import { getDayName, isToday } from '@rentpath/web-utils'

export function getNavDay(availableDate: string, isShort = true) {
  const availableDateStr = new Date(availableDate)

  const day = availableDateStr.getUTCDate()
  const name = isToday(availableDate)
    ? 'Today'
    : getDayName(availableDateStr.getUTCDay(), isShort)

  return { day, name }
}
