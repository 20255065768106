import clsx from 'clsx'
import anchorStyles from '../../components/anchor/anchor.module.css'
import buttonStyles from '../../components/button/button.module.css'
import type { TourWizardProps } from './tour-wizard.types'
import styles from './tour-wizard-modal.module.css'

const tourButtonStyles = clsx(
  buttonStyles.base,
  buttonStyles.primary,
  buttonStyles.fluid,
  buttonStyles.rounded
)
const tourBackButtonStyles = clsx(buttonStyles.base, buttonStyles.outlined)
const headerButton = clsx(anchorStyles.anchor, styles.headerButton)
export const tourWizardTheme: TourWizardProps['theme'] = {
  container: styles.tourWizardModalContainer,
  backButton: tourBackButtonStyles,
  nextButton: tourButtonStyles,
  stepHeading: styles.stepHeading,
  steps: styles.steps,
  stepContainer: styles.stepContainer,
  stepSubHeading: styles.stepSubHeading,
  scheduleTourButton: tourButtonStyles,
  header: styles.header,
  headerButton,
  headerShowBack: styles.headerShowBack,
  headerHideBack: styles.headerHideBack,
  headerShowClose: styles.headerShowClose,
  headerHideClose: styles.headerHideClose,
  tourScheduledTime: styles.tourScheduledTime,
  footerContainer: styles.footerContainer,
  footerContainerInner: styles.footerContainerInner,
  tourTimesStep: {
    container: styles.tourTimesStepContainer,
    navContainer: styles.tourTimesNavContainer,
    nav: styles.nav,
    dateGrid: styles.dateGrid,
    day: styles.day,
    dateGridWithOne: styles.dateGridWithOne,
    dateGridWithMany: styles.dateGridWithMany,
    navBackButton: styles.tourTimesNavBack,
    navNextButton: styles.tourTimesNavNext,
    scrollContainer: styles.tourTimesScrollContainer,
    dayName: styles.tourTimesStepDayName,
    dayNumber: styles.tourTimesStepDayNumber,
    selection: {
      base: styles.tourTimeSelection,
      checked: styles.tourTimeSelectionChecked,
      disabled: styles.tourTimeSelectionDisabled,
      unchecked: styles.tourTimeSelectionUnchecked,
      input: styles.tourTimeSelectionInput,
    },
    timeList: styles.timeList,
    timeListItem: styles.timeListItem,
    timeListSingleTime: styles.timeListSingleTime,
  },
  tourTypesList: {
    wrapper: styles.tourTypesListWrapper,
    list: styles.tourTypesList,
    listItem: styles.tourTypesListItem,
    listItemSelected: styles.pipe,
    input: clsx(styles.tourTypesInput, 'sr-only'),
    label: styles.tourTypesListLabel,
    heading: styles.tourTypesListHeading,
    headingDisabled: styles.tourTypesListHeadingDisabled,
  },
  contactStep: {
    wrapper: styles.contactStepWrapper,
    base: styles.contactStepBase,
    subHeading: styles.contactStepHeading,
  },
  contactForm: {
    base: styles.contactFormBase,
    nameGroup: styles.contactFormNameGroup,
    firstName: styles.contactFormFirstName,
    lastName: styles.contactFormLastName,
    moveInDate: styles.contactFormInput,
    email: styles.contactFormInput,
    phone: styles.contactFormInput,
  },
  thankYouStep: {
    wrapper: styles.thankYouStepWrapper,
    spacing: styles.thankYouStepSpacing,
    confirmationText: styles.thankYouStepConfirmationText,
    calendarButtons: styles.thankYouStepCalendarIcons,
    calendarButtonsText: styles.thankYouStepCalendarButtonsText,
    calendarButtonsGoogle: styles.thankYouStepCalendarButtonsGoogle,
    calendarButtonsApple: styles.thankYouStepCalendarButtonsApple,
    calendarIcon: styles.thankYouStepCalendarIcon,
  },
  addToCalendarButton: clsx(
    tourBackButtonStyles,
    buttonStyles.fluid,
    buttonStyles.rounded
  ),
  failureImage: styles.failureImage,
  thankYouHeading: styles.thankYouHeading,
  thankYouImage: styles.thankYouImage,
  tourTypeHeading: styles.tourTypeHeading,
  tryAgainButton: clsx(tourButtonStyles, styles.tryAgainButton),
  empty: {
    wrapper: styles.emptyWrapper,
    button: clsx(
      buttonStyles.base,
      buttonStyles.primary,
      buttonStyles.rounded,
      styles.tryAgainButton
    ),
  },
  overlay: styles.overlay,
  loadingText: styles.loadingText,
}
