import type { ParsedUrlQuery } from 'querystring'
import { TaggingPageType } from '../../tagging/tagging.const'

const REVIEW_REGEX = /\/writeReview\/?$/
const SECURITY_REGEX = /\/security\/?$/
const DETAIL_REGEX = /^\/detail\/?/
const SEARCH_REGEX = /\/search\/?$/
const REVIEW_THANK_REGEX = /^\/review-thank-you\/?$/
const LIST_REGEX = /^\/list\/?$/
const PROFILE_REGEX = /^\/profile\/?$/
const SAVED_REGEX = /^\/saved\/?$/
const RENT_TRENDS_REGEX = /^\/rent-trends\/?$/
const ERROR_REGEX = /^\/404\/?$/
const DOWNLOAD_REGEX = /^\/mobile_apps\/?$/

export const srpViewToPageMap = {
  map: 'srp_map',
  list: 'srp',
}

export function getSearchDisplayPageType(isMobile: boolean, display?: string) {
  if (!isMobile) return srpViewToPageMap.map
  return display === 'map' ? srpViewToPageMap.map : srpViewToPageMap.list
}

export function getPageType(
  isMobile: boolean,
  urlPathname: string,
  routeQuery: ParsedUrlQuery
) {
  if (urlPathname === '/') return TaggingPageType.HOME
  if (SEARCH_REGEX.test(urlPathname)) {
    return getSearchDisplayPageType(isMobile, routeQuery?.display as string)
  }

  if (REVIEW_REGEX.test(urlPathname)) return TaggingPageType.WRITE_REVIEW
  if (DETAIL_REGEX.test(urlPathname)) return TaggingPageType.DETAIL
  if (REVIEW_THANK_REGEX.test(urlPathname))
    return TaggingPageType.REVIEW_THANK_YOU
  if (LIST_REGEX.test(urlPathname)) return TaggingPageType.LIST_A_PROPERTY
  if (PROFILE_REGEX.test(urlPathname)) return TaggingPageType.PROFILE_SETTINGS
  if (SAVED_REGEX.test(urlPathname)) return TaggingPageType.SAVED_PROPERTIES
  if (SECURITY_REGEX.test(urlPathname)) return TaggingPageType.RENTAL_SAFETY
  if (RENT_TRENDS_REGEX.test(urlPathname)) return TaggingPageType.RENT_TRENDS
  if (ERROR_REGEX.test(urlPathname)) return TaggingPageType.ERROR
  if (DOWNLOAD_REGEX.test(urlPathname)) return TaggingPageType.DOWNLOAD

  return urlPathname.split('/')[1] || ''
}
