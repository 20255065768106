import { oneClickCookieAtom } from '../one-click-lead/one-click-lead-cookie.store'
import { useAtom } from 'jotai'

/**
 * Gets whether or not to use one-click lead for CTAs
 * @category Hooks
 * @returns {boolean} Whether or not to use one-click lead for CTAs
 */
export function useOneClickLeadActive() {
  const [cookieData] = useAtom(oneClickCookieAtom)
  return Boolean(cookieData.email && cookieData.name && cookieData.moveDate)
}
