import clsx from 'clsx'
import { ListingCardSkeleton } from '../../../listing-card/listing-card-skeleton'
import styles from './thank-you-redesign-stacked-cards.module.css'

import type { SimilarOrViewed_ListingFragment } from '../../../listing-card-preview-group/__generated__/similar-properties.gql'
import { useRequestData } from '../../../request-data/pages-router/use-request-data'
import { useMediaQuery } from '@rentpath/react-hooks'
import { ListingCardPreview } from '../../../listing-card/listing-card-preview'

export interface ThankYouRedesignStackedCardsProps {
  listings: SimilarOrViewed_ListingFragment[]
  viewedListingIds: string[]
  leadSubmittedListingIds: string[]
  notInterestedListingIds: string[]
  allViewedListingIds: string[]
}

const MAX_BACKGROUND_BOXES = 3

export function ThankYouRedesignStackedCards({
  viewedListingIds,
  listings,
  leadSubmittedListingIds,
  notInterestedListingIds,
  allViewedListingIds,
}: ThankYouRedesignStackedCardsProps) {
  const { isMobile: isMobileDevice } = useRequestData()
  const isMobile = useMediaQuery('(max-width: 767px)', isMobileDevice)

  if (!listings.length || listings.length <= viewedListingIds.length)
    return null

  const remainingListingsCount =
    listings.length - allViewedListingIds.length || 0

  const backgroundBoxesToShow =
    isMobile && remainingListingsCount >= MAX_BACKGROUND_BOXES
      ? MAX_BACKGROUND_BOXES
      : remainingListingsCount

  return (
    <div className={styles.listingCardsStacked}>
      {Array.from({ length: backgroundBoxesToShow }).map((_, index) => {
        return <div key={index} className={styles.backgroundCard} />
      })}
      {listings.map((listing, index) => {
        const isActiveCard = index === allViewedListingIds.length
        const userHasSeenCard = allViewedListingIds.includes(listing.id)
        const userSubmittedLead = leadSubmittedListingIds.includes(listing.id)

        return (
          <ListingCardPreview
            withCarousel
            key={listing.id}
            colorScheme="light"
            fontSize="small"
            showSquareFeet
            showRating
            showUnitsAvailable
            data-tag_item="view_pdp"
            openInNewTab={!isMobileDevice}
            isMobile={isMobileDevice}
            className={clsx({
              [styles.activeCard]: isActiveCard && !userHasSeenCard,
              [styles.hiddenListingCard]: !userHasSeenCard && !isActiveCard,
              [styles.leadSubmitted]: userSubmittedLead,
              [styles.notInterested]: notInterestedListingIds.includes(
                listing.id
              ),
            })}
            {...listing}
          />
        )
      })}
    </div>
  )
}

export function ThankYouRedesignStackedSkeletons() {
  return (
    <div className={clsx(styles.listingCardsStacked, styles.stackedSkeletons)}>
      <div className={styles.activeCard}>
        <ListingCardSkeleton />
      </div>
      <div className={styles.backgroundCard} />
      <div className={styles.backgroundCard} />
      <div className={styles.backgroundCard} />
    </div>
  )
}
