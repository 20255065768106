import clsx from 'clsx'
import type { HTMLAttributes } from 'react'
import { useState, useImperativeHandle, useRef, forwardRef, memo } from 'react'
import styles from './horizontal-scroll-gradient.module.css'

interface HorizonalScrollGradientProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  direction: 'left' | 'right'
}

export interface HorizonalScrollGradientRef extends HTMLDivElement {
  hide: () => void
  show: () => void
}

export const HorizonalScrollGradient = memo(
  forwardRef<HTMLDivElement, HorizonalScrollGradientProps>(
    function HorizonalScrollGradient(props, forwardedRef) {
      const ref = useRef<HTMLDivElement>(null)
      const [hide, setHide] = useState(true)
      const { className, ...rest } = props

      useImperativeHandle(
        forwardedRef,
        () => ({
          ...ref.current!,
          hide: () => {
            setHide(true)
          },
          show: () => {
            setHide(false)
          },
        }),
        []
      )

      return (
        <div
          className={clsx(styles.gradient, className, {
            [styles.left]: props.direction === 'left',
            [styles.right]: props.direction === 'right',
            [styles.disabled]: hide,
          })}
          {...rest}
        />
      )
    }
  )
)
