function getDialogModal(id: string) {
  return document.getElementById(id) as HTMLDialogElement
}

export function showDialogModalById(id: string) {
  const dialog = getDialogModal(id)

  if (dialog) {
    dialog.showModal()
  }
}

export function closeDialogModalById(id: string) {
  const dialog = getDialogModal(id)

  if (dialog) {
    dialog.close()
  }
}
