import type {
  AggregatesLowHigh,
  ListingAvailabilityStatus,
  SquareFeetRange,
} from '../../../__generated__/api-types'
import { isBasicAndAvailableListing } from '../../ads/is-basic-and-available-listing'
import { AVAILABLE_NOW } from '@brand/slots/detail/floor-plans/const'
import { numberWithCommas, pluralize } from '@rentpath/web-utils'

const endash = '\u2013' // Unicode for endash

type getUnitAvailableTextProps = {
  apiText: string
  listingText: string
  isBasic?: boolean
  availabilityStatus?: ListingAvailabilityStatus
}

export function getUnitAvailableText({
  apiText,
  listingText,
  isBasic,
  availabilityStatus,
}: getUnitAvailableTextProps) {
  const basicAndAvailableListing = isBasicAndAvailableListing({
    isBasic,
    availabilityStatus,
  })

  // per product, a basic available property should ALWAYS use the available now text
  if (basicAndAvailableListing) return AVAILABLE_NOW

  // the api text which comes from filterMatchResults can be null when this component is
  // accessed in the saved page. In that case, we display the listing units available as is.
  const unitAvailableText = apiText ? apiText : listingText

  const hasUnitsAvailable =
    unitAvailableText.includes('Units Available') ||
    unitAvailableText.includes('Unit Available')

  if (hasUnitsAvailable) {
    return AVAILABLE_NOW
  }

  return unitAvailableText
}

export const getSqFtRangeText = ({
  sqFtRange,
  listingSqftText,
}: {
  sqFtRange?: SquareFeetRange | null
  listingSqftText?: string | null
}) => {
  const { min, max } = sqFtRange || { min: null, max: null }

  const values = [min, max].filter(Boolean) as (number | string)[]
  const rangeText = [...new Set(values)]
    .map((v) => numberWithCommas(v))
    .join(endash)

  if (rangeText.length) {
    return `${rangeText} Sqft`
  }

  return listingSqftText
}

export function getBedsText({
  bedRange,
  listingBedsText,
}: {
  bedRange?: AggregatesLowHigh | null
  listingBedsText?: string | null
}) {
  const bedRangeArr = [...new Set([bedRange?.low, bedRange?.high])].filter(
    (item) => typeof item !== 'undefined' && item !== null
  ) as number[]

  switch (bedRangeArr.length) {
    // min and max
    case 2:
      return `${bedRangeArr[0] === 0 ? 'Studio' : bedRangeArr[0]}${endash}${
        bedRangeArr[1]
      } ${pluralize(bedRangeArr[1], 'Bed')}`
    // single value in range
    case 1:
      return bedRangeArr[0] === 0
        ? 'Studio'
        : `${bedRangeArr[0]} ${pluralize(bedRangeArr[0], 'Bed')}`
    case 0:
      return listingBedsText
  }
}

export function getBathText({
  bathRange,
  listingBathText,
}: {
  bathRange?: AggregatesLowHigh | null
  listingBathText?: string | null
}) {
  const bathRangeArr = [...new Set([bathRange?.low, bathRange?.high])].filter(
    Boolean
  ) as number[]

  switch (bathRangeArr.length) {
    // min and max
    case 2:
      return `${bathRangeArr[0]}${endash}${bathRangeArr[1]} Baths`
    // single value in range
    case 1:
      return `${bathRangeArr[0]} ${pluralize(bathRangeArr[0], 'Bath')}`
    case 0:
      return listingBathText
  }
}
