/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type LocationFieldsFragment = { stateAbbr?: string | null, neighborhoods?: Array<string | null> | null };

export type KeyInfo_ListingQueryFragment = { availabilityStatus: Types.ListingAvailabilityStatus, bedText?: string | null, bathText?: string | null, priceText?: string | null, name?: string | null, address?: string | null, addressFull?: string | null, unitsAvailableText?: string | null, ratingCount?: number | null, ratingPercent?: number | null, squareFeetText?: string | null, isBasic: boolean, verified: boolean, location?: { stateAbbr?: string | null, neighborhoods?: Array<string | null> | null } | null };

export const LocationFieldsFragmentDoc = `
    fragment LocationFields on Location {
  stateAbbr
  neighborhoods
}
    `;
export const KeyInfo_ListingQueryFragmentDoc = `
    fragment KeyInfo_ListingQueryFragment on Listing {
  availabilityStatus
  bedText
  bathText
  priceText
  name
  address
  addressFull
  unitsAvailableText
  ratingCount
  ratingPercent
  squareFeetText
  isBasic
  verified
  location {
    ...LocationFields
  }
}
    `;



