import type { getLocationRelativeToSearchedCity } from '../../search/utils/get-location-relative-to-searched-city'
import type { LeadTracking_ListingFragment } from '../__generated__/lead-form.gql'

export enum LeadCategoryRate {
  PHONE = 0.015,
  FORM = 0.02,
  FORM_WITH_TOUR_INTENT = 0.025,
  REQUEST_TOUR = 0.025,
  SCHEDULED_TOUR = 0.05,
}

/**
 * Helper function to make a manual tracking call.
 * Uses the data-tag_section prop.
 *
 * @example
 * trackLead('click')
 * trackLead('lead_submission', { intent: 'tour_live' })
 */
export function trackLead(
  action: string,
  dataTagSection: string,
  data: Record<string, unknown>
) {
  if (window.eventTracker) {
    window.eventTracker.track(action, {
      ...data,
      section: dataTagSection, // should this be necessary?
    })
  }
}

/** Make the tracking call after a successful submit of the lead form. */
export function trackSuccess(
  listing: LeadTracking_ListingFragment,
  leadCategory: LeadCategoryRate,
  intent: string | string[],
  dataTagSection: string,
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
) {
  /** Track the intent options
   * - Use "none" if none of the intent options are selected
   * - Use comma-separated list of tagging values if intent options are selected
   */

  // what...

  // const listingInSrp =
  //   Array.isArray(listings) &&
  //   listings.find((srpListing) => srpListing.listingId === listing.listingId)
  //
  // const listingDetails = trackTruesearchLead(
  //   listingInSrp || listing,
  //   LeadType.EMAIL
  // )

  const { zScore, NV_RPL, revenue, ...restListingData } = listing || {}

  trackLead('lead_submission', dataTagSection, {
    intent, // cd112
    // Note: listhub_id no longer used
    availability_status: listing.availabilityStatus, // cd140
    selection: 'email', // cd41,
    zscore: zScore || '',
    nv_rpl: NV_RPL?.toString() || '',
    revenue: revenue || '',
    property_types: listing.propertyType, // cd07
    listing_id: restListingData.id,
    geo_search_property_match_type: locationRelativeToSearchedCity,
    cil: leadCategory,
    ...restListingData,
  })
}

/**
 * Make the tracking call after trying to submit but encountering validation
 * errors.
 * - The tracking call will have action "error", and cd29 will contain a
 *   comma-separated list of the fields that contained errors. */
export function trackErrors(
  currentErrors: Record<string, unknown>,
  dataTagSection: string
) {
  // Get the list of fields that caused a validation message when the user
  // clicked submit.

  const fields = Object.keys(currentErrors)

  if (!fields.length) {
    return
  }

  trackLead('error', dataTagSection, {
    selection: 'validation',
    validation_error: fields, // cd29
  })
}
