import { useRequestData } from '../request-data/pages-router/use-request-data'

import type { ListingPhone_ListingFragment } from './__generated__/listing-phone.gql'
import { CALL } from './use-listing-phone.const'
import { useMediaQuery } from '@rentpath/react-hooks'

export type ListingPhone = Pick<
  ListingPhone_ListingFragment,
  | 'phoneDesktop'
  | 'phoneDesktopText'
  | 'phoneDesktopSem'
  | 'phoneDesktopSemText'
  | 'phoneMobile'
  | 'phoneMobileText'
  | 'phoneMobileSem'
  | 'phoneMobileSemText'
  | 'mitsPhone'
  | 'telephony'
>

export function useListingPhone(
  listing: ListingPhone = {} as ListingPhone,
  phoneMits?: boolean
) {
  const {
    isMobile,
    sem: { shouldDisplaySemPhone },
  } = useRequestData()

  const isTabletOrBelow = useMediaQuery('(max-width: 1125px)', isMobile)

  const formattedSemNumber = listing.phoneDesktopSemText

  const formattedDesktopNumber = listing.phoneDesktopText

  const rawSemNumber = listing.phoneDesktopSem

  const rawDesktopNumber = listing.phoneDesktop

  let phoneFormatted = shouldDisplaySemPhone
    ? formattedSemNumber
    : formattedDesktopNumber

  let phoneText = phoneFormatted

  let phoneRaw = shouldDisplaySemPhone ? rawSemNumber : rawDesktopNumber

  if (isTabletOrBelow && listing.phoneMobile) {
    const phoneMobileSem = {
      raw: listing.phoneMobileSem,
      formatted: listing.phoneMobileSemText,
    }

    const phoneMobileWeb = {
      raw: listing.phoneMobile,
      formatted: listing.phoneMobileText,
    }

    phoneText = CALL
    phoneRaw = shouldDisplaySemPhone ? phoneMobileSem.raw : phoneMobileWeb.raw
    phoneFormatted = shouldDisplaySemPhone
      ? phoneMobileSem.formatted
      : phoneMobileWeb.formatted
  }

  if (phoneMits) {
    phoneFormatted = listing.mitsPhone.formatted
    phoneText = phoneFormatted
    phoneRaw = listing.mitsPhone.raw
  }

  return {
    phoneFormatted,
    phoneText,
    phoneRaw,
    hasPhone:
      (!!phoneText && !!phoneRaw) ||
      (!!phoneMits && !!phoneRaw && !!phoneFormatted),
  }
}
