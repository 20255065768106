import type { ScheduleTourInput } from '../../../../__generated__/api-types'
import {
  PreferredContactMethod,
  TourType,
} from '../../../../__generated__/api-types'
import type { ContactFormAtom } from '../../tour-wizard.store'
import type { PreferredMethod, TourWizardConfig } from '../../tour-wizard.types'

const preferredContactMethodMap: Record<
  PreferredMethod,
  PreferredContactMethod
> = {
  email: PreferredContactMethod.Email,
  sms: PreferredContactMethod.Sms,
}

export const typeMap: Record<keyof TourWizardConfig['tourTypes'], TourType> = {
  IN_PERSON: TourType.InPerson,
  SELF_GUIDED: TourType.SelfGuided,
  VIDEO_CALL: TourType.VideoCall,
}

export function getScheduleTourInput(
  tourWizardConfig: TourWizardConfig,
  timeSelection: string,
  tourType: TourType,
  contactInfo: ContactFormAtom['values']
): ScheduleTourInput {
  return {
    date: timeSelection,
    disclaimer: tourWizardConfig.tourProviderDetails.disclaimer,
    email: contactInfo.email,
    firstName: contactInfo.firstName,
    lastName: contactInfo.lastName,
    listingId: tourWizardConfig.listing?.id,
    message: 'Tour successfully scheduled',
    moveInDate: contactInfo.moveInDate,
    phone: contactInfo.phone,
    preferredMethod:
      preferredContactMethodMap[
        tourWizardConfig.preferredMethod as PreferredMethod
      ],
    referrer: window.location.href.split('?')[0],
    smsOptIn: Boolean(tourWizardConfig.smsOptIn),
    smsConsentEntities: tourWizardConfig.smsOptIn ? 'integrationpartner' : '',
    type: typeMap[tourType],
  }
}
