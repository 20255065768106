import { useAtomValue, useSetAtom } from 'jotai'
import { useCallback } from 'react'

import { getEmptyFieldCount } from '../../contact-form/contact-form.utils'
import type { ContactFormAtom } from '../../tour-wizard.store'
import {
  contactFormAtom,
  isLastStepAtom,
  setNextStepAtom,
  setTourStatusCompleteAtom,
  timeSelectionAtom,
  tourTypeAtom,
} from '../../tour-wizard.store'
import { useTourRequestMutation } from './use-tour-request-mutation'
import type { TourId } from './use-tour-request-mutation'
import { useTourWizardConfig } from './use-tour-wizard-config'

import type { TourWizardConfig } from '../../tour-wizard.types'
import type { TourType } from '__generated__/api-types'
import type { Result } from '@swan-io/boxed'

export async function handleNextStep(
  contactFormValues: ContactFormAtom['values'],
  emptyFieldCount: number,
  isLastStep: boolean,
  tourRequest: () => Promise<Result<TourId, Error>>,
  tourWizardConfig: TourWizardConfig,
  timeSelection: string,
  tourType: TourType,
  setTourStatusComplete: () => void,
  setNextStep: () => void
): Promise<void> {
  const isReadyToSubmit = isLastStep && emptyFieldCount === 0

  if (isReadyToSubmit) {
    const result = await tourRequest()

    if (result.isOk() && result.value) {
      if (typeof tourWizardConfig.onTourRequest === 'function') {
        tourWizardConfig.onTourRequest(
          contactFormValues,
          tourType,
          timeSelection,
          tourWizardConfig,
          result.value
        )
      }
      if (typeof tourWizardConfig.onComplete === 'function') {
        tourWizardConfig.onComplete(
          contactFormValues,
          tourType,
          timeSelection,
          tourWizardConfig,
          result.value
        )
      }
    }

    if (result.isOk()) {
      setTourStatusComplete()
    }
  } else {
    setNextStep()
  }
}

export function useNextHandler(): [
  () => ReturnType<typeof handleNextStep>,
  boolean,
  Error | null
] {
  const tourWizardConfig = useTourWizardConfig()
  const contactForm = useAtomValue(contactFormAtom)
  const tourType = useAtomValue(tourTypeAtom)
  const timeSelection = useAtomValue(timeSelectionAtom)
  const [tourRequest, loading, error] = useTourRequestMutation()
  const isLastStep = useAtomValue(isLastStepAtom)
  const setTourStatusComplete = useSetAtom(setTourStatusCompleteAtom)
  const setNextStep = useSetAtom(setNextStepAtom)

  const handleNext = useCallback(
    () =>
      handleNextStep(
        contactForm.values,
        getEmptyFieldCount(contactForm),
        isLastStep,
        tourRequest,
        tourWizardConfig,
        timeSelection,
        tourType,
        setTourStatusComplete,
        setNextStep
      ),
    [
      contactForm,
      isLastStep,
      timeSelection,
      tourRequest,
      tourType,
      tourWizardConfig,
      setTourStatusComplete,
      setNextStep,
    ]
  )

  return [handleNext, loading, error]
}
