import { ReactComponent as BackIcon } from '@brand/icons/chevron-left.svg'
import clsx from 'clsx'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useRef } from 'react'
import { RequestTourWizard } from './request-a-tour-wizard'
import {
  RequestTourSteps,
  currentStepAtom,
} from './request-a-tour-wizard.store'
import { useRouter } from 'next/router'
import { getEventCategoryPageSignifier } from '../tagging/get-event-category-page-signifier'
import { Anchor } from '../../components/anchor/anchor'
import type { ComponentProps } from 'react'
import styles from './request-a-tour-wizard-modal.module.css'
import { DialogModal } from '../dialog-modal/dialog-modal'
import { DialogModalCloseButton } from '../dialog-modal/dialog-modal-close-button'
import { Button } from '../../components/button/button'
import { CALENDAR_PORTAL_ID } from '../../components/calendar/calendar.const'
import { yieldOrContinue } from 'main-thread-scheduling'
import { requestTourModalStateAtom } from '../cta-modals/cta-modals.atom'

export type RequestTourWizardListing = ComponentProps<
  typeof RequestTourWizard
>['listing']

export const REQUEST_TOUR_MODAL_ID = 'request-a-tour-modal'

export function RequestTourWizardModal() {
  const [{ listing, currentRefinementSlugs, locationRelativeToSearchedCity }] =
    useAtom(requestTourModalStateAtom)
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom)
  const requestTourModalRef = useRef<HTMLDialogElement>(null)
  const { pathname } = useRouter()

  useEffect(() => {
    if (currentStep) requestTourModalRef?.current?.scrollTo(0, 0)
  }, [currentStep])

  const onClose = useCallback(async function onClose() {
    await yieldOrContinue('smooth')
    requestTourModalRef.current?.close()
    await yieldOrContinue('smooth')
  }, [])

  const handleOnAfterClose = useCallback(
    function handleOnAfterClose() {
      setCurrentStep(RequestTourSteps.contactFormDatePicker)
    },
    [setCurrentStep]
  )

  const isContactFormStep =
    currentStep === RequestTourSteps.contactFormDatePicker
  const isEnhancedLeadsStep =
    currentStep === RequestTourSteps.enhancedLeadQuestions
  const isSimilarPropertiesStep =
    currentStep === RequestTourSteps.similarProperties

  const similarPropsDataTagSection = `similar_properties${getEventCategoryPageSignifier(
    pathname
  )}lead`

  return (
    <DialogModal
      ref={requestTourModalRef}
      onAfterClose={handleOnAfterClose}
      id={REQUEST_TOUR_MODAL_ID}
      data-tid="request-a-tour-modal"
      data-tag_section={
        isSimilarPropertiesStep ? similarPropsDataTagSection : 'request_a_tour'
      }
      className={clsx(styles.modalStyles, {
        [styles.formStep]: isContactFormStep || isEnhancedLeadsStep,
        [styles.similarPropertiesStepBase]: isSimilarPropertiesStep,
        [styles.similarPropertiesStep]: isSimilarPropertiesStep,
      })}
    >
      {(isContactFormStep || isEnhancedLeadsStep) && (
        <nav
          className={clsx(
            !isEnhancedLeadsStep && styles.hideBackButton,
            styles.navHeader
          )}
        >
          <Anchor
            onClick={() =>
              setCurrentStep(RequestTourSteps.contactFormDatePicker)
            }
            data-tid="elf_rt_back"
            data-tag_item="back_button"
          >
            <BackIcon className={styles.backButtonIcon} />
            Back
          </Anchor>
          <DialogModalCloseButton
            ref={requestTourModalRef}
            data-tag_section="request_a_tour_modal"
          >
            <Button variant="anchor" size="md">
              {isSimilarPropertiesStep ? 'Close' : 'Cancel'}
            </Button>
          </DialogModalCloseButton>
        </nav>
      )}
      {listing ? (
        <RequestTourWizard
          listing={listing}
          currentRefinementSlugs={currentRefinementSlugs}
          onClose={onClose}
          locationRelativeToSearchedCity={locationRelativeToSearchedCity}
        />
      ) : null}
      <div id={CALENDAR_PORTAL_ID}></div>
    </DialogModal>
  )
}
