/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type ReportFraud_ListingFragment = { id: string, address?: string | null, name?: string | null, urlPathname?: string | null, location?: { city?: string | null, zip?: string | null, stateAbbr?: string | null } | null, mlsInfo?: { feed?: string | null } | null };

export const ReportFraud_ListingFragmentDoc = `
    fragment ReportFraud_ListingFragment on Listing {
  id
  address
  location {
    city
    zip
    stateAbbr
  }
  name
  urlPathname
  mlsInfo {
    feed
  }
}
    `;



