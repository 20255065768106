/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type PmcInfo_ListingFragment = { listingTier?: string | null, propertyManagementCompany?: { nameSanitized?: string | null, logo?: { id: string } | null } | null };

export const PmcInfo_ListingFragmentDoc = `
    fragment PMCInfo_ListingFragment on Listing {
  listingTier
  propertyManagementCompany {
    nameSanitized
    logo {
      id
    }
  }
}
    `;



