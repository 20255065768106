import { useLeadSubmission } from './use-lead-submission'
import type { LeadForm_ListingFragment } from '../lead-form/__generated__/lead-form.gql'
import type { RequestTourFormData } from './use-lead-submission'
import type { getLocationRelativeToSearchedCity } from '../search/utils/get-location-relative-to-searched-city'
import { useCallback, useMemo } from 'react'
import { SubmitLeadCTAButton } from './submit-lead-cta-button'
import { Heading } from '../../components/heading/heading'
import styles from './enhanced-lead-step.module.css'
import { EnhancedLeadPrompts } from '../../features/enhanced-lead-form/enhanced-lead-prompts'

interface EnhancedLeadStepProps {
  listing: LeadForm_ListingFragment
  currentRefinementSlugs?: string[]
  onClose?: () => void
  contactFormData: RequestTourFormData
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
}

export function EnhancedLeadStep({
  listing,
  currentRefinementSlugs,
  contactFormData,
  locationRelativeToSearchedCity,
}: EnhancedLeadStepProps) {
  const { handleValidSubmit, isSending } = useLeadSubmission({
    currentRefinementSlugs,
    listing,
    locationRelativeToSearchedCity,
  })

  const memoizedContactData = useMemo(() => contactFormData, [contactFormData])

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()

      handleValidSubmit(memoizedContactData)
    },
    [handleValidSubmit, memoizedContactData]
  )

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.contentWrapper}>
        <div className={styles.heading}>
          <Heading withBodyFont level="1x">
            Almost Done...
          </Heading>
        </div>
        <EnhancedLeadPrompts
          promptIds={listing.leadQuestions?.ids as string[]}
        />
      </div>
      <footer className={styles.footer}>
        <SubmitLeadCTAButton
          isSending={isSending}
          title="Send Request"
          dataTag="request_tour_submission"
        />
      </footer>
    </form>
  )
}
