import React from 'react'
import { Spinner } from '../../../components/spinner/spinner'
import { useTourWizardConfig } from './hooks/use-tour-wizard-config'

import { TourWizardOverlay } from './tour-wizard-overlay'

import type { FC } from 'react'

export const TourWizardLoading: FC<{ message: string }> = (props) => {
  const { theme } = useTourWizardConfig()
  return (
    <TourWizardOverlay data-tid="loading">
      <Spinner />
      <p className={theme?.loadingText}>{props.message}&hellip;</p>
    </TourWizardOverlay>
  )
}
