/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type InternetServiceFieldsFragment = { provider?: string | null, connectionTypes?: Array<string | null> | null, maxSpeedMbps?: number | null };

export type InternetServices_ListingFragment = { internetServices: Array<{ provider?: string | null, connectionTypes?: Array<string | null> | null, maxSpeedMbps?: number | null }> };

export const InternetServiceFieldsFragmentDoc = `
    fragment InternetServiceFields on InternetService {
  provider
  connectionTypes
  maxSpeedMbps
}
    `;
export const InternetServices_ListingFragmentDoc = `
    fragment InternetServices_ListingFragment on Listing {
  internetServices {
    ...InternetServiceFields
  }
}
    `;



