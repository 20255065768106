export function getLeadMessagePlaceholder(
  listing?: {
    name?: string | null
    isBasic?: boolean
  },
  // passed in to make use of the email_cta_on_nonpaying_props feature flag
  // if this is implemented without the feature flag, listing above can add availabilityStatus
  // and showPaidLeadMessage can be removed
  // then isBasic and availabilityStatus can be used to determine the text
  showPaidLeadMessage?: boolean
) {
  if (!listing) return 'Message'
  const label = listing.name || 'this property'
  return listing.isBasic && !showPaidLeadMessage
    ? [
        'Although this property is unavailable,',
        `I would like to learn more about properties similar to ${label}.`,
        'Please send me more information. Thank you!',
      ].join(' ')
    : [
        `Hello, I'm interested in ${label}.`,
        'Please send me current availability and additional details. Thanks!',
      ].join(' ')
}
