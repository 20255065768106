/* eslint-disable no-void */
import { getDateDelta, utcDateToDateString } from '@rentpath/web-utils'
import { atom } from 'jotai'
import { TourType } from '../../__generated__/api-types'
import type { TourTimesQueryVariables } from './tour-times/graphql/__generated__/tour-times.gql'
import { fetchTourTimes } from './tour-times/graphql/fetch-tour-times'
import { initialMaxDays } from './tour-times/hooks/use-responsive-days-to-show'
import { getDateRange } from './tour-times/utils/get-date-range'
import { getShouldShowPrev } from './tour-times/utils/get-should-show-prev'
import type { AvailableDatesFields } from './tour-wizard.types'

const INITIAL_STEP_INDEX = 0
export const TOTAL_STEPS = 2

export const currentStepAtom = atom(INITIAL_STEP_INDEX)

export const isLastStepAtom = atom(
  (get) => get(currentStepAtom) === TOTAL_STEPS - 1
)

export const setStepAtom = atom(
  (get) => get(currentStepAtom),
  (_get, set, step: number) => {
    set(currentStepAtom, step)
  }
)

export const setNextStepAtom = atom(
  (get) => get(currentStepAtom),
  (get, set) => {
    const currentStep = get(currentStepAtom)
    const newCurrentStep =
      currentStep < TOTAL_STEPS ? currentStep + 1 : currentStep
    set(currentStepAtom, newCurrentStep)
  }
)

export const setPrevStepAtom = atom(
  (get) => get(currentStepAtom),
  (get, set) => {
    const currentStep = get(currentStepAtom)
    const newCurrentStep = currentStep > 0 ? currentStep - 1 : currentStep
    set(currentStepAtom, newCurrentStep)
  }
)

export const resetCurrentStepAtom = atom(null, (_, set) => {
  set(currentStepAtom, 0)
})

export enum TourWizardStatus {
  INCOMPLETE = 'INCOMPLETE',
  COMPLETE = 'COMPLETE',
}

export const tourStatusAtom = atom<TourWizardStatus>(
  TourWizardStatus.INCOMPLETE
)
export const resetTourStatusAtom = atom(null, (get, set) => {
  set(tourStatusAtom, TourWizardStatus.INCOMPLETE)
})
export const setTourStatusCompleteAtom = atom(null, (get, set) => {
  set(tourStatusAtom, TourWizardStatus.COMPLETE)
})
export const initialTourType: TourType = TourType.InPerson
export const tourTypeAtom = atom<TourType>(initialTourType)
export const setTourTypeAtom = atom(
  (get) => get(tourTypeAtom),
  (_, set, tourType: TourType) => {
    set(tourTypeAtom, tourType)
  }
)
export const resetTourTypeAtom = atom(null, (_, set) => {
  set(tourTypeAtom, initialTourType)
}) // First of next month
export const initialDateMoveIn = new Date()
initialDateMoveIn.setMonth(initialDateMoveIn.getMonth() + 1, 1)
export const initialContactFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  moveInDate: utcDateToDateString(initialDateMoveIn),
  phone: '',
}
export type ContactInfoValues = typeof initialContactFormValues
export type ContactInfoErrors = Record<keyof ContactInfoValues, boolean>
export const initialContactFormValue = {
  errors: {
    email: false,
    firstName: false,
    lastName: false,
    moveInDate: false,
    phone: false,
  },
  values: initialContactFormValues,
}
export type ContactFormAtom = {
  values: ContactInfoValues
  errors: ContactInfoErrors
}
export const contactFormAtom = atom(initialContactFormValue)
export const setErrorAtom = atom(
  (get) => get(contactFormAtom),
  (get, set, name: keyof ContactInfoErrors | string) => {
    const prev = get(contactFormAtom)
    set(contactFormAtom, {
      ...prev,
      errors: {
        ...prev.errors,
        [name]: true,
      },
    })
  }
)
export const removeErrorAtom = atom(
  (get) => get(contactFormAtom),
  (get, set, name: keyof ContactInfoErrors | string) => {
    const prev = get(contactFormAtom)
    set(contactFormAtom, {
      ...prev,
      errors: {
        ...prev.errors,
        [name]: false,
      },
    })
  }
)
export const setContactInfoValuesAtom = atom(
  (get) => get(contactFormAtom),
  (get, set, values: Partial<ContactInfoValues>) => {
    const prev = get(contactFormAtom)
    set(contactFormAtom, {
      ...prev,
      values: {
        ...prev.values,
        ...values,
      },
    })
  }
)
export const resetContactFormAtom = atom(
  (get) => get(contactFormAtom),
  (get, set) => {
    set(contactFormAtom, initialContactFormValue)
  }
)
type GraphQLInputs = {
  variables: TourTimesQueryVariables
  zid: string
}

interface TourTimesAtom {
  error: Error | null
  fetching: boolean
  times?: AvailableDatesFields[]
}

type TimesList = Record<string, AvailableDatesFields[] | undefined>
const emptytimes: AvailableDatesFields[] = []
const defaultTourTimes = {
  fetching: false,
  error: null,
  times: emptytimes,
}
export const cachedTimesAtom = atom<TimesList>({})
export const resetCachedTimesAtom = atom(null, (_get, set) =>
  set(cachedTimesAtom, {})
)
export const setCachedTimesAtom = atom(
  (get) => get(cachedTimesAtom),
  (get, set, { key, times }) => {
    const cachedTimes = get(cachedTimesAtom)
    set(cachedTimesAtom, { ...cachedTimes, [key]: times })
  }
)
export const tourTimesAtom = atom<TourTimesAtom>(defaultTourTimes)
export const resetTourTimesAtom = atom(null, (_, set) =>
  set(tourTimesAtom, defaultTourTimes)
)
export const fetchTourTimesAtom = atom(
  (get) => get(tourTimesAtom),
  (get, set, { variables, zid }: GraphQLInputs) => {
    const cachedTimes = get(cachedTimesAtom)
    const tourtimes = get(tourTimesAtom)

    /**
     * Create a unique key to use for caching
     */
    const key = [
      variables.dates.toString(),
      variables.type.toString(),
    ].toString()

    async function fetchTimes() {
      set(tourTimesAtom, {
        ...tourtimes,
        fetching: true,
        error: null,
      })

      const times = await fetchTourTimes(variables, zid)

      times.match({
        Ok: (value) => {
          if (value) {
            /**
             * If there are times, cache them based on the generated key
             * so we don't have to query for them later
             */
            set(cachedTimesAtom, {
              ...cachedTimes,
              [key]: value,
            })
          }

          set(tourTimesAtom, {
            fetching: false,
            times: value ?? emptytimes,
            error: null,
          })
        },
        Error: (err) => {
          set(tourTimesAtom, {
            ...tourtimes,
            fetching: false,
            error: err instanceof Error ? err : new Error(err as string),
          })
        },
      })
    }

    /**
     * If cachedTimes for the key already exist, don't query and just set the current
     * tourTimes to the times from the cached key
     */
    if (cachedTimes[key]) {
      set(tourTimesAtom, {
        fetching: false,
        error: null,
        times: cachedTimes[key],
      })
    } else {
      void fetchTimes()
    }
  }
)
export type TourDates = string[]
const currentDateStr = utcDateToDateString(new Date())
export const initialTourDates = getDateRange(currentDateStr, initialMaxDays)
export const tourDatesAtom = atom(initialTourDates)
export const setNextSetOfDaysAtom = atom(
  (get) => get(tourDatesAtom),
  (get, set, daysToShow: number) => {
    const dates = get(tourDatesAtom)
    const [firstDateStr] = dates
    const nextDates = getDateRange(
      getDateDelta(firstDateStr, daysToShow),
      daysToShow
    )
    set(tourDatesAtom, nextDates)
  }
)
export const setPrevSetOfDaysAtom = atom(
  (get) => get(tourDatesAtom),
  (get, set, daysToShow: number) => {
    const dates = get(tourDatesAtom)
    const [firstDateStr] = dates

    if (!getShouldShowPrev(dates)) {
      return
    }

    const prevDates = getDateRange(
      getDateDelta(firstDateStr, dates.length * -1),
      daysToShow
    )

    set(tourDatesAtom, prevDates)
  }
)
export const resetTourDatesAtom = atom(null, (_, set) => {
  set(tourDatesAtom, initialTourDates)
})
export const initialTimeSelection = ''
export const timeSelectionAtom = atom(initialTimeSelection)
export const setTimeSelectionAtom = atom(
  (get) => get(timeSelectionAtom),
  (_, set, timeSelection: string) => {
    set(timeSelectionAtom, timeSelection)
  }
)
export const resetTimeSelectionAtom = atom(null, (_, set) => {
  set(timeSelectionAtom, initialTimeSelection)
})

export const initialTourRequestError: Error | null = null
export const tourRequestErrorAtom = atom<Error | null>(initialTourRequestError)
export const setTourRequestErrorAtom = atom(
  (get) => get(tourRequestErrorAtom),
  (_, set, err: Error | null) => {
    set(tourRequestErrorAtom, err)
  }
)
export const resetTourReuqestErrorAtom = atom(null, (_, set) => {
  set(setTourRequestErrorAtom, initialTourRequestError)
})
