import React from 'react'
import { ReactComponent as ChevronLeftIcon } from '@brand/icons/chevron-left.svg'
import clsx from 'clsx'
import { useAtomValue, useSetAtom } from 'jotai'
import { Modal } from '../../modals/modal'
import {
  currentStepAtom,
  resetTourReuqestErrorAtom,
  setPrevStepAtom,
  tourStatusAtom,
  TourWizardStatus,
} from '../tour-wizard.store'
import { useTourWizardConfig } from './hooks/use-tour-wizard-config'

export function TourWizardHeader() {
  const currentStep = useAtomValue(currentStepAtom)
  const status = useAtomValue(tourStatusAtom)
  const setPrevStep = useSetAtom(setPrevStepAtom)
  const { onClose, theme } = useTourWizardConfig()
  const resetTourRequestError = useSetAtom(resetTourReuqestErrorAtom)

  const showBack = currentStep !== 0 && status !== TourWizardStatus.COMPLETE
  const closeButtonText =
    status === TourWizardStatus.COMPLETE ? 'Close' : 'Cancel'
  const showCloseButton = status !== TourWizardStatus.COMPLETE

  const handleGoBack = () => {
    resetTourRequestError()
    setPrevStep()
  }

  const handleClose = () => {
    resetTourRequestError()
    onClose?.()
  }

  return (
    <div
      className={clsx(
        theme?.header,
        showBack ? theme?.headerShowBack : theme?.headerHideBack,
        showCloseButton ? theme?.headerShowClose : theme?.headerHideClose
      )}
    >
      {showBack && (
        <button
          className={theme?.headerButton}
          onClick={handleGoBack}
          data-tag_item="back"
          aria-label="Back to Select Time Step"
        >
          <ChevronLeftIcon className={theme?.headerSelectTimeIcon} />
          Select Time
        </button>
      )}

      {showCloseButton && (
        <Modal.CloseButton onClose={handleClose}>
          <button className={theme?.headerButton}>{closeButtonText}</button>
        </Modal.CloseButton>
      )}
    </div>
  )
}
