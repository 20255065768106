/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type NearbyLocalesPlacesFieldsFragment = { subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType };

export type NearbyLocalesFieldsFragment = { category: string, total: number, places: Array<{ subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType }> };

export type NearbyPlaces_ListingFragment = { nearbyLocales?: { highlights: { category: string, total: number, places: Array<{ subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType }> }, grocery: { category: string, total: number, places: Array<{ subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType }> }, restaurants: { category: string, total: number, places: Array<{ subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType }> }, shopping: { category: string, total: number, places: Array<{ subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType }> }, parks: { category: string, total: number, places: Array<{ subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType }> }, entertainment: { category: string, total: number, places: Array<{ subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType }> }, services: { category: string, total: number, places: Array<{ subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType }> }, sportsAndRecreation: { category: string, total: number, places: Array<{ subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType }> }, bars: { category: string, total: number, places: Array<{ subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType }> }, community: { category: string, total: number, places: Array<{ subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType }> }, emergency: { category: string, total: number, places: Array<{ subcategories: Array<string>, distanceInMiles: number, name: string, type: Types.LocationNearbyPlaceType }> } } | null };

export const NearbyLocalesPlacesFieldsFragmentDoc = `
    fragment NearbyLocalesPlacesFields on NearbySubcategoryItem {
  subcategories
  distanceInMiles
  name
  type
}
    `;
export const NearbyLocalesFieldsFragmentDoc = `
    fragment NearbyLocalesFields on NearbyCategoryItem {
  category
  total
  places {
    ...NearbyLocalesPlacesFields
  }
}
    `;
export const NearbyPlaces_ListingFragmentDoc = `
    fragment NearbyPlaces_ListingFragment on Listing {
  nearbyLocales(perCategoryLimit: 10) {
    highlights {
      ...NearbyLocalesFields
    }
    grocery {
      ...NearbyLocalesFields
    }
    restaurants {
      ...NearbyLocalesFields
    }
    shopping {
      ...NearbyLocalesFields
    }
    parks {
      ...NearbyLocalesFields
    }
    entertainment {
      ...NearbyLocalesFields
    }
    services {
      ...NearbyLocalesFields
    }
    sportsAndRecreation {
      ...NearbyLocalesFields
    }
    bars {
      ...NearbyLocalesFields
    }
    community {
      ...NearbyLocalesFields
    }
    emergency {
      ...NearbyLocalesFields
    }
  }
}
    `;



