/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { DrawerMenu_QueryFragmentDoc } from '../drawer-menu/__generated__/drawer-menu.gql';
export type PageHeader_QueryFragment = { location?: { city?: string | null, stateAbbr?: string | null } };

export const PageHeader_QueryFragmentDoc = `
    fragment PageHeader_QueryFragment on Query {
  ...DrawerMenu_QueryFragment
}
    `;



