import { useOpenModal } from '../modals/use-open-modal'
import type { CtaButtons_ListingFragment } from './__generated__/cta-buttons.gql'
import { ModalName } from '../modals/modals.config'
import { lifestyleRefinementMap } from '@brand/search/refinements'

type UseOpenModalClarificationProps = {
  listing: CtaButtons_ListingFragment
  openNextDialogModal: () => void
  nextModal?: 'lead' | 'tour'
}

export const useOpenModalClarification = ({
  listing,
  openNextDialogModal,
  nextModal,
}: UseOpenModalClarificationProps) => {
  const openModal = useOpenModal()
  let impressionEventSelectionValue = ''

  if (nextModal) {
    switch (nextModal) {
      case 'lead':
        impressionEventSelectionValue = 'email_lead'
        break
      case 'tour':
        impressionEventSelectionValue = 'tour_lead'
        break
      default:
        impressionEventSelectionValue = ''
    }
  }

  const shouldShowClarificationModal = ({
    amenitiesHighlighted,
    categoryBadges,
    incomeRestrictions,
    specialTerms,
  }: CtaButtons_ListingFragment) => {
    const containsIncomeRestrictedAmenity =
      amenitiesHighlighted?.includes(
        lifestyleRefinementMap['income-restricted'].label
      ) ||
      categoryBadges?.includes(
        lifestyleRefinementMap['income-restricted'].label
      )

    const hasClarifications =
      Boolean(incomeRestrictions?.length) || Boolean(specialTerms?.length)

    return containsIncomeRestrictedAmenity && hasClarifications
  }

  return () =>
    shouldShowClarificationModal(listing)
      ? openModal({
          id: ModalName.INCOME_RESTRICTIONS_CLARIFICATION,
          props: {
            listing,
            openNextDialogModal: () => openNextDialogModal(),
            impressionEventSelectionValue, // used as selection(CD67) for tagging
          },
        })
      : openNextDialogModal()
}
