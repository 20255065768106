import clsx from 'clsx'
import React from 'react'

import { useTourWizardConfig } from '../wizard/hooks/use-tour-wizard-config'

import type { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react'

export type TourTimesInputProps = {
  label: string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const TourTimesInput: FC<TourTimesInputProps> = ({
  label,
  ...inputProps
}) => {
  const tourWizardConfig = useTourWizardConfig()
  const theme = tourWizardConfig.theme?.tourTimesStep?.selection

  let className = (
    inputProps.checked && !inputProps.disabled
      ? theme?.checked
      : theme?.unchecked
  ) as string

  if (inputProps.disabled) {
    className = theme?.disabled as string
  }

  return (
    <>
      <input
        data-tag_action="selected_time"
        data-tag_item={label}
        data-tid="tour-times-input"
        type="radio"
        className={theme?.input}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
      />
      <label
        data-tag_action="selected_time"
        data-tag_item={label}
        htmlFor={inputProps.id}
        className={clsx(theme?.base, className)}
      >
        {label}
      </label>
    </>
  )
}
