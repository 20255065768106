import { useAtomValue, useAtom, useSetAtom } from 'jotai'
import { useCallback, useState } from 'react'

import { getScheduleTourInput } from '../graphql/get-schedule-tour-input'
import { tourWizardSubmitHandler } from '../tour-wizard-submit-handler'
import {
  contactFormAtom,
  timeSelectionAtom,
  tourRequestErrorAtom,
  tourTypeAtom,
} from '../../tour-wizard.store'
import { useTourWizardConfig } from './use-tour-wizard-config'
import { Result } from '@swan-io/boxed'
import { oneClickCookieAtom } from '../../../one-click-lead/one-click-lead-cookie.store'
import { SCHEDULE_TOUR } from '../../../one-click-lead/one-click-lead.const'

export type TourId = string | null | undefined

export type UseTourRequestReturn = [
  () => Promise<Result<TourId, Error>>,
  boolean,
  Error | null
]

export function useTourRequestMutation(): UseTourRequestReturn {
  const tourWizardConfig = useTourWizardConfig()
  const timeSelection = useAtomValue(timeSelectionAtom)
  const tourType = useAtomValue(tourTypeAtom)
  const { values: contactInfo } = useAtomValue(contactFormAtom)
  const setOneClickCookieData = useSetAtom(oneClickCookieAtom)
  const [error, setError] = useAtom(tourRequestErrorAtom)

  const { tourProvider } = tourWizardConfig

  const [loading, setLoading] = useState(false)
  const tourRequestVars = getScheduleTourInput(
    tourWizardConfig,
    timeSelection,
    tourType,
    contactInfo
  )

  const tourRequest = useCallback(async () => {
    setLoading(true)

    try {
      if (tourProvider === 'test-with-error') {
        await new Promise<void>((resolve) => {
          // eslint-disable-next-line no-console
          console.info('using tour request vars: ', tourRequestVars)
          setTimeout(() => {
            resolve()
          }, 2000)
        })

        throw new Error('test error')
      }

      return await tourWizardSubmitHandler(
        {
          input: tourRequestVars,
        },
        {
          zid: tourWizardConfig.userId,
        }
      )
        .mapOk((value) => {
          if (
            !value.tourSchedule ||
            tourProvider === 'test-with-tour-submit-error'
          ) {
            throw new Error('No tour schedule returned')
          }

          setOneClickCookieData({
            name: `${tourRequestVars.firstName} ${tourRequestVars.lastName}`,
            phone: tourRequestVars.phone,
            email: tourRequestVars.email,
            moveDate: tourRequestVars.moveInDate,
            applicationOptIn: false,
            tourRequest: true,
            mostRecentLeadTypeSubmitted: SCHEDULE_TOUR,
          })

          if (typeof value.tourSchedule?.meta?.tourId === 'string') {
            return value.tourSchedule.meta.tourId
          }
          return null
        })
        .tapError((graphqlError) => {
          if (graphqlError?.extensions?.code === 'VALIDATION_ERROR') {
            window.eventTracker?.track('error', {
              selection: 'validation',
              validation_error: graphqlError.message,
            })
          }

          setError(graphqlError)
        })
        .mapError((err) => new Error(err.message))
    } catch (err) {
      setError(err as Error)
      return Result.Error(err as Error)
    } finally {
      setLoading(false)
    }
  }, [
    tourProvider,
    tourRequestVars,
    tourWizardConfig.userId,
    setError,
    setOneClickCookieData,
  ])

  return [tourRequest, loading, error]
}
