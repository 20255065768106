/**
 * Type of photo (property photo, floorplan, etc) that is being requested.
 * - Note we are not using an enum here because it makes the react component
 *   harder to use (with importing the enum)
 */
export type PhotoType =
  | 'floorplan'
  | 'management'
  | 'modal'
  | 'property'
  | 'width'

/**
 * Available photo formats to use with the Cloudinary named transformations.
 */
export type PhotoFormat = 'webp' | 'jpg'

/** Options used when creating the image url. */
type GetCloudinaryUrlOptions = {
  isUnpaid: boolean
  format: PhotoFormat
  photoType: PhotoType
}

/**
 * Array that shows order of the standard sizes.
 * - Used to pick the next larger size.
 * - The values must also match the cloudinary named transformations, in the
 *   format "t_{jpg|webp}_{size}", for example "t_webp_xl"
 */

/** The available size keywords that can be used for photos. */
export type PhotoSize = typeof STANDARD_SIZE_ORDER[number]

/** Cloudinary hash value for a photo */
export type PublicId = string

export const photoTypeMap: Record<PhotoType, string> = {
  floorplan: 'w',
  management: 'w', // management logo
  modal: 'w', // modal photo
  property: '3x2_fixed',
  width: 'w',
}

const BASE_URL = 'https://rentpath-res.cloudinary.com'

export const STANDARD_SIZE_ORDER = [
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  '2xl',
] as const
/**
 * Generate a standardized url to transform and fetch a single image in the
 * requested size.
 */
export function getCloudinaryUrl(
  publicId: PublicId, // Cloudinary image hash
  size: PhotoSize,
  options?: Partial<GetCloudinaryUrlOptions>
): string {
  // Set some defaults for the options
  const opt: GetCloudinaryUrlOptions = {
    format: 'webp',
    isUnpaid: false,
    photoType: 'property',
    ...options,
  }

  // Cloudinary named transformation, for example:
  // - `t_3x2_fixed_webp_xl` = property photo, webp format, xl size
  // - `t_w_webp_lg` = floorplan image, webp format, lg size
  const transformation = `t_${photoTypeMap[opt.photoType]}_${
    opt.format
  }_${size}`

  // Unpaid properties get additional transformations to improve the image
  // (because we tend to get lower quality images on those properties).
  // - A named transformation "t_unpaid" has been set up in Cloudinary.
  const transformationQuality =
    opt.isUnpaid && (opt.photoType === 'property' || opt.photoType === 'modal')
      ? `/t_unpaid`
      : ''

  return `${BASE_URL}/${transformation}${transformationQuality}/${publicId}`
}
