import type { TourWizard_ListingFragment } from './__generated__/tour-wizard.gql'

export function getIsScheduleTourEnabled(
  tourProviderDetails: TourWizard_ListingFragment['tourProviderDetails']
) {
  const tourTypesConfig = tourProviderDetails?.types

  const isSomeTourTypeEnabled = Boolean(
    tourTypesConfig && Object.values(tourTypesConfig).some(Boolean)
  )

  return Boolean(tourProviderDetails?.enabled && isSomeTourTypeEnabled)
}
