import { useAtom, useAtomValue } from 'jotai'
import React, { useEffect } from 'react'

import { ThankYouContainer } from '../../thank-you/thank-you-container'
import {
  timeSelectionAtom,
  tourStatusAtom,
  TourWizardStatus,
} from '../tour-wizard.store'
import { useResetAtoms } from './hooks/use-reset-atoms'
import { TourWizardConfigProvider } from './tour-wizard-config-provider'
import { TourWizardFooter } from './tour-wizard-footer'
import { TourWizardHeader } from './tour-wizard-header'
import { TourWizardSteps } from './tour-wizard-steps'

import type { FC } from 'react'

import type { TourWizardProps } from '../tour-wizard.types'
import { formatDateAndTimeString } from '@rentpath/web-utils'

function tourTimeMessageFromTimeSelection(
  timeSelection: string,
  status: TourWizardStatus,
  styles: TourWizardProps['theme']
) {
  if (status !== TourWizardStatus.COMPLETE) return undefined
  const tourTime = timeSelection ? timeSelection.split('T')[0] : ''
  const formattedTime = formatDateAndTimeString(tourTime, timeSelection)

  return (
    <>
      Tour Scheduled!{' '}
      <span className={styles?.tourScheduledTime}>{formattedTime}</span>
    </>
  )
}

export const TourWizard: FC<TourWizardProps> = (tourWizardProps) => {
  const [status] = useAtom(tourStatusAtom)
  const resetAtoms = useResetAtoms()
  const listing = tourWizardProps.listing

  const timeSelection = useAtomValue(timeSelectionAtom)

  const customMessage = tourTimeMessageFromTimeSelection(
    timeSelection,
    status,
    tourWizardProps.theme
  )

  // Reset on unmount
  useEffect(
    () => () => {
      resetAtoms()
    },
    [resetAtoms]
  )

  return (
    <TourWizardConfigProvider tourWizardConfig={tourWizardProps}>
      <div
        data-tid="tour-wizard"
        data-tag_section="schedule_tour_form"
        className={tourWizardProps.theme?.container}
      >
        <TourWizardHeader />
        {status === TourWizardStatus.INCOMPLETE && (
          <div
            className={tourWizardProps.theme?.steps}
            data-tid="tour-wizard-steps"
          >
            <TourWizardSteps />
            <TourWizardFooter />
          </div>
        )}
        {status === TourWizardStatus.COMPLETE && (
          <ThankYouContainer
            heading=""
            listing={listing}
            onClose={tourWizardProps.onClose}
            initialCustomMessage={customMessage}
            showEmailLeadForScheduleTour
            shouldforceRedesign
          />
        )}
      </div>
    </TourWizardConfigProvider>
  )
}
