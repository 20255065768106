import type { Listing } from '../../__generated__/api-types'

export function includeUnpaidPdpAds(
  tplSource: Listing['tplsource'],
  offMarket: Listing['offMarket']
) {
  // if offmarket is true, show unpaid ads
  if (offMarket) {
    return true
  }

  // product states that free ads should be effective for the following tplsources: inactive, lnp, corelogic, lovely, and listhub
  if (tplSource) {
    return ['inactive', 'lnp', 'corelogic', 'lovely', 'listhub'].includes(
      tplSource.toLowerCase()
    )
  }

  return false
}
