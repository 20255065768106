/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type Footer_ListingFragment = { disclaimer?: string | null };

export const Footer_ListingFragmentDoc = `
    fragment Footer_ListingFragment on Listing {
  disclaimer
}
    `;



