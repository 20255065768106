/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type HighlightedAmenities_ListingFragment = { amenitiesHighlighted: Array<string>, categoryBadges: Array<string>, uniqueHighlights: Array<string> };

export const HighlightedAmenities_ListingFragmentDoc = `
    fragment HighlightedAmenities_ListingFragment on Listing {
  amenitiesHighlighted
  categoryBadges
  uniqueHighlights
}
    `;



