import clsx from 'clsx'
import { IconButton } from '../../../../components/icon-button/icon-button'
import { useEffect, useState } from 'react'
import { ReactComponent as ArrowRightIcon } from '@brand/icons/arrow-right.svg'
import styles from './loading-properties.module.css'

type LoadingPropertiesProps = {
  onClickViewProperties?: () => void
  onLoadingEnded?: () => void
  isViewMoreVisible?: boolean
  progressLoadingDuration: number
  title: string
}

type ProgressBarProps = {
  animationDuration: number
  onAnimationEnded: () => void
}

function ProgressBar(props: ProgressBarProps) {
  const [active, setActive] = useState(false)

  useEffect(() => {
    const t = setTimeout(() => setActive(true), 100)
    return () => {
      clearTimeout(t)
    }
  }, [])

  return (
    <div className={styles.progressBar}>
      <div
        data-tid="progressBar"
        className={clsx(styles.progress, active && styles.active)}
        style={{ transition: `width ${props.animationDuration}s` }}
        onTransitionEnd={props.onAnimationEnded}
      />
    </div>
  )
}

export function LoadingProperties(props: LoadingPropertiesProps) {
  const [propertiesBtnEnabled, setPropertiesBtnEnabled] = useState(false)

  function onLoadingEnded() {
    setPropertiesBtnEnabled(true)
    props.onLoadingEnded?.()
  }
  return (
    <div className={styles.loadingContainerWrapper}>
      <div className={styles.loadingContainer}>
        <p className={styles.title}>{props.title}</p>
        <ProgressBar
          animationDuration={props.progressLoadingDuration}
          onAnimationEnded={onLoadingEnded}
        />
      </div>
      {props.isViewMoreVisible && (
        <IconButton
          rounded
          showLabel
          iconPosition="right"
          icon={<ArrowRightIcon />}
          variant="primary"
          label="View Properties"
          disabled={!propertiesBtnEnabled}
          onClick={() => props.onClickViewProperties?.()}
        />
      )}
    </div>
  )
}
