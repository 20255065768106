export enum TaggingPageType {
  DETAIL = 'pdp',
  DOWNLOAD = 'mobile_apps',
  ERROR = 'error',
  HOME = 'home',
  LIST_A_PROPERTY = 'manage',
  MOBILE_APPS = 'mobile_apps',
  NEIGHBORHOOD = 'neighborhood',
  PROFILE_SETTINGS = 'profile',
  RENT_TRENDS = 'rent_trends',
  RENTAL_CALCULATOR_ERROR = 'rental_calculator_error',
  RENTAL_CALCULATER_FORM = 'rental_calculator_form',
  RENTAL_CALCULATOR_RESULTS = 'rental_calculator_results',
  RENTAL_SAFETY = 'rental_safety',
  REVIEW_THANK_YOU = 'review_submission_thank_you',
  SAVED_PROPERTIES = 'my_places',
  SAVED_SEARCHES = 'saved_searches',
  SEARCH_MAP = 'srp_map',
  SEARCH = 'srp',
  SECURITY = 'security',
  SITEMAP = 'sitemap',
  STATE_LANDER = 'state_lander',
  WRITE_REVIEW = 'write_review',
}
