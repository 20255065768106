import React, { useState } from 'react'
import type { ImageProps } from 'next/image'
import Image from 'next/image'

// For use in place of small images (listing card and smaller)
import noImageSmall from '@brand/static/images/no-image-small.png'

// For use in place of large images (e.g., detail page gallery photo)
import noImageLarge from '@brand/static/images/no-image-large.png'

export interface ImageWithFallbackProps extends ImageProps {
  fallbackImageSize: 'sm' | 'lg'
}

export function ImageWithFallback(props: ImageWithFallbackProps) {
  const { src, fallbackImageSize, alt, ...rest } = props
  const [imgSrc, setImgSrc] = useState(src)

  return (
    <Image
      {...rest}
      alt={alt}
      src={imgSrc}
      onError={() => {
        setImgSrc(
          fallbackImageSize === 'sm' ? noImageSmall.src : noImageLarge.src
        )
      }}
    />
  )
}
