import clsx from 'clsx'
import { forwardRef } from 'react'
import type { DetailedHTMLProps, InputHTMLAttributes } from 'react'
import styles from './checkbox.module.css'

type CheckboxProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string
  pill?: string
  'data-tag_item'?: string
  labelPosition?: 'left' | 'right'
  'data-tag_action'?: string
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(
    {
      label,
      pill,
      className,
      'data-tag_item': dataTagItem,
      'data-tag_action': dataTagAction,
      labelPosition = 'right',
      ...props
    },
    ref
  ) {
    return (
      <label
        className={clsx(styles.container, className)}
        // data-tag_item needs to go on the parent or otherwise tag-manager will autofire
        // an additional click event with the closest `data-tag_item` up the tree
        data-tag_item={dataTagItem}
        data-tag_action={dataTagAction}
      >
        {/* data-tag_action="ignore" prevents double-firing of a click event when the input is checked/unchecked */}
        {labelPosition === 'left' && (
          <span className={clsx(styles.label, styles.labelLeft)}>{label}</span>
        )}
        <input type="checkbox" {...props} data-tag_action="ignore" ref={ref} />
        <span className={styles.checkmark} />
        {labelPosition === 'right' && (
          <span className={clsx(styles.label, styles.labelLeft)}>{label}</span>
        )}
        {pill && <span className={styles.pill}>{pill}</span>}
      </label>
    )
  }
)
