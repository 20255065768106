import { ReactComponent as ArrowRightIcon } from '@brand/icons/arrow-right.svg'
import formStyles from '../thank-you-redesign-listing-cards/thank-you-redesign-stacked-cards-wrapper.module.css'
import { PhoneInput } from '../../../../components/forms/phone-input'
import { useForm } from 'react-hook-form'
import { validatePhone } from '@rentpath/form-validation'
import { Button } from '../../../../components/button/button'
import { RentTermsLinks } from '../../../../components/rent-terms/rent-terms-links'
import styles from './thank-you-redesign-phone.module.css'

import type { LeadData } from '../../../lead-form/lead-form'
import type { OneClickCookieData } from '../../../one-click-lead/one-click-lead-cookie.store'

interface ThankYouRedesignPhoneProps {
  onSkipClick?: () => void
  isLastListing?: boolean
  submitLead: (oneClickData: Partial<OneClickCookieData>) => void
}

export const REQUIRED_PHONE_MESSAGE = 'This property requires a phone number'

export function ThankYouRedesignPhone({
  onSkipClick,
  isLastListing,
  submitLead,
}: ThankYouRedesignPhoneProps) {
  const { formState, register, watch, getValues } = useForm<LeadData>({
    mode: 'onTouched',
  })
  const { errors, isValid } = formState
  const ctaText = isLastListing ? 'Skip This Step' : 'Skip to Next Property'

  const handleSubmit = () => {
    const { phone } = getValues()
    submitLead({ phone })
  }

  return (
    <div className={formStyles.modalWrapper}>
      <div className={styles.phoneForm}>
        <div className={styles.phoneTitleContainer}>
          <p className={styles.phoneTitle}>{REQUIRED_PHONE_MESSAGE}</p>
        </div>
        <PhoneInput
          data-tag_item="phone_number"
          error={errors.phone?.message ?? undefined}
          label="Phone"
          required
          data-tid="phone_input"
          value={watch('phone')}
          {...register('phone', {
            validate: (v) => validatePhone(v, true),
          })}
        />
        <Button
          fluid
          rounded
          variant="primary"
          data-tag_item="request_tour_submission"
          data-tid="submit"
          onClick={handleSubmit}
          disabled={!isValid}
        >
          Send
        </Button>
        <div
          data-tid="lead-form-terms_of_service"
          className={styles.leadFormFooter}
        >
          {`By submitting this form, you agree to Rent's`}
          <br />
          <RentTermsLinks />.
        </div>
      </div>
      <div className={formStyles.ctaButtons}>
        <div
          className={formStyles.notInterested}
          onClick={onSkipClick}
          data-tid="skip"
        >
          <div className={formStyles.iconWrapper}>
            <ArrowRightIcon className={formStyles.notInterestedIcon} />
          </div>
          <span className={formStyles.ctaLabel}>{ctaText}</span>
        </div>
      </div>
    </div>
  )
}
