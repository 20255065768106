/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { PublicTransitStop_PublicTransitStopFragmentDoc } from './public-transit-card.gql';
export type PublicTransitFieldsFragment = { stopsCountByType: Array<{ type: Types.PublicTransitStopType, count: number }>, stops: Array<{ routes: Array<string>, type?: Types.PublicTransitStopType | null, name?: string | null, distanceInMiles?: number | null }>, agencies: Array<{ url?: string | null, name?: string | null }> };

export const PublicTransitFieldsFragmentDoc = `
    fragment PublicTransitFields on PublicTransit {
  stopsCountByType {
    type
    count
  }
  stops {
    ...PublicTransitStop_PublicTransitStopFragment
  }
  agencies {
    url
    name
  }
}
    `;



