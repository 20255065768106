import { forwardRef } from 'react'
import type { InputProps } from '../input/input'
import { Input } from '../input/input'

export const NameInput = forwardRef<
  HTMLInputElement,
  Omit<InputProps, 'label'> & { label?: InputProps['label'] }
>((props, ref) => (
  <Input autoComplete="name" label="Name" type="text" {...props} ref={ref} />
))

NameInput.displayName = 'NameInput'
