import clsx from 'clsx'
import type { DataAttributes } from '../../types'
import type { LeadFormProps } from './lead-form'
import styles from './lead-form-header.module.css'
import { Heading } from '../../components/heading/heading'
import { useIsEmailCtaBasicAvailableListing } from '../detail/hooks/use-is-email-cta-basic-available-listing'

type LeadFormHeaderProps = {
  isModal?: boolean
} & Pick<LeadFormProps, 'listing'> &
  DataAttributes

export function LeadFormHeader({
  listing,
  isModal,
  ...props
}: LeadFormHeaderProps) {
  const { name, isBasic } = listing
  const isEmailCTAOnAvailableBasicListing =
    useIsEmailCtaBasicAvailableListing(listing)

  return (
    <header
      data-tag-section={props?.['data-tag_section']}
      className={clsx(
        styles.leadFormHeader,
        isBasic && styles.leadFormHeaderBasic,
        isModal && isBasic && styles.leadFormHeaderBasicModal
      )}
    >
      {isBasic && !isEmailCTAOnAvailableBasicListing ? (
        <>
          <span className={styles.leadFormHeaderText}>
            Currently Unavailable
          </span>
          {isModal && <div className={styles.leadFormHeaderTo}>To: {name}</div>}
        </>
      ) : (
        <>
          <Heading withBodyFont as="h3">
            <div className={styles.leadFormHeaderTo}>To: {name}</div>
          </Heading>
        </>
      )}
    </header>
  )
}
