import type { DiamondMaxHero_ListingFragment } from '../../brands/ag/slots/home/home-page-hero/diamond-max-hero/__generated__/diamond-max-hero.gql'
import type { Photo } from '../../__generated__/api-types'

export function getDiamondMaxLeadPhoto(
  diamondMaxPhoto?: DiamondMaxHero_ListingFragment['diamondMaxPhoto'],
  firstPhoto?: Photo | null
) {
  return (
    diamondMaxPhoto?.lg ||
    diamondMaxPhoto?.md ||
    diamondMaxPhoto?.sm ||
    firstPhoto
  )
}
