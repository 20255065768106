import React from 'react'

import type { FC, PropsWithChildren } from 'react'
import { useTourWizardConfig } from './hooks/use-tour-wizard-config'

export const TourWizardOverlay: FC<PropsWithChildren> = ({
  children,
  ...rest
}) => {
  const { theme } = useTourWizardConfig()
  return (
    <div
      className={theme?.overlay}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </div>
  )
}
