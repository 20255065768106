/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type LeasingTerms_ListingFragment = { leasingTerms: Array<string>, specialTerms?: string | null, incomeRestrictions?: Array<{ maxAnnualIncome?: string | null, maxOccupants?: string | null }> | null };

export const LeasingTerms_ListingFragmentDoc = `
    fragment LeasingTerms_ListingFragment on Listing {
  incomeRestrictions {
    maxAnnualIncome
    maxOccupants
  }
  leasingTerms
  specialTerms
}
    `;



