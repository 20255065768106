import { LeadFormDisclaimer } from '@brand/slots/lead-form/lead-form-disclaimer'
import {
  EMAIL_REGEX,
  errorMessages,
  NAME_MIN_LENGTH,
  validateDate,
  validatePhone,
} from '@rentpath/form-validation'
import { useAtom, useSetAtom } from 'jotai'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../../../components/button/button'
import { DateInput } from '../../../components/date-input/date-input'
import { EmailInput } from '../../../components/forms/email-input'
import { MessageInput } from '../../../components/forms/message-input'
import { NameInput } from '../../../components/forms/name-input'
import { PhoneInput } from '../../../components/forms/phone-input'
import { Heading } from '../../../components/heading/heading'
import { REQUEST_TOUR } from '../../cta-buttons/cta-buttons.const'
import { useDefaultMoveDate } from '../../lead-form/hooks/use-default-move-date'
import { useUpdateEventTrackerForLeads } from '../../lead-form/hooks/use-update-event-tracker-for-leads'
import { leadCookieAtom } from '../../lead-form/lead-cookie.store'
import { getShouldShowNativeDatePicker } from '../../lead-form/utils/get-should-show-native-date-picker'
import { getShouldShowSmsConsent } from '../../lead-form/utils/get-should-show-sms-consent'
import { RentTermsLinks } from '../../../components/rent-terms/rent-terms-links'
import { useRequestData } from '../../request-data/pages-router/use-request-data'
import { DayTimePicker } from '../day-time-picker/day-time-picker'
import {
  currentStepAtom,
  RequestTourSteps,
} from '../request-a-tour-wizard.store'
import { useLeadSubmission } from '../use-lead-submission'
import type { LeadForm_ListingFragment } from '../../lead-form/__generated__/lead-form.gql'
import type { RequestTourFormData } from '../use-lead-submission'
import styles from './contact-day-picker-form.module.css'
import type { getLocationRelativeToSearchedCity } from '../../search/utils/get-location-relative-to-searched-city'
import { SubmitLeadCTAButton } from '../submit-lead-cta-button'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { requestTourSubHeadline } from '../request-a-tour.const'

interface ContactDayPickerFormProps {
  listing: LeadForm_ListingFragment
  currentRefinementSlugs?: string[]
  onClose?: () => void
  message: string
  setContactFormData: (data: RequestTourFormData) => void
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
}

export const emptyRequestTourData: RequestTourFormData = {
  email: '',
  message: '',
  moveDate: '',
  name: '',
  phone: '',
}

export function ContactDayPickerForm({
  listing,
  currentRefinementSlugs,
  message,
  setContactFormData,
  locationRelativeToSearchedCity,
}: ContactDayPickerFormProps) {
  const { isMobile } = useRequestData()
  const setNextStep = useSetAtom(currentStepAtom)
  const isPhoneRequired = Boolean(listing.leadPhoneRequired)
  const shouldShowSmsConsent = getShouldShowSmsConsent(listing)
  const showDateInput = !listing.isBasic
  const showNativeDatePicker = getShouldShowNativeDatePicker(isMobile)
  const [leadCookieData, setLeadCookieData] = useAtom(leadCookieAtom)
  const defaultMoveDate = useDefaultMoveDate(leadCookieData)
  const isEnhanced = Boolean(listing.leadQuestions?.ids?.length)
  useUpdateEventTrackerForLeads(listing.id, listing.tplsource)

  const defaultValues = {
    ...emptyRequestTourData,
    ...leadCookieData,
    message,
    moveDate: defaultMoveDate,
  }

  const { formState, handleSubmit, register, setError, watch } =
    useForm<RequestTourFormData>({
      defaultValues,
      mode: 'onTouched',
    })

  const { errors, isValid } = formState

  const { handleValidSubmit, isSending } = useLeadSubmission({
    currentRefinementSlugs,
    listing,
    setError,
    shouldShowSmsConsent,
    locationRelativeToSearchedCity,
  })

  const requestTourSubheadlineText = useFeatureVariable<string>(
    ['tour_headline_copy', 'request_tour_subheadline_text'],
    requestTourSubHeadline
  )

  const requestTourHeadlineText = useFeatureVariable<string>(
    ['tour_headline_copy', 'request_tour_headline_text'],
    REQUEST_TOUR
  )

  function NextCta() {
    return (
      <Button
        type="button"
        fluid
        rounded
        variant="primary"
        data-tid="elf_rt_next"
        data-tag_item="next_button"
        disabled={!isValid}
        onClick={handleSubmit((data: RequestTourFormData, _event) => {
          setContactFormData(data) // lead cookie data + message
          const { email, moveDate, name, phone } = data
          setLeadCookieData({ email, moveDate, name, phone })
          setNextStep(RequestTourSteps.enhancedLeadQuestions)
        })}
      >
        Next
      </Button>
    )
  }

  return (
    <form
      data-tid="request-tour-form"
      data-tag_section="request_tour_form"
      className={styles.requestTourForm}
      noValidate
      onSubmit={handleSubmit(handleValidSubmit)}
    >
      <div className={styles.inputsHeaderWrapper}>
        <div className={styles.dayPicker}>
          <Heading withBodyFont level="1x">
            {requestTourHeadlineText}
          </Heading>
          <p className={styles.dayPickerBodyText}>
            {requestTourSubheadlineText}
          </p>
          <DayTimePicker
            showArrows={!isMobile}
            modalForm
            officeHours={listing.officeHours}
          />
        </div>

        <div className={styles.contactInfo}>
          <NameInput
            data-tid="contact-form-name"
            error={errors.name?.message ?? undefined}
            required
            value={watch('name')}
            {...register('name', {
              required: errorMessages.NAME_EMPTY,
              minLength: {
                value: NAME_MIN_LENGTH,
                message: errorMessages.NAME_MIN_LENGTH,
              },
            })}
          />

          <EmailInput
            data-tid="contact-form-email"
            error={errors.email?.message ?? undefined}
            value={watch('email')}
            required
            {...register('email', {
              required: errorMessages.EMAIL_EMPTY,
              pattern: {
                value: EMAIL_REGEX,
                message: errorMessages.EMAIL_INVALID_LONG,
              },
            })}
          />

          <PhoneInput
            data-tid="contact-form-phone"
            error={errors.phone?.message ?? undefined}
            label={isPhoneRequired ? 'Phone' : 'Phone (optional)'}
            required={isPhoneRequired}
            value={watch('phone')}
            {...register('phone', {
              validate: (v) => validatePhone(v, isPhoneRequired),
            })}
          />

          {showDateInput && (
            <DateInput
              showInPortal
              showNativeDatePicker={showNativeDatePicker}
              data-tid="contact-form-date"
              error={errors.moveDate?.message ?? undefined}
              required
              value={watch('moveDate', defaultValues.moveDate)}
              {...register('moveDate', {
                validate: (v) => validateDate(v, isMobile),
              })}
            />
          )}
          <MessageInput
            label="Message (optional)"
            data-tid="contact-form-message"
            value={watch('message')}
            {...register('message')}
          />
        </div>

        <div data-tid="terms_of_service" className={styles.termsOfService}>
          {`By submitting this form, you agree to ${
            shouldShowSmsConsent ? 'our' : "Rent's"
          } `}
          <br />
          <RentTermsLinks />. {shouldShowSmsConsent && <LeadFormDisclaimer />}
        </div>
      </div>

      <footer className={styles.footer}>
        {isEnhanced ? (
          <NextCta />
        ) : (
          <SubmitLeadCTAButton
            isSending={isSending}
            isValid={isValid}
            title="Send Request"
            dataTag="request_tour_submission"
          />
        )}
      </footer>
    </form>
  )
}
