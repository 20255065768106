import { formatMilitaryTime, parseUnixTimeStamp } from '@rentpath/web-utils'
import type { TourTimesListProps } from '../tour-times-list'

/**
 * Returns the list of formatted times to render on each day, in ascending order.
 * @example```
 * getTimeSlotsTemplate([{
 *   date: "2023-03-09",
 *   times: [
 *     '2023-03-09T16:15:00-05:00',
 *     '2023-03-09T15:45:00-05:00',
 *   ]
 * }, {
 *    date: '2023-03-10',
 *    times: [
 *      '2023-03-10T16:30:00-05:00',
 *      '2023-03-10T16:00:00-05:00',
 *    ]
 * }]) // ['3:45 PM', '4:00 PM', '4:15 PM', '4:30 PM']
 * ```
 * @param availableDates
 * @returns - list of time slots to render
 */
export function getTimeSlotsTemplate(
  availableDates: TourTimesListProps['availableDates']
): string[] {
  if (!availableDates?.length) return []

  const timeSlots: string[] = []
  availableDates.forEach((day) => {
    const times = day?.times || []

    times.forEach((time) => {
      const parsed = parseUnixTimeStamp(time)
      if (!timeSlots.includes(parsed)) timeSlots.push(parsed)
    })
  })

  return timeSlots.sort().map(formatMilitaryTime)
}
