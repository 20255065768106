import { getCloudinaryUrl } from '../../components/cloud-image/get-cloudinary-url'
import type { DiamondMaxHero_ListingFragment } from '../../brands/ag/slots/home/home-page-hero/diamond-max-hero/__generated__/diamond-max-hero.gql'
import type { ReactElement } from 'react'

export function getDiamondMaxPhotoSources(
  diamondMaxPhoto?: DiamondMaxHero_ListingFragment['diamondMaxPhoto']
) {
  const small = diamondMaxPhoto?.sm
  const medium = diamondMaxPhoto?.md
  const large = diamondMaxPhoto?.lg

  if (!small && !medium && !large) {
    return []
  }

  const sources: ReactElement<HTMLSourceElement>[] = []

  if (small) {
    sources.push(
      <source
        media="(max-width: 768px)"
        srcSet={getCloudinaryUrl(small.id || '', 'lg')}
      />
    )
  }

  if (medium) {
    sources.push(
      <source
        media="(max-width: 1024px)"
        srcSet={getCloudinaryUrl(medium.id || '', 'xl')}
      />
    )
  }

  if (large) {
    sources.push(<source srcSet={getCloudinaryUrl(large.id || '', '2xl')} />)
  }

  return sources
}
