const targetCodeIndex = 0
const advertiserIndex = 1
const propertyTypeIndex = 2
const pdpSummaryVersionIndex = 3

export type AdTargetData = {
  targetCode?: string | null
  advertiser?: string | null
  propertyType?: string | null
  pdpSummaryVersion?: string | null
}
/**
 * Currently only one advertiser is supported. Should we need more than one advertiser, we will need support from Playwire.
 */
export function getAdsTargets(
  { targetCode, advertiser, pdpSummaryVersion, propertyType }: AdTargetData,
  targets?: string[]
) {
  // Note--Currently playwire only allows us to pass 4 items in this array.
  // So if we need to add more, we will need to work with playwire to increase the size of this array.

  const defaultAdTargets = ['', '', '', ''] // targetCode, advertiser, propertyType, pdpSummaryVersion
  const newTargets = targets || defaultAdTargets
  if (targetCode) {
    newTargets[targetCodeIndex] = targetCode
  }

  if (advertiser) {
    newTargets[advertiserIndex] = advertiser.toLowerCase()
  }

  if (propertyType) {
    newTargets[propertyTypeIndex] = propertyType.toLowerCase()
  }

  if (pdpSummaryVersion) {
    newTargets[pdpSummaryVersionIndex] = pdpSummaryVersion.toLowerCase()
  }

  return newTargets
}
