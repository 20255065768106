/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type RandomLinks_LocationFragment = { state?: string | null };

export type RandomLinks_ListingFragment = { name?: string | null, address?: string | null, urlPathname?: string | null };

export type RandomLinks_QueryFragment = { randomLinks: { state?: string | null, listingSearch?: { listings: Array<{ name?: string | null, address?: string | null, urlPathname?: string | null }> } | null } };

export type RandomLinksQueryVariables = Types.Exact<{
  randomLinksSeed: Types.Scalars['String'];
  randomLinksState: Types.Scalars['String'];
  randomLinksCity?: Types.InputMaybe<Types.Scalars['String']>;
  randomLinksResultCount?: Types.InputMaybe<Types.Scalars['Int']>;
  includedPropertyTypes?: Types.InputMaybe<Array<Types.PropertyTypeFilter> | Types.PropertyTypeFilter>;
}>;


export type RandomLinksQuery = { randomLinks: { state?: string | null, listingSearch?: { listings: Array<{ name?: string | null, address?: string | null, urlPathname?: string | null }> } | null } };

export const RandomLinks_LocationFragmentDoc = `
    fragment RandomLinks_LocationFragment on Location {
  state
}
    `;
export const RandomLinks_ListingFragmentDoc = `
    fragment RandomLinks_ListingFragment on Listing {
  name
  address
  urlPathname
}
    `;
export const RandomLinks_QueryFragmentDoc = `
    fragment RandomLinks_QueryFragment on Query {
  randomLinks: location(input: {state: $randomLinksState, city: $randomLinksCity}) {
    ...RandomLinks_LocationFragment
    listingSearch(
      filters: {excludeCityState: "Yakutat, AK", propertyTypes: $includedPropertyTypes}
      sort: RANDOM
      sortRandomSeed: $randomLinksSeed
      resultsPerPage: $randomLinksResultCount
    ) {
      listings {
        ...RandomLinks_ListingFragment
      }
    }
  }
}
    `;
export const RandomLinksQueryDocument = `
    query RandomLinksQuery($randomLinksSeed: String!, $randomLinksState: String!, $randomLinksCity: String, $randomLinksResultCount: Int = 30, $includedPropertyTypes: [PropertyTypeFilter!]) {
  ...RandomLinks_QueryFragment
}
    ${RandomLinks_QueryFragmentDoc}
${RandomLinks_LocationFragmentDoc}
${RandomLinks_ListingFragmentDoc}`;

    export function createRandomLinksQueryFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchRandomLinksQuery(variables: RandomLinksQueryVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<RandomLinksQuery, RandomLinksQueryVariables>(RandomLinksQueryDocument, variables, headers);
      }
    }

