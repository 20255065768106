import { getBrandWebsite } from '@brand/config/brand-config'
import { LeadFormDisclaimer } from '@brand/slots/lead-form/lead-form-disclaimer'
import {
  EMAIL_REGEX,
  errorMessages,
  NAME_MIN_LENGTH,
  validateDate,
  validatePhone,
} from '@rentpath/form-validation'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useId, useMemo, useState } from 'react'
import type { SubmitErrorHandler, SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import type { LeadSubmissionInput } from '../../__generated__/api-types'
import {
  LeadChannel,
  LeadTour,
  LeadSmsConsentEntity,
} from '../../__generated__/api-types'
import { Button } from '../../components/button/button'
import { DateInput } from '../../components/date-input/date-input'
import { EmailInput } from '../../components/forms/email-input'
import { MessageInput } from '../../components/forms/message-input'
import { NameInput } from '../../components/forms/name-input'
import { PhoneInput } from '../../components/forms/phone-input'
import { Checkbox } from '../../components/input/checkbox'
import { ClientOnly } from '../../components/utils/client-only'
import type { TaggingProps } from '../../types'
import { RentTermsLinks } from '../../components/rent-terms/rent-terms-links'
import { useRequestData } from '../request-data/pages-router/use-request-data'
import { userAddEmailedEvent } from '../user-event-tracking/user-add-emailed-event'
import { useZutronId } from '../user/user.store'
import type { LeadForm_ListingFragment } from './__generated__/lead-form.gql'
import { useDefaultMoveDate } from './hooks/use-default-move-date'
import { useUpdateEventTrackerForLeads } from './hooks/use-update-event-tracker-for-leads'
import { leadCookieAtom } from './lead-cookie.store'
import { oneClickCookieAtom } from '../one-click-lead/one-click-lead-cookie.store'
import styles from './lead-form.module.css'
import { PropertyWebsiteLinkFooter } from './property-website-link-footer'
import { submitLeadWithErrorHandling } from './submit-lead'
import { createFacebookDataForLead } from './utils/create-facebook-data-for-lead'
import {
  trackErrors,
  trackSuccess,
  LeadCategoryRate,
} from './utils/event-tracking'
import { getLeadDeviceType } from './utils/get-lead-device-type'
import { getLeadMessagePlaceholder } from './utils/get-lead-message-placeholder'
import { getLeadRefinements } from './utils/get-lead-refinements'
import { getShouldShowNativeDatePicker } from './utils/get-should-show-native-date-picker'
import { getShouldShowSmsConsent } from './utils/get-should-show-sms-consent'
import { getMessageWithIntent } from './utils/get-message-with-intent'
import { EMAIL } from '../one-click-lead/one-click-lead.const'
import type { getLocationRelativeToSearchedCity } from '../search/utils/get-location-relative-to-searched-city'
import { LeadFormSteps } from '../enhanced-lead-form/const'
import { useIsEmailCtaBasicAvailableListing } from '../detail/hooks/use-is-email-cta-basic-available-listing'

import { buildEnhancedLeadSubmissionFields } from '../enhanced-lead-form/utils'
import {
  enhancedLeadCookieAtom,
  enhancedLeadsAtom,
} from '../enhanced-lead-form/enhanced-leads.store'
import { EnhancedLeadQuestionForm } from '../enhanced-lead-form/enhanced-lead-questions'
import { SubmitLeadCTAButton } from '../request-a-tour/submit-lead-cta-button'

export type LeadData = {
  context: string
  email: string
  message: string
  moveDate: string
  name: string
  optInNewsletter: boolean
  phone: string
  requestATour: boolean
  submittingApplication: boolean
}

export type LeadFormProps = {
  defaultIsSubmittingApplicationChecked?: boolean
  defaultIsRequestATourChecked?: boolean
  onStepChange?: (step: LeadFormSteps) => void
  currentStep?: LeadFormSteps
  showInCalendarInPortal?: boolean
  listing: LeadForm_ListingFragment
  currentRefinementSlugs?: string[]
  onValidSubmit?: (values: LeadData) => void
  isDisplayPropertyWebsiteLink?: boolean
  withNewsletterSignUp?: boolean
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
} & TaggingProps

const defaultLeadData: LeadData = {
  context: 'modal',
  email: '',
  message: '',
  moveDate: '',
  name: '',
  optInNewsletter: false,
  phone: '',
  requestATour: false,
  submittingApplication: false,
}

export function LeadForm({
  onValidSubmit,
  currentStep = LeadFormSteps.formFields,
  defaultIsRequestATourChecked,
  defaultIsSubmittingApplicationChecked,
  listing,
  onStepChange,
  currentRefinementSlugs,
  showInCalendarInPortal,
  isDisplayPropertyWebsiteLink,
  withNewsletterSignUp = true,
  locationRelativeToSearchedCity,
  ...props
}: LeadFormProps) {
  const componentId = useId()
  const [formStep, setFormStep] = useState(currentStep)
  const [isSending, setIsSending] = useState(false)
  const [leadCookieData, setLeadCookieData] = useAtom(leadCookieAtom)
  const setOneClickCookieData = useSetAtom(oneClickCookieAtom)
  const enhancedLeadsFormValues = useAtomValue(enhancedLeadsAtom)
  const setEnhancedLeadsCookie = useSetAtom(enhancedLeadCookieAtom)
  const zutronId = useZutronId()
  const { visitId, sessionId, isMobile, ipLocation, ip, useGDPR, sem } =
    useRequestData()
  const showPaidLeadMessage = useIsEmailCtaBasicAvailableListing(listing)
  const defaultMessage = useMemo(
    () =>
      getLeadMessagePlaceholder(
        { name: listing.name, isBasic: listing.isBasic },
        showPaidLeadMessage
      ),
    [listing.name, listing.isBasic, showPaidLeadMessage]
  )
  const isCountryUS = Boolean(ipLocation?.isUSA)
  const isPhoneRequired = Boolean(listing.leadPhoneRequired)
  const isEnhanced = Boolean(listing.leadQuestions?.ids?.length)
  const shouldShowSmsConsent = getShouldShowSmsConsent(listing)

  useEffect(() => {
    setFormStep(currentStep || LeadFormSteps.formFields)
  }, [currentStep])

  const showDateInput = !listing.isBasic
  const showNativeDatePicker = getShouldShowNativeDatePicker(isMobile)
  const showTakingATourCheckbox = !listing.isBasic
  const showApplicationCheckbox = Boolean(listing.applicationUrl)
  const showOptionalCheckboxes =
    showTakingATourCheckbox || showApplicationCheckbox
  const leadTypeId = !listing.isBasic
    ? LeadChannel.Active
    : LeadChannel.Inactive
  useUpdateEventTrackerForLeads(listing.id, listing.tplsource)

  const defaultMoveDate = useDefaultMoveDate(leadCookieData)

  const defaultValues = useMemo(
    () => ({
      ...defaultLeadData,
      ...leadCookieData,
      moveDate: defaultMoveDate,
      message: defaultMessage,
      requestATour: defaultIsRequestATourChecked,
      submittingApplication: defaultIsSubmittingApplicationChecked,
    }),
    [
      defaultIsRequestATourChecked,
      defaultIsSubmittingApplicationChecked,
      defaultMessage,
      defaultMoveDate,
      leadCookieData,
    ]
  )

  const { formState, handleSubmit, register, resetField, watch } =
    useForm<LeadData>({
      defaultValues,
      mode: 'onTouched',
    })
  const { errors } = formState
  const isSubmittingAppChecked = watch('submittingApplication')
  const isRequestATourChecked = watch('requestATour')
  const dataTagProps = props['data-tag_section'] || 'lead_submission_form'
  const dataTagSection = isEnhanced ? `${dataTagProps}_e` : dataTagProps

  // If the user changes the computed default values, reset the form.
  // This covers cases where we have multiple lead forms on the same page.
  // only reset fields stored via leadCookieData so that we can persist
  // fields that are not stored in the cookie (e.g. message) between steps
  useEffect(() => {
    Object.entries(leadCookieData).forEach(([key, value]) => {
      if (key in defaultValues) {
        resetField(key as keyof LeadData, {
          defaultValue: key === 'moveDate' ? defaultMoveDate : value,
        })
      }
    })
  }, [defaultMoveDate, defaultValues, leadCookieData, resetField])

  useEffect(
    function resetMessageOnListingChange() {
      resetField('message', { defaultValue: defaultMessage })
    },
    [resetField, defaultMessage]
  )

  function getIntents() {
    const intents: string[] = []
    if (isSubmittingAppChecked) {
      intents.push('application')
    }
    if (isRequestATourChecked) {
      intents.push('request_tour')
    }
    if (!intents.length) {
      intents.push('none')
    }

    return intents
  }

  const handleValidSubmit: SubmitHandler<LeadData> = (leadData) => {
    const fbData = createFacebookDataForLead({
      email: leadData.email,
      listingId: listing.id,
      ip,
      revenue: listing.revenue,
      useGDPR,
      sessionId,
      visitId,
    })

    const enhancedLeadFormatted = isEnhanced
      ? buildEnhancedLeadSubmissionFields(
          listing.leadQuestions?.ids || [],
          enhancedLeadsFormValues
        )
      : {}

    const lead: LeadSubmissionInput = {
      applicationOptIn: Boolean(leadData.submittingApplication),
      campaignId: sem.marketingCampaignId,
      context: leadData.context,
      device: getLeadDeviceType(),
      email: leadData.email,
      evTransId: `${visitId}.${sessionId}_${listing.id}`,
      facebookData: fbData,
      leadTypeId,
      listingId: listing.id,
      message: getMessageWithIntent(
        leadData,
        listing,
        enhancedLeadsFormValues,
        showPaidLeadMessage
      ),
      moveDate: leadData.moveDate,
      fullName: leadData.name,
      phone: leadData.phone,
      optInNewsletter: isCountryUS || leadData.optInNewsletter,
      refinements: getLeadRefinements(currentRefinementSlugs),
      smsConsentEntities: shouldShowSmsConsent
        ? [LeadSmsConsentEntity.Advertiser]
        : null,
      smsOptIn: shouldShowSmsConsent,
      taggingHitId: window.hit_id,
      website: getBrandWebsite(isMobile),
      tourType: leadData.requestATour ? LeadTour.Unspecified : null,
      sessionId: `${visitId}.${sessionId}`,
      tvisit: `${visitId}.${sessionId}`,
      ...enhancedLeadFormatted,
    }

    setIsSending(true)

    const headers = zutronId ? { zid: zutronId } : undefined

    submitLeadWithErrorHandling({ lead }, headers)
      .tapOk(() => {
        const intents = getIntents()

        const cookieData = {
          email: leadData.email,
          moveDate: leadData.moveDate,
          name: leadData.name,
          phone: leadData.phone,
          mostRecentLeadTypeSubmitted: EMAIL,
        }

        if (isEnhanced) setEnhancedLeadsCookie(enhancedLeadsFormValues)

        const leadCategory = isRequestATourChecked
          ? LeadCategoryRate.FORM_WITH_TOUR_INTENT
          : LeadCategoryRate.FORM

        trackSuccess(
          listing,
          leadCategory,
          intents,
          dataTagSection,
          locationRelativeToSearchedCity
        )

        onValidSubmit?.(leadData)
        setLeadCookieData(cookieData)

        void setOneClickCookieData({
          ...cookieData,
          applicationOptIn: leadData.submittingApplication,
          tourRequest: leadData.requestATour,
        })

        userAddEmailedEvent({ listingId: listing.id, zutronId: zutronId || '' })
      })
      .tapError(() => {
        // Per product - alert is fine here since this will not typically happen
        alert('There was a problem submitting your info.')

        trackErrors(errors, dataTagSection)
      })
      .tap(() => {
        setIsSending(false)
      })
  }

  const handleInvalidSubmit: SubmitErrorHandler<LeadData> = (currentErrors) => {
    trackErrors(currentErrors, dataTagSection)
  }

  function handleNextClick(leadData: LeadData) {
    onStepChange?.(LeadFormSteps.elfPrompts)
    setFormStep(LeadFormSteps.elfPrompts)
    const cookieData = {
      email: leadData.email,
      moveDate: leadData.moveDate,
      name: leadData.name,
      phone: leadData.phone,
      mostRecentLeadTypeSubmitted: EMAIL,
    }

    setLeadCookieData(cookieData)
  }

  function SendCta() {
    return (
      <SubmitLeadCTAButton
        isSending={isSending}
        title="Send"
        dataTag="send_button"
      />
    )
  }

  function renderFormContent() {
    switch (formStep) {
      case LeadFormSteps.formFields:
        return (
          <form
            className={styles.leadForm}
            noValidate
            onSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}
            data-tag_section={dataTagSection}
            {...props}
          >
            <MessageInput
              data-tag_item="text"
              data-tid="lead-form-message"
              value={watch('message')}
              {...register('message')}
            />

            <NameInput
              data-tag_item="name"
              data-tid="lead-form-name"
              error={errors.name?.message ?? undefined}
              required
              value={watch('name', leadCookieData?.name ?? defaultValues.name)}
              {...register('name', {
                required: errorMessages.NAME_EMPTY,
                minLength: {
                  value: NAME_MIN_LENGTH,
                  message: errorMessages.NAME_MIN_LENGTH,
                },
              })}
            />

            <EmailInput
              data-tag_item="email"
              data-tid="lead-form-email"
              error={errors.email?.message ?? undefined}
              required
              value={watch('email')}
              {...register('email', {
                required: errorMessages.EMAIL_EMPTY,
                pattern: {
                  value: EMAIL_REGEX,
                  message: errorMessages.EMAIL_INVALID_LONG,
                },
              })}
            />

            <PhoneInput
              data-tag_item="phone_number"
              data-tid="lead-form-phone"
              error={errors.phone?.message ?? undefined}
              label={isPhoneRequired ? 'Phone' : 'Phone (optional)'}
              required={isPhoneRequired}
              value={watch('phone')}
              {...register('phone', {
                validate: (v) => validatePhone(v, isPhoneRequired),
              })}
            />

            {showDateInput && (
              <DateInput
                showInPortal={showInCalendarInPortal}
                showNativeDatePicker={showNativeDatePicker}
                data-tag_item="move_in_date"
                data-tid="lead-form-date"
                error={errors.moveDate?.message ?? undefined}
                required
                value={watch('moveDate', defaultValues.moveDate)}
                {...register('moveDate', {
                  validate: (v) => validateDate(v, isMobile),
                })}
              />
            )}

            {showOptionalCheckboxes && (
              <div className={styles.leadFormOptionalInputs}>
                <p className={styles.leadFormStrongText}>
                  I&rsquo;m interested in (optional)
                </p>
                {showTakingATourCheckbox && (
                  <Checkbox
                    id={`requestATour-${componentId}`}
                    label="Taking a Tour"
                    pill="Recommended"
                    data-tid="lead-form-request-a-tour"
                    {...register('requestATour')}
                    data-tag_item={
                      isRequestATourChecked
                        ? 'uncheck_request_tour'
                        : 'check_request_tour'
                    }
                  />
                )}
                {showApplicationCheckbox && (
                  <Checkbox
                    id={`submittingApplication-${componentId}`}
                    label="Online Application"
                    data-tid="lead-form-online-application"
                    {...register('submittingApplication')}
                    data-tag_item={
                      isSubmittingAppChecked
                        ? 'uncheck_application'
                        : 'check_application'
                    }
                  />
                )}
              </div>
            )}

            {isEnhanced ? (
              <Button
                type="button"
                fluid
                rounded
                variant="primary"
                data-tid="elf_email_next"
                data-tag_item="next_button"
                onClick={handleSubmit(handleNextClick)}
              >
                Next
              </Button>
            ) : (
              <SendCta />
            )}

            {!isCountryUS && withNewsletterSignUp && (
              <div className={styles.leadFormOptionalInputs}>
                <Checkbox
                  id={`optInNewsletter-${componentId}`}
                  type="checkbox"
                  data-tid="lead-form-opt-in-newsletter"
                  data-tag_item="checkbox"
                  label="Simplify my search with helpful tips and rental recommendations."
                  checked={watch('optInNewsletter')}
                  {...register('optInNewsletter')}
                />
              </div>
            )}

            <div
              data-tid="lead-form-terms_of_service"
              className={styles.leadFormFooter}
            >
              {`By submitting this form, you agree to ${
                shouldShowSmsConsent ? 'our' : "Rent's"
              } `}
              <br />
              <RentTermsLinks />.{' '}
              {shouldShowSmsConsent && <LeadFormDisclaimer />}
              {isDisplayPropertyWebsiteLink && (
                <PropertyWebsiteLinkFooter listing={listing} />
              )}
            </div>
          </form>
        )
      case LeadFormSteps.elfPrompts:
        return (
          <EnhancedLeadQuestionForm
            listing={listing}
            sendButton={<SendCta />}
            onFormSubmit={handleSubmit(handleValidSubmit, handleInvalidSubmit)}
          />
        )
      default:
        return null
    }
  }

  return <ClientOnly>{renderFormContent()}</ClientOnly>
}
