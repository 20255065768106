export const pmcIdList: Record<string, boolean> = {
  '100008692': true,
  '6010': true,
  '6121': true,
  '6058': true,
  '6061': true,
  '6009': true,
  '15563': true,
  '6008': true,
  '100014397': true,
  '100014391': true,
}

// Used for opting out of displaying the estimated utility costs on PDP
export const optOutEstimatedUtilitiesCost: Record<string, boolean> = {
  '6010': true, // UDR
}
