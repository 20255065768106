import { TourType } from '../../__generated__/api-types'
import type {
  TourTypeDetails,
  TourTypes,
  TourVendorsTypesAllowed,
} from './tour-wizard.types'

export const SMS_ENABLED_VENDORS = ['knock', 'rently', 'udr']

/**
 *  Order in which the tour types are presented to the user.
 * - The allowed types are listed first, followed by the disallowed types.
 */
export const TYPES_ORDER: TourType[] = [
  TourType.InPerson,
  TourType.VideoCall,
  TourType.SelfGuided,
]

/** Helper constant for tour vendors that allow all tour types */
export const TYPES_ALLOWED_ALL: TourTypes = {
  IN_PERSON: true,
  SELF_GUIDED: true,
  VIDEO_CALL: true,
}

/** Helper constant for tour vendors that allow only inperson tours */
export const TYPES_ALLOWED_INPERSON: TourTypes = {
  IN_PERSON: true,
  SELF_GUIDED: false,
  VIDEO_CALL: false,
}

export const TYPES_ALLOWED_SELFGUIDED: TourTypes = {
  IN_PERSON: false,
  SELF_GUIDED: true,
  VIDEO_CALL: false,
}

/** Mapping from tour vendors to the tour types that they support.
 * - If a tour vendor is not listed here then we assume that they allow only
 *   inperson.
 * - If there is an inconsistency or conflict between the requested tour types,
 *   and those that the vendor supports, such that no tour types would be
 *   allowed, we fall back to inperson tour as the single option. */
export const TOUR_VENDORS_TYPES_ALLOWED: TourVendorsTypesAllowed = {
  entrata: TYPES_ALLOWED_INPERSON,
  funnel: TYPES_ALLOWED_ALL,
  hyly: TYPES_ALLOWED_ALL,
  knock: {
    IN_PERSON: true,
    SELF_GUIDED: false, // knock does not support selfguided
    VIDEO_CALL: true,
  },
  maxleases: TYPES_ALLOWED_INPERSON,
  rently: TYPES_ALLOWED_SELFGUIDED,
  test: TYPES_ALLOWED_ALL,
  udr: TYPES_ALLOWED_ALL,
}

export const DEFAULT_TOUR_TYPE_DETAILS: TourTypeDetails = {
  IN_PERSON: {
    description: 'Tour with a leasing agent and get answers to your questions.',
    title: 'In-Person',
    dataTagItem: 'in_person_tour',
  },
  SELF_GUIDED: {
    description: 'Walk around at your own pace without any pressure.',
    title: 'Self-Guided',
    dataTagItem: 'self_guided_tour',
  },
  VIDEO_CALL: {
    description: 'Tour safely from home on apps like FaceTime and Zoom.',
    title: 'Live Video',
    dataTagItem: 'live_video_tour',
  },
}

export const SCHEDULE_TOUR_SUBHEADLINE_TEXT =
  'Take a tour {msg}. Choose the day and time that works for you.'

// Tour provider Knock requires a custom legal message with links to their
// "privacy policy" and "terms and conditions" pages.
export const URL_RENTLY_SMS_TERMS = 'https://use.rently.com/sms-terms/'
export const URL_TERMS_KNOCK = 'https://www.knockcrm.com/terms'
export const URL_PRIVACY_KNOCK = 'https://www.knockcrm.com/privacy'

export const TOUR_TIMES_STEP = 0
export const CONTACT_STEP = 1

export const MAX_DAYS_TO_SHOW = 30
