import { format as formatTZ } from 'date-fns-tz'
import { format } from 'date-fns'

import type { DayTime } from './day-time-atom'
import {
  DAY_TIME_PICKER_DAYS_TO_ADD,
  DAY_TIME_PICKER_HOURS,
  DAY_TIME_PICKER_TIMES,
} from './day-time-picker'
import type { LeadForm_ListingFragment } from '../../lead-form/__generated__/lead-form.gql'
import { getTourTimeDates } from './get-tour-time-dates'

export const formatDayPickerTimeToISO = (
  dayTime: DayTime,
  timeZone: string,
  officeHours: LeadForm_ListingFragment['officeHours']
) => {
  let ISODate = ''

  const dayTimePickerDates = getTourTimeDates(
    new Date(),
    DAY_TIME_PICKER_DAYS_TO_ADD,
    officeHours
  )

  if (Boolean(Object.keys(dayTime).length)) {
    const inputDate = new Date(
      format(dayTimePickerDates[dayTime.day].date, 'dd MMM, y')
    )
    // Adjust date forward one year if user selects a January tour date during December
    const isDateInNextYear =
      new Date().getMonth() !== 0 && inputDate.getMonth() === 0
    if (isDateInNextYear) {
      inputDate.setFullYear(new Date().getFullYear() + 1)
    } else {
      inputDate.setFullYear(new Date().getFullYear())
    }

    ISODate = formatDateWithTimezone(
      inputDate,
      DAY_TIME_PICKER_HOURS[DAY_TIME_PICKER_TIMES[dayTime.time]],
      timeZone
    )
  }

  return ISODate
}

export const formatDateWithTimezone = (
  givenTourDate: Date,
  tourHour: number,
  timeZone: string | null
) => {
  if (!givenTourDate) {
    return ''
  }

  const tourDate = new Date(givenTourDate.getTime())
  tourDate.setHours(tourHour)
  tourDate.setMinutes(0)
  tourDate.setSeconds(0)

  let formattedDate = format(tourDate, "yyyy-MM-dd'T'HH:mm:ss")

  if (timeZone && Boolean(timeZone)) {
    // We are checking both to prevent ts error
    formattedDate = formatTZ(tourDate, "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZone,
    })
  }

  return formattedDate
}
