import { useAtomValue } from 'jotai'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { UserEventLeadType, UserEventType } from '../../__generated__/api-types'
import { leadCookieAtom } from '../lead-form/lead-cookie.store'
import { useRequestData } from '../request-data/pages-router/use-request-data'
import { useUser } from '../user/user.store'
import { submitUserEvent } from './submit-user-event'
import { userAddCalledEvent } from './user-add-called-event'

export function usePhoneLeadEvent() {
  const { query } = useRouter()
  const user = useUser()
  const { useGDPR, ip: ipSrc } = useRequestData()
  const leadCookieData = useAtomValue(leadCookieAtom)

  return useCallback(
    (event: {
      item: string
      action: string
      listing_id: string
      revenue?: number | null
    }) => {
      if (event.listing_id && user?.zutronId) {
        userAddCalledEvent({
          listingId: event.listing_id,
          zutronId: user.zutronId,
        })
      }

      if (ipSrc && !useGDPR) {
        submitUserEvent({
          input: {
            email: user?.email || leadCookieData.email || undefined,
            eventType: UserEventType.Lead,
            ipSrc,
            leadType: UserEventLeadType.PhoneLead,
            listingId: event.listing_id,
            listingRevenue: Number(event.revenue),
            testEventId: (query.testEventId as string) || undefined,
            url: window.location.href,
            userAgent: window.navigator.userAgent,
          },
        })
      }
    },
    [leadCookieData.email, ipSrc, query.testEventId, useGDPR, user]
  )
}
