/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { DetailThirdPartyReviews_ListingFragmentDoc, ThirdPartyReviewsFieldsFragmentDoc, AggregatedRatingsFieldsFragmentDoc } from '../../__generated__/detail-page.gql';
export type RatingsFieldsFragment = { count: number, percent?: number | null, rating: number };

export type CategoryRatingsFieldsFragment = { name?: string | null, averageRating: number, oneStarCount: number, twoStarCount: number, threeStarCount: number, fourStarCount: number, fiveStarCount: number };

export type RatingsReviews_ListingFragment = { isUnpaid: boolean, tplsource?: string | null, ratingCount?: number | null, ratingPercent?: number | null, id: string, urlPathname?: string | null, ratings?: Array<{ count: number, percent?: number | null, rating: number }> | null, categoryRatings: Array<{ name?: string | null, averageRating: number, oneStarCount: number, twoStarCount: number, threeStarCount: number, fourStarCount: number, fiveStarCount: number }>, thirdPartyReviews?: { googleReviews?: { total: number, reviews: Array<{ review: string, rating: number, author?: string | null, date: string, helpfulCount: number, id: string, listingId: string, isCurrentManagement: boolean, comments: Array<{ comment: string, author: string, date: string }> }> } | null, facebookReviews?: { total: number, reviews: Array<{ review: string, rating: number, author?: string | null, date: string, helpfulCount: number, id: string, listingId: string, isCurrentManagement: boolean, comments: Array<{ comment: string, author: string, date: string }> }> } | null, aggregates?: Array<{ total: number, aggregatedRating: number, source: string, ratings?: Array<{ count: number, percent?: number | null, rating: number }> | null }> | null } | null };

export const RatingsFieldsFragmentDoc = `
    fragment RatingsFields on Rating {
  count
  percent
  rating
}
    `;
export const CategoryRatingsFieldsFragmentDoc = `
    fragment CategoryRatingsFields on CategoryRating {
  name
  averageRating
  oneStarCount
  twoStarCount
  threeStarCount
  fourStarCount
  fiveStarCount
}
    `;
export const RatingsReviews_ListingFragmentDoc = `
    fragment RatingsReviews_ListingFragment on Listing {
  isUnpaid
  tplsource
  ratingCount
  ratingPercent
  ratings {
    ...RatingsFields
  }
  id
  categoryRatings {
    ...CategoryRatingsFields
  }
  urlPathname
  ...DetailThirdPartyReviews_ListingFragment
}
    `;



