import type { ContactFormAtom, ContactInfoValues } from '../tour-wizard.store'

export function getEmptyFieldCount(contactForm: ContactFormAtom): number {
  const contactFormValues = contactForm.values
  const contactFormErrors = contactForm.errors
  const fieldValueKeys = Object.keys(contactFormValues)
  const totalFieldCount = fieldValueKeys.length
  const populatedFieldCount = fieldValueKeys.filter(
    (key) =>
      !!contactFormValues[key as keyof ContactInfoValues] &&
      !contactFormErrors[key as keyof ContactInfoValues]
  ).length

  return totalFieldCount - populatedFieldCount
}
