import { graphqlRequesterOptions } from '../../../../config/graphql-requester-options'
import type { AvailableDatesFields } from '../../tour-wizard.types'
import type { TourTimesQueryVariables } from './__generated__/tour-times.gql'
import { createTourTimesFetcher } from './__generated__/tour-times.gql'

// Sometimes the API can come back with times in the wrong order.
function sortTourTimes(availableDates?: AvailableDatesFields[]) {
  return availableDates?.map((availableDate) => ({
    ...availableDate,
    times: availableDate.times?.sort(),
  }))
}

const fetcher = createTourTimesFetcher(graphqlRequesterOptions)

export const fetchTourTimes = (
  variables: TourTimesQueryVariables,
  zid: string
) =>
  fetcher(variables, { zid }).mapOk((result) => {
    return sortTourTimes(result.listing?.tourTimes ?? [])
  })
