import clsx from 'clsx'

import { FinalStepButton } from '../final-step-button/final-step-button'
import type { ThankYou_ListingFragment } from '../../__generated__/thank-you.gql'

import styles from './thank-you-redesign-final-screen.module.css'

interface ThankYouRedesignFinalScreenProps {
  onClose?: () => void
  children?: React.ReactNode
  initiatedLeadListing: ThankYou_ListingFragment
}

export const finalScreenMessage =
  "You're one step closer to finding your perfect place"

export function ThankYouRedesignFinalScreen({
  initiatedLeadListing,
  children,
  onClose,
}: ThankYouRedesignFinalScreenProps) {
  const hasChildren = !!children

  return (
    <div
      className={clsx(
        styles.wrapper,
        hasChildren && styles.wrapperWithConfirmation
      )}
    >
      {/* Confirmation message passed in as a child */}
      {children}
      <p
        className={clsx(
          styles.finalText,
          hasChildren && styles.finalTextWithConfirmation
        )}
      >
        {finalScreenMessage}
      </p>
      <p className={styles.moreProperties}>
        Take a look at some more properties in this area!
      </p>
      <FinalStepButton
        initiatedLeadListing={initiatedLeadListing}
        onClose={onClose}
      />
    </div>
  )
}
