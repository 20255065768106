import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import React, { useEffect, useMemo } from 'react'

import { TourWizardStepContainer } from '../wizard/tour-wizard-step-container'
import { TourWizardError } from '../wizard/tour-wizard-error'
import { TourWizardLoading } from '../wizard/tour-wizard-loading'
import {
  setNextSetOfDaysAtom,
  setPrevSetOfDaysAtom,
  setTimeSelectionAtom,
  tourDatesAtom,
  tourTypeAtom,
  setTourTypeAtom,
} from '../tour-wizard.store'
import { useAsyncTourTimes } from './hooks/use-async-tour-times'
import { useResponsiveDaysToShow } from './hooks/use-responsive-days-to-show'
import { TourTimesEmpty } from './tour-times-empty'
import { TourTimesList } from './tour-times-list'
import { TourTimesNav } from './tour-times-nav'
import { getTimeSlotsTemplate } from './utils/get-time-slots-template'
import { getShouldShowNext } from './utils/get-should-show-next'
import { getShouldShowPrev } from './utils/get-should-show-prev'

import type { FC } from 'react'
import { TourTypesList } from '../tour-types/tour-types-list'
import { TourWizardStepHeading } from '../wizard/tour-wizard-step-heading'
import { useTourWizardConfig } from '../wizard/hooks/use-tour-wizard-config'
import { capitalizeOnlyFirstLetter } from '../wizard/utils/captialize-only-first-letter'
import { useZutronId } from '../../user/user.store'

export const TourTimesStep: FC = () => {
  const zutronId = useZutronId()
  const { orderedTourTypes, tourTypeDetails, theme } = useTourWizardConfig()
  const setTourType = useSetAtom(setTourTypeAtom)

  const [enabledTypes] = orderedTourTypes || []

  const { times, fetching, error } = useAsyncTourTimes()
  const [timeSelection, setTimeSelection] = useAtom(setTimeSelectionAtom)
  const tourDates = useAtomValue(tourDatesAtom)
  const setNextSetOfDays = useSetAtom(setNextSetOfDaysAtom)
  const setPrevSetOfDays = useSetAtom(setPrevSetOfDaysAtom)
  const daysToShow = useResponsiveDaysToShow()
  const timeSlotsTemplate = useMemo(() => getTimeSlotsTemplate(times), [times])

  const selectedTypeName = useAtomValue(tourTypeAtom)
  const [enabledTourTypes] = orderedTourTypes || []
  const selectedTourType =
    selectedTypeName && tourTypeDetails?.[selectedTypeName]

  const shouldShowPrev = useMemo(
    () => getShouldShowPrev(tourDates),
    [tourDates]
  )
  const shouldShowNext = useMemo(
    () => getShouldShowNext(tourDates, daysToShow),
    [daysToShow, tourDates]
  )

  const noDates = times?.length === 0 || timeSlotsTemplate.length === 0

  function handleViewMore() {
    setNextSetOfDays(daysToShow)
  }

  function handleBack() {
    setPrevSetOfDays(daysToShow)
  }

  function handleNext() {
    setNextSetOfDays(daysToShow)
  }

  function handleTimeSelection(selection: string) {
    setTimeSelection(selection)
  }

  useEffect(() => {
    setTourType(enabledTypes[0])
  }, [enabledTypes, setTourType])

  const oneTourType = enabledTourTypes.length === 1

  return (
    <TourWizardStepContainer
      className="overflow-hidden"
      data-tid="tour-times-step"
    >
      <div className={theme?.tourTimesStep?.container}>
        <TourWizardStepHeading
          title="Select a Time and Date"
          subtitle={
            oneTourType ? (
              <>
                <span>
                  {selectedTourType &&
                    capitalizeOnlyFirstLetter(selectedTourType?.title)}{' '}
                  tour
                </span>
              </>
            ) : (
              ''
            )
          }
        />
        <TourTypesList />
      </div>
      <div
        className={theme?.tourTimesStep?.navContainer}
        role={oneTourType ? undefined : 'tabpanel'}
        tabIndex={oneTourType ? undefined : 0}
        aria-label={selectedTourType?.title}
      >
        <TourTimesNav
          showBack={shouldShowPrev}
          showNext={shouldShowNext}
          onBack={handleBack}
          onNext={handleNext}
          availableDates={times}
        />
        <div
          className={theme?.tourTimesStep?.scrollContainer}
          style={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            overflowY: 'overlay',
          }}
        >
          {fetching && (
            <TourWizardLoading message="Checking for available times" />
          )}

          {!zutronId && (
            <TourWizardError message="An unexpected error occurred." />
          )}

          {error && zutronId && !fetching && (
            <TourWizardError message="A problem occurred while checking availability." />
          )}

          {noDates && !fetching ? (
            <TourTimesEmpty onViewMoreDays={handleViewMore} />
          ) : (
            <TourTimesList
              availableDates={times}
              timeSlotsTemplate={timeSlotsTemplate}
              onTimeSelect={handleTimeSelection}
              timeSelection={timeSelection}
            />
          )}
        </div>
      </div>
    </TourWizardStepContainer>
  )
}
