'use client'

import FailureImage from '@brand/assets/features/schedule-tour/tourFailure.png'
import ThankYouImage from '@brand/assets/features/schedule-tour/tourSuccess.png'
import {
  brandAppId,
  brandName,
  getBrandWebsite,
} from '@brand/config/brand-config'
import { GraphqlRequester } from '@rentpath/graphql-requester'
import { parseUnixTimeStamp, utcDateToDateString } from '@rentpath/web-utils'
import Cookies from 'js-cookie'
import type { LeadSmsConsentEntity } from '../../__generated__/api-types'
import {
  LeadChannel,
  LeadDevice,
  UserEventLeadType,
} from '../../__generated__/api-types'
import { graphqlRequesterOptions } from '../../config/graphql-requester-options'
import { submitLead } from '../lead-form/submit-lead'
import { createFacebookDataForLead } from '../lead-form/utils/create-facebook-data-for-lead'
import {
  trackSuccess,
  LeadCategoryRate,
} from '../lead-form/utils/event-tracking'
import { getLeadRefinements } from '../lead-form/utils/get-lead-refinements'
import { useListingPhone } from '../listing-phone/use-listing-phone'
import type { ModalProps } from '../modals/modal'
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
} from '@brand/slots/footer/get-footer-links'
import { useRequestData } from '../request-data/pages-router/use-request-data'
import { userAddEmailedEvent } from '../user-event-tracking/user-add-emailed-event'
import { useZutronId } from '../user/user.store'
import type { TourWizard_ListingFragment } from './__generated__/tour-wizard.gql'
import styles from './tour-wizard-modal.module.css'
import { tourWizardTheme } from './tour-wizard.theme'
import type {
  TourTypes,
  TourWizardListingConfig,
  TourWizardProps,
} from './tour-wizard.types'
import { TourWizard } from './wizard/tour-wizard'
import { getTourType } from '../lead-form/utils/get-tour-type'
import type { getLocationRelativeToSearchedCity } from '../search/utils/get-location-relative-to-searched-city'
import { DialogModal } from '../dialog-modal/dialog-modal'
import {
  type ComponentProps,
  startTransition,
  useCallback,
  useRef,
} from 'react'
import { yieldOrContinue } from 'main-thread-scheduling'
import {
  resetTourReuqestErrorAtom,
  resetCurrentStepAtom,
} from './tour-wizard.store'
import { useAtom, useSetAtom } from 'jotai'
import { tourWizardModalStateAtom } from '../cta-modals/cta-modals.atom'

const tourWizardClient = new GraphqlRequester(graphqlRequesterOptions)

export type TourWizardListing = ComponentProps<
  typeof TourWizardModalBody
>['listing']

type TourWizardModalProps = {
  'data-tid'?: string
  id?: string
}

type TourWizardModalBodyProps = {
  listing: TourWizard_ListingFragment
  currentRefinementSlugs?: string[]
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
} & Pick<ModalProps, 'onClose'>

export const TOUR_WIZARD_MODAL_ID = 'tour-wizard-modal'

function TourWizardModalBody(props: TourWizardModalBodyProps) {
  const { isMobile, ipLocation, ip, useGDPR, sessionId, visitId } =
    useRequestData()
  const zutronId = useZutronId()
  const { phoneText } = useListingPhone(props.listing)

  // The user data should be loaded async in the client by the time user opens this modal.

  // Default to listing's tour provider
  // If cookie is set to "mock", use "test" as a provider
  // Otherwise, if cookie is set and not "mock", assume it's a provider
  let tourProvider = props.listing.tourProviderDetails?.providerKey

  // this component is client only.
  const tourProviderCookie = Cookies.get('tour')
  if (tourProviderCookie === 'mock') {
    tourProvider = 'test'
  } else if (typeof tourProviderCookie === 'string') {
    tourProvider = tourProviderCookie
  }

  const handleSuccessfulTourSubmission: TourWizardProps['onTourRequest'] = (
    contactInfo,
    tourType,
    tourTime,
    config,
    tourId
  ) => {
    const timeForMessage = parseUnixTimeStamp(tourTime)
    const dateForMessage = utcDateToDateString(new Date(tourTime))
    const message = `Tour scheduled for ${timeForMessage} ${dateForMessage}`
    const headers = zutronId ? { zid: zutronId } : undefined

    const fbData = createFacebookDataForLead({
      email: contactInfo.email,
      leadType: UserEventLeadType.ScheduleTourLead,
      listingId: props.listing.id,
      ip,
      revenue: props.listing.revenue,
      useGDPR,
      sessionId,
      visitId,
    })

    const isSmsOptIn = Boolean(props.listing.tourProviderDetails?.smsOptIn)

    submitLead(
      {
        lead: {
          applicationOptIn: false,
          context: 'modal',
          device: LeadDevice.Desktop,
          campaignId: Cookies.get('campaign_id'),
          evTransId: `${visitId}.${sessionId}_${props.listing.id}`,
          email: contactInfo.email,
          phone: contactInfo.phone,
          facebookData: fbData,
          firstName: contactInfo.firstName,
          lastName: contactInfo.lastName,
          leadTypeId: LeadChannel.Tour,
          listingId: props.listing.id,
          message,
          moveDate: contactInfo.moveInDate,
          optInNewsletter: Boolean(ipLocation?.isUSA),
          smsOptIn: isSmsOptIn,
          smsConsentEntities:
            isSmsOptIn && props.listing.tourProviderDetails?.smsContentEntities
              ? (props.listing.tourProviderDetails?.smsContentEntities.split(
                  ','
                ) as LeadSmsConsentEntity[])
              : null,
          tourDateTime: tourTime,
          website: getBrandWebsite(isMobile),
          refinements: getLeadRefinements(props.currentRefinementSlugs),
          tourPropertyId: config.tourPropertyId,
          tourProvider: config.tourProvider,
          sessionId: `${visitId}.${sessionId}`,
          taggingHitId: window.hit_id,
          tvisit: `${visitId}.${sessionId}`,
          tourId,
          tourType: getTourType(tourType),
        },
      },
      headers
    ).tapOk(() => {
      userAddEmailedEvent({
        listingId: props.listing.id,
        zutronId: zutronId ?? '',
      })
    })

    // Fire lead regardless of the tour success/failure
    trackSuccess(
      props.listing,
      LeadCategoryRate.SCHEDULED_TOUR,
      'request_tour',
      'schedule_tour_form',
      props.locationRelativeToSearchedCity
    )
  }

  const tourTypes: TourTypes = {
    IN_PERSON: Boolean(props.listing.tourProviderDetails?.types?.inPerson),
    SELF_GUIDED: Boolean(props.listing.tourProviderDetails?.types?.selfGuided),
    VIDEO_CALL: Boolean(props.listing.tourProviderDetails?.types?.videoCall),
  }

  return (
    <TourWizard
      appId={brandAppId}
      brandName={brandName}
      failureImage={FailureImage.src}
      graphqlFetchClient={tourWizardClient}
      isMobile={isMobile}
      onClose={props.onClose}
      onTourRequest={handleSuccessfulTourSubmission}
      propertyAddress={props.listing.addressFull as string}
      propertyName={props.listing.name as string}
      propertyPhone={phoneText as string}
      thankYouImage={ThankYouImage.src}
      tourProvider={tourProvider as string}
      theme={tourWizardTheme}
      tourTypeDetails={{
        IN_PERSON: {
          description:
            'Tour with a leasing agent and get answers to your questions.',
          title: 'In-Person',
          dataTagItem: 'in_person_tour',
        },
        SELF_GUIDED: {
          description: 'Walk around at your own pace without any pressure.',
          title: 'Self-Guided',
          dataTagItem: 'self_guided_tour',
        },
        VIDEO_CALL: {
          description: 'Tour safely from home on apps like FaceTime and Zoom.',
          title: 'Live Video',
          dataTagItem: 'live_video_tour',
        },
      }}
      tourPropertyId={props.listing.tourProviderDetails?.propertyId as string}
      tourProviderDetails={{
        types: {
          inPerson: tourTypes.IN_PERSON,
          selfGuided: tourTypes.SELF_GUIDED,
          videoCall: tourTypes.VIDEO_CALL,
        },
        enabled: Boolean(props.listing.tourProviderDetails?.enabled),
        disclaimer: props.listing.tourProviderDetails?.disclaimer ?? '',
        name: props.listing.tourProviderDetails?.name ?? '',
        smsConsentEntities:
          props.listing.tourProviderDetails?.smsContentEntities ?? '',
        smsOptIn: Boolean(props.listing.tourProviderDetails?.smsOptIn),
        updatedAt: props.listing.tourProviderDetails?.updatedAt ?? '',
      }}
      tourTypes={tourTypes}
      // Uat parameter is still required by TourApi. So we are setting it to false,
      // even though its no longer used.
      uat={false}
      urlPrivacy={PRIVACY_POLICY_URL}
      urlTerms={TERMS_OF_SERVICE_URL}
      userId={zutronId ?? ''}
      listing={props.listing as TourWizardListingConfig['listing']}
    />
  )
}

export function TourWizardModal(props: TourWizardModalProps) {
  // props.listing can be null to indicate the modal is closed. We render a
  // Modal anyway so that CSS transitions will continue to work.
  const [
    { listing, currentRefinementSlugs, locationRelativeToSearchedCity },
    setTourWizardModalAtom,
  ] = useAtom(tourWizardModalStateAtom)
  const tourWizardModalRef = useRef<HTMLDialogElement>(null)
  const resetCurrentStep = useSetAtom(resetCurrentStepAtom)
  const resetTourRequestError = useSetAtom(resetTourReuqestErrorAtom)

  const onClose = useCallback(
    async function onClose() {
      await yieldOrContinue('smooth')
      tourWizardModalRef?.current?.close()
      await yieldOrContinue('smooth')
      setTourWizardModalAtom({ listing: null })
    },
    [setTourWizardModalAtom]
  )

  const onAfterClose = useCallback(
    function onAfterClose() {
      startTransition(() => {
        resetCurrentStep()
        resetTourRequestError()
        setTourWizardModalAtom({ listing: null })
      })
    },
    [setTourWizardModalAtom, resetCurrentStep, resetTourRequestError]
  )

  return (
    <DialogModal
      className={styles.tourWizardModal}
      id={TOUR_WIZARD_MODAL_ID}
      data-tid={props['data-tid'] || 'tour-modal'}
      ref={tourWizardModalRef}
      onAfterClose={onAfterClose}
    >
      {listing ? (
        <TourWizardModalBody
          listing={listing}
          currentRefinementSlugs={currentRefinementSlugs}
          onClose={onClose}
          locationRelativeToSearchedCity={locationRelativeToSearchedCity}
        />
      ) : null}
    </DialogModal>
  )
}
