import { AsyncData } from '@swan-io/boxed'
import { useEffect, useState } from 'react'
import type { SimilarOrViewed_ListingFragment } from '../../listing-card-preview-group/__generated__/similar-properties.gql'
import { createSimilarPropertiesFetcher } from '../../listing-card-preview-group/__generated__/similar-properties.gql'
import { usePhotoOrderExperiment } from '../../ml-photo-order/use-photo-order-experiement'
import { graphqlRequesterOptions } from '../../../config/graphql-requester-options'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { PropertyType } from '../../../__generated__/api-types'
import { fetchDetailPageSearchResults } from '../../detail/detailQueryFetchers'
import { getPropertyTypeFilter } from '../utils/property-types'
import type { SimilarListingsOffmarketSearch_ListingFragment } from '../../detail/__generated__/detail-page.gql'

const similarPropertiesFetcher = createSimilarPropertiesFetcher(
  graphqlRequesterOptions
)

export const useFetchSimilarProperties = ({
  shouldFetch,
  listingId,
  offMarket,
  location,
  propertyType,
}: {
  shouldFetch: boolean
  listingId: string
  offMarket?: boolean
  location?: SimilarListingsOffmarketSearch_ListingFragment['location']
  propertyType?: SimilarListingsOffmarketSearch_ListingFragment['propertyType']
}) => {
  const [similarProperties, setSimilarProperties] = useState(() =>
    AsyncData.NotAsked<SimilarOrViewed_ListingFragment[]>()
  )

  const { withOptimizedPhotos, optimizedPhotosSessionId } =
    usePhotoOrderExperiment()

  const recommendationsVersion = useFeatureVariable<number>(
    ['recommendations', 'version'],
    0
  )

  useEffect(() => {
    if (!similarProperties.isNotAsked()) return

    if (shouldFetch && !offMarket) {
      setSimilarProperties(AsyncData.Loading())

      const similarPropertiesRequestFuture = similarPropertiesFetcher({
        listingId: listingId,
        withOptimizedPhotos,
        optimizedPhotosSessionId,
        recommendationsVersion,
      }).tapOk((result) => {
        setSimilarProperties(AsyncData.Done(result.listing?.similar || []))
      })

      return () => {
        if (similarProperties.isLoading()) {
          similarPropertiesRequestFuture?.cancel()
        }
      }
    } else if (shouldFetch && offMarket) {
      setSimilarProperties(AsyncData.Loading())
      fetchDetailPageSearchResults({
        locationFilter: {
          city: location?.city,
          state: location?.state,
        },
        withOptimizedPhotos,
        optimizedPhotosSessionId,
        resultsPerPage: 5,
        pageNumber: 1,
        filters: {
          propertyTypes: [
            getPropertyTypeFilter(propertyType || PropertyType.Apartments),
          ],
        },
      }).tapOk((result) => {
        setSimilarProperties(
          AsyncData.Done(result.location.listingSearch?.listings || [])
        )
      })
    }
  }, [
    shouldFetch,
    listingId,
    offMarket,
    propertyType,
    location,
    optimizedPhotosSessionId,
    similarProperties,
    withOptimizedPhotos,
    recommendationsVersion,
  ])

  return similarProperties
}
