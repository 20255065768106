/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type DetailBreadcrumbs_LocationFragment = { city?: string | null, state?: string | null, zip?: string | null, county?: string | null };

export type DetailBreadcrumbs_ListingFragment = { urlPathname?: string | null, propertyType?: Types.PropertyType | null, address?: string | null, name?: string | null, location?: { city?: string | null, state?: string | null, zip?: string | null, county?: string | null } | null };

export const DetailBreadcrumbs_LocationFragmentDoc = `
    fragment DetailBreadcrumbs_LocationFragment on Location {
  city
  state
  zip
  county
}
    `;
export const DetailBreadcrumbs_ListingFragmentDoc = `
    fragment DetailBreadcrumbs_ListingFragment on Listing {
  urlPathname
  propertyType
  address
  name
  location {
    ...DetailBreadcrumbs_LocationFragment
  }
}
    `;



