import { useSetAtom } from 'jotai'
import { useCallback } from 'react'

import {
  resetCachedTimesAtom,
  resetContactFormAtom,
  resetCurrentStepAtom,
  resetTimeSelectionAtom,
  resetTourDatesAtom,
  resetTourStatusAtom,
  resetTourTimesAtom,
  resetTourTypeAtom,
} from '../../tour-wizard.store'

export function useResetAtoms() {
  const resetTours = useSetAtom(resetTourStatusAtom)
  const resetTourType = useSetAtom(resetTourTypeAtom)
  const resetCurrentStep = useSetAtom(resetCurrentStepAtom)
  const resetTourDates = useSetAtom(resetTourDatesAtom)
  const resetTimeSelection = useSetAtom(resetTimeSelectionAtom)
  const resetContactForm = useSetAtom(resetContactFormAtom)
  const resetCachedTimes = useSetAtom(resetCachedTimesAtom)
  const resetTourTimes = useSetAtom(resetTourTimesAtom)

  return useCallback(() => {
    resetTours()
    resetTourType()
    resetCurrentStep()
    resetTourDates()
    resetTimeSelection()
    resetContactForm()
    resetCachedTimes()
    resetTourTimes()
  }, [
    resetTours,
    resetTourType,
    resetCurrentStep,
    resetTourDates,
    resetTimeSelection,
    resetContactForm,
    resetCachedTimes,
    resetTourTimes,
  ])
}
