/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type UserAddCalledListingMutationVariables = Types.Exact<{
  listingId: Types.Scalars['ID'];
  zutronId: Types.Scalars['ID'];
}>;


export type UserAddCalledListingMutation = { userAddCalledListing: { message?: string | null, meta?: Record<string, unknown> | null, success: boolean } };


export const UserAddCalledListingDocument = `
    mutation UserAddCalledListing($listingId: ID!, $zutronId: ID!) {
  userAddCalledListing(listingId: $listingId, zutronId: $zutronId) {
    message
    meta
    success
  }
}
    `;

    export function createUserAddCalledListingFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchUserAddCalledListing(variables: UserAddCalledListingMutationVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<UserAddCalledListingMutation, UserAddCalledListingMutationVariables>(UserAddCalledListingDocument, variables, headers);
      }
    }

