import { TaggingPageType } from '../../tagging/tagging.const'
import { getPageType, srpViewToPageMap } from './get-page-type'
import { useRouter } from 'next/router'

const FAVORITE_PAGE = 'saved'
const SRP_PAGE = 'srp'

const mappedPageType = {
  [srpViewToPageMap.map]: SRP_PAGE,
  [TaggingPageType.SAVED_PROPERTIES]: FAVORITE_PAGE,
}

export function usePageType(isMobile: boolean) {
  const router = useRouter()
  const pageType = getPageType(isMobile, router.pathname, router.query)

  return mappedPageType[pageType] || pageType
}
