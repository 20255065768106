import type { Listing } from '../../__generated__/api-types'
import { ListingAvailabilityStatus } from '../../__generated__/api-types'
import { getIsScheduleTourEnabled } from '../tour-wizard/get-is-schedule-tour-enabled'

export function useHideRequestTour(
  availabilityStatus: Listing['availabilityStatus'],
  tourProviderDetails: Listing['tourProviderDetails'],
  requestTourOptOut: Listing['requestTourOptOut']
) {
  const hasScheduleTours = getIsScheduleTourEnabled(tourProviderDetails)

  if (hasScheduleTours) return false

  if (requestTourOptOut) return true

  return availabilityStatus === ListingAvailabilityStatus.Limited
}
