import { graphqlRequesterOptions } from '../../config/graphql-requester-options'
import { createDetailPageQueryOffMarketFetcher } from './__generated__/detail-page-offmarket.gql'
import { createDetailPageQueryFetcher } from './__generated__/detail-page.gql'
import { createDetailPageSearchResultsFetcher } from './__generated__/detail-page-search-results.gql'

export const fetchDetailPageQuery = createDetailPageQueryFetcher(
  graphqlRequesterOptions
)

export const fetchDetailOffMarketPageQuery =
  createDetailPageQueryOffMarketFetcher(graphqlRequesterOptions)

export const fetchDetailPageSearchResults =
  createDetailPageSearchResultsFetcher(graphqlRequesterOptions)
