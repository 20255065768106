import { ReactComponent as ChevronLeftIcon } from '@brand/icons/chevron-left.svg'
import styles from './enhanced-lead-form-header.module.css'
import { LeadFormSteps } from './const'
import { Button } from '../../components/button/button'

export interface EnhancedLeadFormHeaderProps {
  onClose?: () => void
  onStepChange?: (formStep: LeadFormSteps) => void
  dataTagSection?: string
}

export function EnhancedLeadFormHeader({
  onClose,
  onStepChange,
  dataTagSection,
}: EnhancedLeadFormHeaderProps) {
  function handleClick() {
    onStepChange?.(LeadFormSteps.formFields)
  }

  return (
    <div className={styles.container}>
      <div className={styles.backButton}>
        <Button
          variant="anchor"
          onClick={handleClick}
          data-tag_section={dataTagSection}
          data-tag_item="back_button"
        >
          <ChevronLeftIcon aria-hidden />
          Back
        </Button>
      </div>
      {onClose && (
        <Button variant="anchor" onClick={onClose}>
          Cancel
        </Button>
      )}
    </div>
  )
}
