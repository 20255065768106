import { defaultLeadCookieData } from '../lead-form/lead-cookie.store'
import type { LeadCookieData } from '../lead-form/lead-cookie-parser'
import type { IPLocation } from './lib/ip-location/ip-location.types'
import type { PartiesOff } from './parties.const'
import { Host } from './host'
import type { MobileDevice } from './lib/get-mobile-device-data'

export const defaultRequestData: RequestData = {
  appBannerDismissedCookie: false,
  fullstoryForceFullTraffic: false,
  host: '',
  hostEnv: Host.local,
  initialSearchFiltersCookie: null,
  ip: null,
  ipLocation: {
    city: '',
    country: '',
    isEU: true,
    isUSA: false,
    state: '',
  },
  isBot: false,
  isIOSMobile: false,
  isMobile: false,
  isWebp: true,
  leadCookie: defaultLeadCookieData,
  mobileDevice: 'unknown',
  off: {
    bucketing: false,
    fullstory: false,
    onetap: false,
    gpt: false,
    gtm: false,
    rum: false,
    chatbot: false,
  },
  optimizedPhotosSessionId: '',
  propertyTypeName: 'apartments',
  rentTrendsLink: null,
  sem: {
    marketingCampaignId: null,
    shouldDisplaySemPhone: false,
    shouldUseSemFilter: false,
  },
  sessionId: null,
  useGDPR: false,
  userAgent: null,
  userPreferences: null,
  visitId: null,
}

export type RequestData = {
  appBannerDismissedCookie: boolean
  fullstoryForceFullTraffic: boolean
  host: string
  hostEnv: Host
  initialSearchFiltersCookie: string | null
  ip: {
    iv: string
    str: string
  } | null
  ipLocation: IPLocation | null
  isBot: boolean
  isMobile: boolean
  isIOSMobile: boolean
  isWebp: boolean
  leadCookie: LeadCookieData
  mobileDevice: MobileDevice
  off: PartiesOff
  optimizedPhotosSessionId: string
  propertyTypeName: string
  // this really shouldn't be used in request
  // data, but we need to leave it until we
  // fix the issue
  rentTrendsLink: string | null
  sem: {
    marketingCampaignId: string | null
    shouldDisplaySemPhone: boolean
    shouldUseSemFilter: boolean
  }
  sessionId: string | null
  useGDPR: boolean
  userAgent: string | null
  userPreferences: string | null
  visitId: string | null
}
