/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type DrawerMenu_QueryFragment = { location?: { city?: string | null, stateAbbr?: string | null } };

export const DrawerMenu_QueryFragmentDoc = `
    fragment DrawerMenu_QueryFragment on Query {
  location(input: $locationFilter) @include(if: $withMarketTrendsLink) {
    city
    stateAbbr
  }
}
    `;



