import React from 'react'

import type { FC } from 'react'
import { useTourWizardConfig } from '../wizard/hooks/use-tour-wizard-config'

export const TourTimesEmpty: FC<{ onViewMoreDays: () => void }> = ({
  onViewMoreDays,
}) => {
  const { theme } = useTourWizardConfig()
  return (
    <div className={theme?.empty?.wrapper} data-tid="tour-empty-times">
      <p>No times available for these dates</p>
      <button
        className={theme?.empty?.button}
        type="button"
        onClick={onViewMoreDays}
        tabIndex={0}
      >
        View More Days
      </button>
    </div>
  )
}
