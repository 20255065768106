/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { Link_SeoLinkFragmentDoc, DistancePriceLink_SeoLinkFragmentDoc } from '../../../search/__generated__/search-page.gql';
export type SeoLinks_LocationFragment = { city?: string | null, geoType?: Types.LocationSearchTypes | null, state?: string | null, zip?: string | null, poi?: string | null, county?: string | null, hood?: string | null, seoLinks?: { nearby: { cities: Array<{ displayName: string, url: string }>, neighborhoods: Array<{ displayName: string, url: string }> }, categorizedRefinements?: { priceUnder: Array<{ displayName: string, url: string }>, other: Array<{ displayName: string, url: string }> } | null } | null };

export const SeoLinks_LocationFragmentDoc = `
    fragment SeoLinks_LocationFragment on Location {
  city
  geoType
  city
  state
  zip
  poi
  county
  hood
  seoLinks(input: $seoLinksQueryInput) {
    nearby {
      cities(input: $nearbyCitiesInput) {
        ...Link_SeoLinkFragment
      }
      neighborhoods(input: $nearbyNeighborhoodsInput) {
        ...Link_SeoLinkFragment
      }
    }
    categorizedRefinements(input: $nearbyCategorizedRefinementsInput) {
      priceUnder(priceSort: ASC) {
        ...Link_SeoLinkFragment
      }
      other {
        ...Link_SeoLinkFragment
      }
    }
  }
}
    `;



