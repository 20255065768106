import {
  BedFilter,
  LeadLeaseTerm,
  LeadOccupants,
  LeadPets,
} from '../../__generated__/api-types'
import type { LeadSubmissionInput } from '../../__generated__/api-types'

export enum LeadQuestion {
  BEDS = '1',
  OCCUPANTS = '2',
  PETS = '3',
  LEASE_TERM = '4',
}

type LeadMappingField = keyof Pick<
  LeadSubmissionInput,
  'questionBeds' | 'questionOccupants' | 'questionPets' | 'questionLeaseTerms'
>

type PromptMappingType = {
  [key: string]: {
    singleSelection: boolean
    text: string
    values: readonly string[]
    leadField: LeadMappingField
    leadValues:
      | readonly BedFilter[]
      | readonly LeadOccupants[]
      | readonly LeadPets[]
      | readonly LeadLeaseTerm[]
  }
}

export const PromptMapping: PromptMappingType = {
  [LeadQuestion.BEDS]: {
    singleSelection: false,
    text: 'How many bedrooms?',
    values: ['Studio', '1', '2', '3', '4+'],
    leadField: 'questionBeds',
    leadValues: [
      BedFilter.Studio,
      BedFilter.OneBedroom,
      BedFilter.TwoBedroom,
      BedFilter.ThreeBedroom,
      BedFilter.FourOrMoreBedrooms,
    ],
  },
  [LeadQuestion.OCCUPANTS]: {
    singleSelection: true,
    text: 'How many occupants?',
    values: ['1', '2', '3', '4+'],
    leadField: 'questionOccupants',
    leadValues: [
      LeadOccupants.One,
      LeadOccupants.Two,
      LeadOccupants.Three,
      LeadOccupants.FourPlus,
    ],
  },
  [LeadQuestion.PETS]: {
    singleSelection: true,
    text: 'Do you have any pets?',
    values: ['0', '1', '2', '3+'],
    leadField: 'questionPets',
    leadValues: [LeadPets.None, LeadPets.One, LeadPets.Two, LeadPets.ThreePlus],
  },
  [LeadQuestion.LEASE_TERM]: {
    singleSelection: true,
    text: 'Length of lease?',
    values: ['1-3', '4-6', '7-12', '13+'],
    leadField: 'questionLeaseTerms',
    leadValues: [
      LeadLeaseTerm.OneToThreeMonths,
      LeadLeaseTerm.FourToSixMonths,
      LeadLeaseTerm.SevenToTwelveMonths,
      LeadLeaseTerm.ThirteenMonthsPlus,
    ],
  },
} as const

export type EnhancedLeadResponses = Record<string, string[]>

export type EnhancedLeadSubmissionMapped = Pick<
  LeadSubmissionInput,
  | 'questionIds'
  | 'questionBeds'
  | 'questionOccupants'
  | 'questionPets'
  | 'questionLeaseTerms'
>

export enum LeadFormSteps {
  formFields,
  elfPrompts,
  thankYou,
}
