'use client'

import noImageLarge from '@brand/static/images/no-image-large.png'
import { useState, useMemo, useCallback } from 'react'

import { getCloudinaryUrl } from './get-cloudinary-url'
import { getPhotoData } from './get-photo-data'

import type { StaticImageData } from 'next/image'
import type { PhotoSize, PublicId } from './get-cloudinary-url'
import type { GetPhotoDataOptions } from './get-photo-data'
import type { ImgHTMLAttributes, ReactElement } from 'react'

type ImageProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  'alt' | 'src' | 'srcSet' | 'srcError'
>

enum BackupImage {
  PUBLIC_ID_ERROR_IMAGE = 'PUBLIC_ID_ERROR_IMAGE',
  SRC_ERROR_IMAGE = 'SRC_ERROR_IMAGE',
  DEFAULT_ERROR_IMAGE = 'DEFAULT_ERROR_IMAGE',
}

export interface CloudImageProps extends GetPhotoDataOptions, ImageProps {
  /** You must always specify the alt prop for accessibility. */
  alt: string

  /** Alt text for a backup image. */
  errorAlt?: string

  /**
   * When set to true it will disable lazy loading.
   * Set this true for images that are the Largest Contentful Paint (LCP)
   * element.
   */
  priority?: boolean

  /**
   * The publicly visible ID of the image.
   * - Comes from our cloud service.
   * - The cloud service we currently use is Cloudinary.
   */
  publicId: PublicId

  /**
   * Fallback publicId image. Used when there is an error loading the publicId
   * image.
   * - If both publicIdError and srcError are provided, the publicIdError image is prioritized.
   * - If the publicIdError image fails, the srcError image is used, if provided.
   */
  publicIdError?: PublicId

  /**
   * Fallback image URL. Used when there is an error loading the publicId image.
   * - If both publicIdError and srcError are provided, the publicIdError image is prioritized.
   * - If the publicIdError image fails, the srcError image is used, if provided.
   * - Uses a default fallback image if neither srcError or publicIdError are
   *   provided.
   */
  srcError?: StaticImageData | string

  /**
   * A list of <source> elements to be used in a <picture> element.
   * An alternative to providing a publicId. In case you need to
   * render multiple images instead of a single image
   */
  sources?: ReactElement<HTMLSourceElement>[]

  /**
   * Size of the image. Used for mobile and, if no desktop size provided, also
   * used for desktop.
   */
  size: PhotoSize

  /**
   * Size of the image for desktop.
   */
  sizeDesktop?: PhotoSize
}

/**
 * This component is a standardized way to display a cloud image.
 * - Limits you to certain image sizes.
 * - Lets you specify different responsive sizes for mobile and desktop
 * - Automatically provides the appropriate 2x dpi images
 *
 * @example
 * // Simplest usage
 * <CloudImage
 *   alt="property photo"
 *   publicId="ab6e65616f65f0f02915920f867c4b23"
 *   size="sm"
 * />
 *
 * @example
 * // Use different size for mobile vs desktop
 * <CloudImage
 *   alt="property photo"
 *   isUnpaid
 *   publicId="ab6e65616f65f0f02915920f867c4b23"
 *   size="md"
 *   sizeDesktop="lg"
 * />
 *
 * @example
 * // Resize the image and crop if necessary
 * <CloudImage
 *   alt="property photo"
 *   className="h-50 w-100 object-cover"
 *   publicId="ab6e65616f65f0f02915920f867c4b23"
 *   size="sm"
 * />
 *
 * @example
 * // Adding fallback url to handle broken image src
 * const srcError = example.png
 * <CloudImage
 *   alt="property photo"
 *   publicId="ab6e65616f65f0f02915920f867c4b23"
 *   size="sm"
 *   srcError={srcError}
 * />
 *
 *  * @example
 * // Adding fallback public id to handle broken image src
 * const srcError = example.png
 * <CloudImage
 *   alt="property photo"
 *   publicId="ab6e65616f65f0f02915920f867c4b23"
 *   size="sm"
 *   publicIdError={srcError}
 * />
 */
export function CloudImage({
  alt,
  className,
  errorAlt,
  isUnpaid,
  photoType,
  priority,
  publicId,
  size,
  sizeDesktop,
  sources,
  srcError,
  publicIdError,
  ...restprops // Rest of props will be placed on the <img>
}: CloudImageProps) {
  // Get the data needed to create the picture element with multiple sources
  // (photo formats and sizes)
  const opt = useMemo(
    () => ({
      isUnpaid,
      photoType: photoType || 'property',
      size,
      sizeDesktop,
    }),
    [isUnpaid, photoType, size, sizeDesktop]
  )
  const data = getPhotoData(publicId, opt)
  const [isMainImageError, setMainImageError] = useState(false)
  const [availableBackupImages, setAvailableBackupImages] = useState<{
    [key in BackupImage]: boolean
  }>({
    PUBLIC_ID_ERROR_IMAGE: Boolean(publicIdError),
    SRC_ERROR_IMAGE: Boolean(srcError),
    DEFAULT_ERROR_IMAGE: true,
  })

  const [errorUrl, setErrorUrl] = useState<string | null>(null)

  // here's an ugly hack. If the onError is triggered before hydration
  // (usually priority=true, LCP items, but sometimes other images based on
  // network conditions), React won't receive it. The fix is to wait until this
  // component is mounted and set src again to trigger the onError.
  const updateImageSource = useCallback(function updateImageSource(
    img: HTMLImageElement
  ) {
    if (img) {
      // based on nextjs
      // https://github.com/vercel/next.js/blob/canary/packages/next/src/client/image.tsx#L427-L431
      img.src = img.src
    }
  },
  [])

  const onError = useCallback(
    function onError() {
      const nextBackupImage = Object.entries(availableBackupImages).find(
        ([_, bool]) => bool
      )?.[0]

      if (
        nextBackupImage === BackupImage.PUBLIC_ID_ERROR_IMAGE &&
        publicIdError
      ) {
        const publicIdErrorUrl = getCloudinaryUrl(
          publicIdError,
          sizeDesktop || size,
          opt
        )

        setErrorUrl(publicIdErrorUrl)

        setAvailableBackupImages({
          ...availableBackupImages,
          PUBLIC_ID_ERROR_IMAGE: false,
        })
      }

      if (nextBackupImage === BackupImage.SRC_ERROR_IMAGE && srcError) {
        typeof srcError === 'object'
          ? setErrorUrl(srcError.src)
          : setErrorUrl(srcError)

        setAvailableBackupImages({
          ...availableBackupImages,
          SRC_ERROR_IMAGE: false,
          // if srcError image fails - don't both with default error image (avoid overfetching images)
          DEFAULT_ERROR_IMAGE: false,
        })
      }

      if (nextBackupImage === BackupImage.DEFAULT_ERROR_IMAGE) {
        setErrorUrl(noImageLarge.src)

        setAvailableBackupImages({
          ...availableBackupImages,
          DEFAULT_ERROR_IMAGE: false,
        })
      }
    },
    [availableBackupImages, publicIdError, size, sizeDesktop, srcError, opt]
  )

  const loadingProps = priority
    ? ({
        loading: 'eager',
        fetchpriority: 'high',
      } as const)
    : ({
        loading: 'lazy',
      } as const)

  // On error, the <img> tag needs to render without a <picture> element
  // wrapping it. On MacOS (Retina screen) what happens is that Mac assumes you
  // are loading a 2x resolution image and then renders it at 1/2 scale,
  // which prevents the error image from filling the space.
  if (isMainImageError && errorUrl) {
    return (
      <img
        alt={errorAlt ?? 'No Image Available'}
        className={className}
        {...restprops}
        src={errorUrl}
        onError={onError}
      />
    )
  }

  const hasSources = sources && sources.length > 0

  return (
    <picture>
      {!hasSources &&
        data.sources.map((s, index) => (
          // Order is not expected to change but just in case will use a
          // unique combo for the key prop
          <source
            key={`${index}${s.type}${s.media || ''}`}
            type={s.type}
            media={s.media}
            srcSet={s.srcset}
          />
        ))}
      {hasSources && sources}
      <img
        alt={alt}
        className={className}
        {...restprops}
        {...loadingProps}
        src={data.src}
        ref={updateImageSource}
        onError={(e) => {
          onError()
          setMainImageError(true)
          restprops.onError?.(e)
        }}
      />
    </picture>
  )
}
