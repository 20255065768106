import {
  PropertyType,
  PropertyTypeFilter,
} from '../../../__generated__/api-types'

const propertyTypeToFilterMap: Record<PropertyType, PropertyTypeFilter> = {
  [PropertyType.Apartments]: PropertyTypeFilter.Apartments,
  [PropertyType.Condo]: PropertyTypeFilter.Condos,
  [PropertyType.House]: PropertyTypeFilter.Houses,
  [PropertyType.Townhome]: PropertyTypeFilter.Townhouses,
  [PropertyType.Duplex]: PropertyTypeFilter.Houses,
  [PropertyType.Triplex]: PropertyTypeFilter.Houses,
}

export const getPropertyTypeFilter = (propertyType: PropertyType) =>
  propertyTypeToFilterMap[propertyType]
