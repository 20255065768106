import { format } from 'date-fns'
import { useMemo } from 'react'
import type { LeadCookieData } from '../lead-cookie-parser'

// First of next month
export function useDefaultMoveDate(leadCookieData?: LeadCookieData) {
  return useMemo(() => {
    const todaysDate = new Date()

    if (
      leadCookieData?.moveDate &&
      new Date(leadCookieData.moveDate).getTime() > todaysDate.getTime()
    ) {
      return leadCookieData.moveDate
    }

    todaysDate.setMonth(todaysDate.getMonth() + 1, 1)
    return format(todaysDate, 'yyyy-MM-dd')
  }, [leadCookieData?.moveDate])
}
