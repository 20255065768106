/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type Schools_SchoolFragment = { districtName?: string | null, name?: string | null, type?: string | null, grade?: string | null, overallRating?: string | null, milesToGeoCode?: string | null, locationSlug?: string | null };

export const Schools_SchoolFragmentDoc = `
    fragment Schools_SchoolFragment on School {
  districtName
  name
  type
  grade
  overallRating
  milesToGeoCode
  locationSlug
}
    `;



