import { getToday } from '@rentpath/web-utils'
import { MAX_DAYS_TO_SHOW } from '../../tour-wizard.config'
import type { TourDates } from '../../tour-wizard.store'

/**
 * Make sure next date range is within MAX_DAYS_TO_SHOW range.
 */
export function getShouldShowNext(
  tourDates: TourDates,
  daysToShow: number
): boolean {
  const [firstDateStr] = tourDates
  const timeDiff =
    new Date(firstDateStr).getTime() - new Date(getToday()).getTime()
  const dayDiff = timeDiff / (1000 * 3600 * 24)

  return dayDiff + daysToShow < MAX_DAYS_TO_SHOW
}
