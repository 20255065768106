import { getCloudinaryUrl } from './get-cloudinary-url'
import { getNextSize } from './get-next-size'

import type { PhotoSize, PhotoType, PublicId } from './get-cloudinary-url'

/** Data to be used when creating a source element. */
export interface PhotoDataSource {
  media?: string
  srcset: string
  type: string
}

export interface PhotoData {
  /**
   * Specifies the preferred image urls:
   * - WebP format images
   * - Desktop vs mobile image sizes
   * - Larger images for high dpi screens
   * - To be used in creating source elements within a picture element.
   */
  sources: PhotoDataSource[] // desktop and mobile webp

  /**
   * To be used for the src of the img element (within a picture element).
   * - Will be a jpg image to be used as a fallback for browsers that don't
   *   support picture element or webp images (generally that means just IE).
   */
  src: string
}

/** Options used when creating the photo data. */
export interface GetPhotoDataOptions {
  /**
   * Set to true if this is an unpaid property.
   * - We enhance images from unpaid properties because the images tend to be of
   *   lower quality.
   */
  isUnpaid?: boolean

  /**
   * Media query that transitions from small (mobile) screens to medium
   * (tablet) screens.
   * - We only switch image sizes between mobile and desktop, so we make the
   *   transition at the medium size.
   * @example { mediaDesktop: '(min-width: 768px)' }
   */
  mediaDesktop?: string

  /**
   * Type of photo that you are displaying.
   * - This will determine the sizes and cloudinary transformations that are used.
   * - Defaults to 'property' if not specified.
   */
  photoType?: PhotoType

  /**
   * Image size.
   * - Will be used for mobile first.
   * - You can optionally specify another (larger) size to use for desktop,
   *   using the sizeDesktop parameter.
   *
   * For 'property' photo type, the sizes correspond to:
   * - xs (135 x 90)
   * - sm (270 x 180)
   * - md (375 x 250)
   * - lg (540 x 360)
   * - xl (1080 x 720)
   * - 2xl (2160 x 1440)
   *
   * For 'floorplan' or 'management' photo type, the sizes correspond to:
   * - xs (50 x auto)
   * - sm (100 x auto)
   * - md (300 x auto)
   * - lg (600 x auto)
   * - xl (1000 x auto)
   * - 2xl (2000 x auto)
   *
   * @example {size:'md'}
   */
  size: PhotoSize

  /**
   * Optional different (larger) size to use for desktop widths.
   * - Uses the size parameter to specify the mobile first size, so if you don't
   *   specify this parameter, it will use that size.
   * @example {size:'md', sizeDesktop:'lg'}
   * @see mediaDesktop
   */
  sizeDesktop?: PhotoSize
}
/** Default media query for the mobile / desktop breakpoint */
export const BREAKPOINT = '(min-width: 768px)'

/** Don't use images above this size for mobile media query sizes */
const MAX_SIZE_MOBILE: PhotoSize = 'xl'

export function getPhotoData(
  publicId: PublicId, // Cloudinary image hash
  options: GetPhotoDataOptions
): PhotoData {
  // Set up defaults for options and override with the function param
  const opt: GetPhotoDataOptions = {
    mediaDesktop: BREAKPOINT,
    ...options,
  }

  // Use desktop size if specified, or fall back to mobile size
  const sizeDesktop = opt.sizeDesktop || opt.size

  // Generate url for jpg image, to be used as the fallback image
  // - We expect the fallback image to only be used by IE, so use desktop size
  const urlWebp = getCloudinaryUrl(publicId, sizeDesktop, {
    ...opt,
    format: 'webp',
  })

  const data: PhotoData = {
    sources: [],
    src: urlWebp,
  }

  // === Source for desktop webp ===
  // - Only if a separate size was requested
  // - This will have a media query so it will only be used if that matches,
  //   otherwise will fall through to the next source (which does not have a
  //   media query)
  if (opt.sizeDesktop) {
    const srcsetDesktop: string[] = []
    const size2xDesktop = getNextSize(sizeDesktop)

    if (size2xDesktop) {
      const url2xDesktop = getCloudinaryUrl(publicId, size2xDesktop, {
        ...opt,
        format: 'webp',
      })
      srcsetDesktop.push(`${url2xDesktop} 2x`)
    }

    const url1xDesktop = getCloudinaryUrl(publicId, sizeDesktop, {
      ...opt,
      format: 'webp',
    })
    srcsetDesktop.push(url1xDesktop)

    data.sources.push({
      media: opt.mediaDesktop,
      srcset: srcsetDesktop.join(', '),
      type: 'image/webp',
    })
  }

  // === Source for mobile webp ===
  // - This will be the default if the desktop source is not specified or if the
  //   media query does not match
  const srcsetMobile: string[] = []

  // Get the size for 2x dpi
  // - Use the next bigger size
  // - Do not exceed the maximum so we don't end up with a 2xl image on mobile
  const size2x = getNextSize(opt.size, MAX_SIZE_MOBILE)

  if (size2x) {
    const url2xMobile = getCloudinaryUrl(publicId, size2x, {
      ...opt,
      format: 'webp',
    })
    srcsetMobile.push(`${url2xMobile} 2x`)
  }

  const url1xMobile = getCloudinaryUrl(publicId, opt.size, {
    ...opt,
    format: 'webp',
  })
  srcsetMobile.push(url1xMobile)

  data.sources.push({
    // Mobile is first, so we don't need to specify a media query
    srcset: srcsetMobile.join(', '),
    type: 'image/webp',
  })

  return data
}
