import { ReactComponent as CalendarIcon } from '@brand/icons/calendar.svg'
import type { Placement } from '@popperjs/core'
import { useHasMounted } from '@rentpath/react-hooks'
import { toDate, toYYYYMMDD } from '@rentpath/web-utils'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import type {
  ChangeEvent,
  DetailedHTMLProps,
  ForwardedRef,
  HTMLAttributes,
  ReactNode,
} from 'react'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import useOnClickOutside from 'use-onclickoutside'
import type { DataAttributes } from '../../types'
import { CALENDAR_PORTAL_ID } from '../calendar/calendar.const'
import type { InputProps } from '../input/input'
import inputStyles from '../input/input.module.css'
import styles from './date-input.module.css'

const Calendar = dynamic(
  () => import('../calendar/calendar').then((mod) => mod.Calendar),
  {
    ssr: false,
  }
)

const DATE_FORMAT = 'yyyy-MM-dd'
type DateInputNativeProps = DetailedHTMLProps<
  HTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>
type SharedInputProps = Omit<InputProps, 'type' | 'label'>

export type DateInputProps = SharedInputProps &
  DateInputNativeProps &
  DataAttributes & {
    calendarElement?: ReactNode

    calendarPlacement?: Placement
    /** String in format 'yyyy-mm-dd' for a valid date.
     * - If an invalid date is passed it will be passed through to the input for
     *   the user to correct.
     * - Like a normal input you cannot use both defaultValue and value props. */
    defaultValue?: string

    /** String in format 'yyyy-mm-dd' for a valid date.
     * - If an invalid date is passed it will be passed through to the input for
     *   the user to correct.
     * - Like a normal input you cannot use both defaultValue and value props. */
    value?: string

    /**
     * Sub text appear inside the input next to the calendar icon
     */
    subText?: string

    /**
     * Message that appears under the input indicating error
     */
    error?: string

    showNativeDatePicker?: boolean
    showInPortal?: boolean
    onFocus?: (evt: FocusEvent) => void
  }

const popperOffsetOptionsFn = ({
  placement,
}: {
  placement: Placement
}): [number | null | undefined, number | null | undefined] => {
  if (placement === 'top-start') return [0, -8]
  return [0, 8]
}

export const DateInput = forwardRef(
  /** This component is a standardized way to prompt for a date.
   * Certain browsers do not support native date inputs:
   * - Currently that includes IE and desktop Safari
   * - Refer to the following for updated list:
   *   https://caniuse.com/input-datetime */
  function DateInputWithRef(
    {
      calendarPlacement = 'auto-start',
      subText = 'Move in on',
      showInPortal = false,
      ...props
    }: DateInputProps,
    forwardedRef: ForwardedRef<HTMLInputElement>
  ) {
    const { onBlur, onChange, onFocus, value } = props
    const hasMounted = useHasMounted()
    const [referenceElement, setReferenceElement] =
      useState<HTMLDivElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
      null
    )
    const [updatePopperLoaded, setUpdatePopperLoaded] = useState(false)

    const {
      styles: popperjsStyles,
      attributes,
      update: updatePopper,
    } = usePopper(referenceElement, popperElement, {
      placement: calendarPlacement,
      strategy: 'fixed',
      modifiers: [
        {
          name: 'flip',
          options: {
            allowedAutoPlacements: ['top-start', 'bottom-start'],
          },
        },
        {
          name: 'offset',
          options: {
            offset: popperOffsetOptionsFn,
          },
        },
      ],
    })

    if (updatePopper && !updatePopperLoaded) {
      setUpdatePopperLoaded(true)
    }

    /** Maintain the current value of the date input in state because we need to
     * coordinate changes between the input and the popup calendar when using
     * defaultValue as an uncontrolled component.
     * - Note we useEffect below to update this in case the value prop changes.
     * - Note as with normal inputs, you cannot use both the defaultValue and
     *   value prop together! */
    const [currentValue, setCurrentValue] = useState(
      props.defaultValue || props.value
    )

    /** State of the calendar popup.
     * - Closed by default.
     * - Opens when user focuses on the input.
     * - Closes when user blurs the input (unless clicking in the calendar)
     * - Closes when user clicks a valid date in the calendar.
     * - Closes when user clicks anywhere outside this component. */
    const [shouldShowCalendar, setShouldShowCalendar] = useState(false)

    /** DOM node that wraps the component.
     * - Used to detect clicks that are outside the component which should close
     *   the calendar. */
    const wrapperRef = useRef<HTMLDivElement>(null)

    /** DOM node that wraps the input.
     * - Used to detect clicks that are outside the component which should close
     *  the calendar. */
    const popperElementRef = useRef<HTMLDivElement | null>(null)

    /** Timeout id for hiding the calendar after a blur event. */
    const blurTimeoutRef = useRef(0)

    // If the setTimeout call runs after the component unmounts, that would be a
    // memory leak, so be sure to clear it
    useEffect(
      () =>
        // Returns function to run when component unmounts
        () => {
          clearTimeout(blurTimeoutRef.current)
        },
      []
    )

    /** Save the reference for popperElement in ref object
     * - This is needed because the popperElement is created after the component
     *  mounts, but we need to access it in the useOnClickOutside hook.
     * Using popperElement directly in the hook would not update the ref argument when popperElement changes.
     */
    useEffect(() => {
      if (popperElement) {
        popperElementRef.current = popperElement
      }
    }, [popperElement])

    const [calendarValue, setCalendarValue] = useState<Date | undefined>(
      undefined
    )

    // If the "value" prop changes, update state
    useEffect(() => {
      if (props.value !== undefined) {
        setCurrentValue(props.value)
      } else if (typeof props.min === 'string') {
        setCurrentValue(props.min)
      }
    }, [props.value, props.min])

    useEffect(() => {
      setCalendarValue(toDate(currentValue))
    }, [currentValue])

    const calendarMax = toDate(
      typeof props.max === 'number' ? `${props.max}` : props.max
    )
    const calendarMin = toDate(
      typeof props.min === 'number' ? `${props.min}` : props.min
    )

    // Clicks outside the component should close the calendar
    useOnClickOutside(showInPortal ? popperElementRef : wrapperRef, () => {
      setShouldShowCalendar(false)
    })

    /** Function to call whenever the value changes (either by the user typing a
     * change, or by clicking a date in the calendar).
     * - We call the onChange handler to report the new value. */
    const updateValue = useCallback(
      (s: string) => {
        // - For an uncontrolled component update the state.
        // - For a controlled component, the onChange handler must update the value
        //   prop, then when that prop changes the state will be updated.
        if (value === undefined) {
          setCurrentValue(s)
        }

        if (onChange) {
          onChange({
            target: { name: props.name as string, value: s },
          } as ChangeEvent<HTMLInputElement>)
        }
      },
      [onChange, props.name, value]
    )

    /** Click handler for everything in the calendar. */
    function handleClickCalendar() {
      // Prevent the calendar being closed if the blur event fired immediately
      // before the click
      clearTimeout(blurTimeoutRef.current)
    }

    /** Callback from the calendar component.
     * - Is only called when user clicks on a new date.
     * - Return date in the format we requested, which is "yyyy-mm-dd"
     */
    const handleChangeCalendar = useCallback(
      (s: string) => {
        // Hide the calendar after the user clicks a valid date. The user can
        // re-open the calendar by focusing on the input again
        setShouldShowCalendar(false)
        updateValue(s)
      },
      [updateValue]
    )

    function isInvalidCharacter(val: string): boolean {
      // Only allow numbers, dashes or foward slashes
      return !/^[0-9-/]{0,}$/.test(val)
    }

    /** Callback from the text or date input.
     * - From a native date input, the value will be "yyyy-mm-dd" or an empty
     *   string
     * - From a text input, the value will be whatever the user typed, expected to
     *   be "mm/dd/yyyy" but might contain whatever the user typed!
     * - In either case - the value passed back to the onChange prop might not be
     *   a valid date, so it always needs to be validated and checked
     */
    const handleChangeInput = useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (isInvalidCharacter(evt.target.value)) {
          return
        }
        const newValue = toYYYYMMDD(evt.target.value) || ''
        updateValue(newValue)
      },
      [updateValue]
    )

    /** Focus handler for the input. */
    const handleFocusInput = useCallback(
      (evt: FocusEvent) => {
        if (typeof updatePopper === 'function') {
          // eslint-disable-next-line no-void
          void updatePopper()
        }
        // Open the pop-up calendar
        setShouldShowCalendar(true)

        if (onFocus) {
          onFocus(evt)
        }
      },
      [updatePopper, onFocus]
    )

    /** Fix to accommodate desktop Safari
     * Input onFocus event does not fire correctly in desktop Safari
     * when clicking on the date input outside the date value.
     * Need to query DOM directly rather than leverage forwardedRef to
     * avoid interference with ref behavior within react-hook-form.
     */
    useEffect(() => {
      if (updatePopperLoaded) {
        props.name &&
          wrapperRef.current
            ?.getElementsByTagName('input')[0]
            ?.addEventListener('focus', handleFocusInput)
      }

      return () => {
        props.name &&
          document
            .getElementsByName(props.name)[0]
            ?.removeEventListener('focus', handleFocusInput)
      }
    }, [handleFocusInput, props.name, updatePopperLoaded])

    /** Focus handler for the calendar wrapper */
    const handleFocusCalendar = useCallback(() => {
      // Prevent the calendar being closed if the input blur event fired
      // immediately before the calendar got focus.
      // - This is for keyboard accessibility, when the user presses tab to go
      //   from the input field to the calendar.
      clearTimeout(blurTimeoutRef.current)
    }, [blurTimeoutRef])

    /** Blur handler for the input.
     * - Closes the popup calendar unless the user clicks on the calendar. */
    const handleBlurInput = useCallback(
      (evt: React.FocusEvent<HTMLInputElement>) => {
        // If the user clicks on the calendar, then the blur event occurs before
        // the click event. Because the blur event closes the calendar, that means
        // the user would not be able to click the calendar. So instead of
        // immediately hiding the calendar, we set a short timeout before doing
        // so. Then we can cancel that timeout if the user clicked on the
        // calendar.
        blurTimeoutRef.current = window.setTimeout(() => {
          setShouldShowCalendar(false)
        }, 190) // should be under INP "good" theshold of 200ms

        if (onBlur) {
          onBlur(evt)
        }
      },
      [onBlur]
    )

    /**
     * Addresses issue where calendar does not close when user presses escape key
     * instead it was closing the entire modal because the key down event was propagating
     * to the modal listeners.
     */
    useEffect(() => {
      if (!shouldShowCalendar) {
        return
      }

      const handleKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          e.stopPropagation()
          setShouldShowCalendar(false)
        }
      }

      document.addEventListener('keydown', handleKeydown)

      return () => document.removeEventListener('keydown', handleKeydown)
    }, [shouldShowCalendar])

    const calendarProps = {
      dateFormat: DATE_FORMAT,
      maxDate: calendarMax,
      minDate: calendarMin,
      onChange: handleChangeCalendar,
      value: calendarValue,
    }

    const dateInputProps = {
      'data-tid': props['data-tid'],
      ref: forwardedRef,
      onBlur: handleBlurInput,
      onChange: handleChangeInput,
      tabIndex: props.tabIndex,
      name: props.name,
      autoComplete: props.autoComplete,
      id: props.id,
      max: props.max,
      maxLength: props.maxLength,
      min: props.min,
      className: styles.input,
    }

    const error = props.error ? (
      <p
        role="alert"
        data-tid={
          props['data-tid'] ? `${props['data-tid']}-message` : undefined
        }
        className={inputStyles.error}
      >
        {props.error}
      </p>
    ) : null

    if (props.showNativeDatePicker) {
      return (
        <div>
          <label
            className={clsx(
              inputStyles.wrapperStyles,
              props.error && inputStyles.inputWithError
            )}
          >
            <div className={clsx(inputStyles.input, styles.inputWrapper)}>
              <span className={styles.inputPrefix}>
                <CalendarIcon />
              </span>
              <sub className={styles.subText}>{subText}</sub>
              <input {...dateInputProps} type="date" value={currentValue} />
            </div>
          </label>
          {error}
        </div>
      )
    }

    const calendar = (
      <div
        ref={(node) => node && setPopperElement(node)}
        style={{ ...popperjsStyles.popper, zIndex: 'var(--z-index-5x)' }}
        {...attributes.popper}
      >
        {shouldShowCalendar && (
          <div
            onClick={handleClickCalendar}
            onFocus={handleFocusCalendar}
            aria-hidden="true"
            style={{ minWidth: 258 }}
          >
            {React.isValidElement(props.calendarElement) ? (
              React.cloneElement(props.calendarElement, calendarProps)
            ) : (
              <Calendar {...calendarProps} />
            )}
          </div>
        )}
      </div>
    )

    const isFirefox = navigator.userAgent.includes('Firefox')

    return (
      <div>
        <div ref={wrapperRef}>
          <div ref={(node) => node && setReferenceElement(node)}>
            <label
              className={clsx(
                inputStyles.wrapperStyles,
                props.error && inputStyles.inputWithError
              )}
            >
              <div
                className={clsx(inputStyles.input, styles.inputWrapper)}
                onClick={handleClickCalendar}
              >
                <span className={styles.inputPrefix}>
                  <CalendarIcon />
                </span>

                <sub className={styles.subText}>{subText}</sub>

                <div
                  className={clsx(
                    styles.dateInputWrapper,
                    isFirefox ? styles.dateInputWrapperFirefox : ''
                  )}
                >
                  <input
                    {...dateInputProps}
                    type="date"
                    value={currentValue}
                    onChange={onChange}
                    onClick={(e) => e.preventDefault()}
                  />
                </div>
              </div>
            </label>
          </div>

          {showInPortal
            ? hasMounted
              ? createPortal(calendar, getCalendarPortalTarget())
              : null
            : calendar}
        </div>

        {error}
      </div>
    )
  }
)

function getCalendarPortalTarget() {
  if (document.getElementById(CALENDAR_PORTAL_ID)) {
    return document.getElementById(CALENDAR_PORTAL_ID) as HTMLDivElement
  }

  const div = document.createElement('div')
  div.id = CALENDAR_PORTAL_ID
  document.body.appendChild(div)

  return div
}
