import clsx from 'clsx'
import React from 'react'

import type { DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import { useTourWizardConfig } from './hooks/use-tour-wizard-config'

export const TourWizardStepContainer: FC<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ children, className, ...rest }) => {
  const { theme } = useTourWizardConfig()
  return (
    <div
      className={clsx(theme?.stepContainer, className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </div>
  )
}
