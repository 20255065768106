import { buildLeadQuestionsMessage } from '../../enhanced-lead-form/build-lead-questions-message'

export interface BuildMessageSimpleProps {
  name: string
  propertyName: string
  dateTime: string
  customerMessage?: string
  questions?: Record<string, string[]>
  leadQuestionIds?: string[]
}

export const buildRequestTourMessage = ({
  name,
  propertyName,
  dateTime,
  customerMessage,
  questions,
  leadQuestionIds,
}: BuildMessageSimpleProps) => {
  const message = customerMessage
    ? `
  ---

  ${customerMessage}`
    : ''

  return `${name} wants to tour ${dateTime} at ${propertyName}. ${buildLeadQuestionsMessage(
    {
      questions,
      leadQuestionIds,
    }
  )}

Please respond to finalize when they can tour.
  ${message}`
}
