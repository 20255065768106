import { useMediaQuery } from '@rentpath/react-hooks'
import { useRouter } from 'next/router'

import { Button } from '../../../../components/button/button'
import { IconButton } from '../../../../components/icon-button/icon-button'
import { ReactComponent as ArrowRightIcon } from '@brand/icons/arrow-right.svg'
import { getPropertyTypeMap } from '@brand/search/property-type-utils'

import type { ThankYou_ListingFragment } from '../../__generated__/thank-you.gql'
import { useRequestData } from '../../../request-data/pages-router/use-request-data'

import {
  getPageType,
  srpViewToPageMap,
} from '../../../analytics/utils/get-page-type'
import { getSearchPageUrl } from '../../../search/utils/get-search-page-url'

type FinalStepButtonProps = {
  onClose?: () => void
  withIcon?: boolean
  initiatedLeadListing: ThankYou_ListingFragment
}

const LABEL = 'See More Listings'

export function FinalStepButton({
  onClose,
  withIcon,
  initiatedLeadListing,
}: FinalStepButtonProps) {
  const { isMobile: isMobileDevice } = useRequestData()
  const router = useRouter()
  const isMobile = useMediaQuery('(max-width: 767px)', isMobileDevice)
  const pageType = getPageType(isMobile, router.pathname, router.query)
  const isSrp = Object.values(srpViewToPageMap).includes(pageType)

  function onSeeMoreClick() {
    if (isSrp) {
      onClose?.()
    } else {
      // saved page doesn't have access to state/city from the router
      // so we must 1) check if we're on the saved page and
      // 2) use the initiatedLeadListing's city/state/propertyType
      if (router.pathname.match(/\/saved/)) {
        const propertyTypeRefinement = getPropertyTypeMap(
          initiatedLeadListing.propertyType
        ).lower

        const listingSRPUrl = getSearchPageUrl({
          state: initiatedLeadListing.location?.state?.replace(/ /g, '-'),
          location: initiatedLeadListing.location?.city?.replace(/ /g, '-'),
          propertyTypes: propertyTypeRefinement,
        })

        if (listingSRPUrl) {
          return window.location.assign(listingSRPUrl)
        }
      }

      const { state, city, propertyTypes } = router.query

      const searchPageUrl = getSearchPageUrl({
        state,
        location: city,
        propertyTypes,
      })

      if (searchPageUrl) {
        return window.location.assign(searchPageUrl)
      }

      return null
    }
  }

  if (withIcon) {
    return (
      <IconButton
        rounded
        showLabel
        iconPosition="right"
        icon={<ArrowRightIcon />}
        variant="primary"
        label={LABEL}
        onClick={onSeeMoreClick}
      />
    )
  }

  return (
    <Button onClick={onSeeMoreClick} variant="primary" rounded>
      {LABEL}
    </Button>
  )
}
