/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type PublicTransitStop_PublicTransitStopFragment = { routes: Array<string>, type?: Types.PublicTransitStopType | null, name?: string | null, distanceInMiles?: number | null };

export const PublicTransitStop_PublicTransitStopFragmentDoc = `
    fragment PublicTransitStop_PublicTransitStopFragment on PublicTransitStop {
  routes
  type
  name
  distanceInMiles
}
    `;



