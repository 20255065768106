import clsx from 'clsx'
import type { HTMLAttributes } from 'react'
import {
  forwardRef,
  memo,
  useImperativeHandle,
  useRef,
  useState,
  useCallback,
} from 'react'
import { ReactComponent as ChevronLeftIcon } from '@brand/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from '@brand/icons/chevron-right.svg'
import styles from './horizontal-scroll-arrow-button.module.css'

interface HorizontalScrollArrowButtonProps
  extends Pick<HTMLAttributes<HTMLButtonElement>, 'aria-label'> {
  onClick: (directionIndex: 'left' | 'right') => void
  direction: 'left' | 'right'
}

export interface HorizonalScrollButtonRef extends HTMLButtonElement {
  disable: () => void
  enable: () => void
}

export const HorizontalScrollArrowButton = memo(
  forwardRef<HTMLButtonElement, HorizontalScrollArrowButtonProps>(
    function HorizontalScrollArrowButton(props, forwardedRef) {
      const { direction, onClick } = props
      const ChevronIcon =
        direction === 'left' ? ChevronLeftIcon : ChevronRightIcon
      const ref = useRef<HTMLButtonElement>(null)
      const [disabled, setDisabled] = useState(true)

      const handleClick = useCallback(
        function handleClick() {
          onClick(direction)
        },
        [onClick, direction]
      )

      useImperativeHandle(
        forwardedRef,
        () => ({
          ...ref.current!,
          disable: () => {
            setDisabled(true)
          },
          enable: () => {
            setDisabled(false)
          },
        }),
        []
      )

      return (
        <button
          onClick={handleClick}
          aria-label={props['aria-label']}
          className={clsx(styles.button, {
            [styles.disabled]: disabled,
            [styles.right]: direction === 'right',
            [styles.left]: direction === 'left',
          })}
          disabled={disabled}
          type="button"
        >
          <ChevronIcon
            aria-hidden
            className={clsx(styles.text, disabled && styles.disabled)}
          />
        </button>
      )
    }
  )
)
