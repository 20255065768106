import { headingFont } from '@brand/styles/fonts'

import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from '@rentpath/core-components'
import clsx from 'clsx'
import type { ElementType, ForwardedRef, ReactNode } from 'react'
import { forwardRef } from 'react'
import type { DataAttributes } from '../../types'

import styles from './heading.module.css'

export type HeadingOwnProps = {
  children: ReactNode
  level?: '1x' | '2x' | '3x' | '4x' | 'body' | 'headline'
  withBodyFont?: boolean
} & DataAttributes

const HeadingDefaultElement = 'h2'

export const Heading: PolymorphicForwardRefExoticComponent<
  HeadingOwnProps,
  typeof HeadingDefaultElement
> = forwardRef(function Heading<
  T extends ElementType = typeof HeadingDefaultElement
>(
  {
    as,
    className,
    withBodyFont,
    level,
    ...restProps
  }: PolymorphicPropsWithoutRef<HeadingOwnProps, T>,
  ref: ForwardedRef<Element>
) {
  const Element: ElementType = as || HeadingDefaultElement

  return (
    <Element
      ref={ref}
      className={clsx(
        styles.heading,
        {
          [styles.headingLevel1]: level === '1x',
          [styles.headingLevel2]: level === '2x',
          [styles.headingLevel3]: level === '3x',
          [styles.headingLevel4]: level === '4x',
          [styles.headingLevelBody]: level === 'body',
          [styles.headingLevelHeadline]: level === 'headline',
        },
        withBodyFont ? styles.headingWithBodyFont : headingFont.className,
        className
      )}
      {...restProps}
    />
  )
})
