import type { TourWizardConfig } from '../../tour-wizard.types'
import type { TourTimesQueryVariables } from './__generated__/tour-times.gql'
import { TourType } from '../../../../__generated__/api-types'

export const getAsyncTourTimesVariables = (
  tourWizardConfig: TourWizardConfig,
  tourType: TourType,
  dates: string[]
): TourTimesQueryVariables => ({
  listingId: tourWizardConfig.listing?.id,
  dates,
  type: tourType || TourType.InPerson,
})
