/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { ListingPhone_ListingFragmentDoc } from '../../../listing-phone/__generated__/listing-phone.gql';
import { TelephonyFields_ListingFragmentDoc, CtaButtons_ListingFragmentDoc, CtaButtons_TourProviderDetailsFragmentDoc, CtaButtons_LocationFragmentDoc } from '../../../cta-buttons/__generated__/cta-buttons.gql';
export type OfficeHourTimeFragment = { open?: string | null, close?: string | null, comment?: string | null };

export type OfficeHoursFieldsFragment = { day: string, times: Array<{ open?: string | null, close?: string | null, comment?: string | null }> };

export type OfficeHours_ListingQueryFragment = { id: string, tplsource?: string | null, isActive: boolean, isBasic: boolean, phoneMobileSem?: string | null, phoneMobile?: string | null, phoneDesktop?: string | null, phoneDesktopText?: string | null, phoneDesktopSem?: string | null, phoneDesktopSemText?: string | null, phoneMobileText?: string | null, phoneMobileSemText?: string | null, revenue?: number | null, zScore?: number | null, NV_RPL?: number | null, officeHours: Array<{ day: string, times: Array<{ open?: string | null, close?: string | null, comment?: string | null }> }>, mitsPhone: { raw?: string | null, formatted?: string | null }, telephony: { isSwapPhoneEligible: boolean, webNumber: { raw?: string | null, formatted?: string | null }, semNumber: { raw?: string | null, formatted?: string | null } } };

export const OfficeHourTimeFragmentDoc = `
    fragment OfficeHourTime on OfficeHourTime {
  open
  close
  comment
}
    `;
export const OfficeHoursFieldsFragmentDoc = `
    fragment OfficeHoursFields on OfficeHour {
  day
  times {
    ...OfficeHourTime
  }
}
    `;
export const OfficeHours_ListingQueryFragmentDoc = `
    fragment OfficeHours_ListingQueryFragment on Listing {
  id
  tplsource
  officeHours {
    ...OfficeHoursFields
  }
  ...ListingPhone_ListingFragment
}
    `;



