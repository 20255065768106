/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { Link_SeoLinkFragmentDoc, DistancePriceLink_SeoLinkFragmentDoc } from '../../../search/__generated__/search-page.gql';
export type CitiesByStateSeoLinks_QueryFragment = { location: { seoLinks?: { citiesByState: Array<{ propertyType: string, displayName: string, url: string }> } | null } };

export const CitiesByStateSeoLinks_QueryFragmentDoc = `
    fragment CitiesByStateSeoLinks_QueryFragment on Query {
  location(input: $locationFilter) {
    seoLinks(input: $seoLinksQueryInput) {
      citiesByState(input: $citiesByStateInput) {
        ...Link_SeoLinkFragment
        propertyType
      }
    }
  }
}
    `;



