/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type PriceDropsFieldsFragment = { priceDrops: { overall?: number | null, byFloorplanId: Array<{ id: string, priceDrop: number } | null> } };

export const PriceDropsFieldsFragmentDoc = `
    fragment PriceDropsFields on Listing {
  priceDrops {
    byFloorplanId {
      id
      priceDrop
    }
    overall
  }
}
    `;



