import { useRouter } from 'next/router'
import { getPageType } from '../analytics/utils/get-page-type'
import { useRequestData } from '../request-data/pages-router/use-request-data'
import { ThankYou } from './thank-you'
import { ThankYouRedesign } from './thank-you-redesign/thank-you-redesign'
import type { ThankYou_ListingFragment } from './__generated__/thank-you.gql'
import { TaggingPageType } from '../tagging/tagging.const'
import { useFetchSimilarProperties } from '../search/hooks/use-fetch-similar-properties'

type ThankYouContainerProps = {
  heading: string
  listing: ThankYou_ListingFragment
  showEmailLeadForScheduleTour?: boolean
  initialCustomMessage?: JSX.Element
  shouldforceRedesign?: boolean
  onClose?: () => void
}

export function ThankYouContainer({
  heading,
  listing,
  onClose,
  initialCustomMessage,
  shouldforceRedesign,
  showEmailLeadForScheduleTour,
}: ThankYouContainerProps) {
  const router = useRouter()
  const requestData = useRequestData()
  const { isMobile } = requestData
  const pageName = getPageType(isMobile, router.pathname, router.query)
  const isPdp = pageName === TaggingPageType.DETAIL

  const primaryLeadListingHasAttFiber = listing.adPartners.some(
    (adPartner) => adPartner?.name === 'ATT'
  )

  const showRedesignModal =
    !primaryLeadListingHasAttFiber || shouldforceRedesign

  const similarPropertiesAsyncData = useFetchSimilarProperties({
    shouldFetch: true,
    listingId: listing.id || '',
  })

  return showRedesignModal ? (
    <ThankYouRedesign
      similarProperties={similarPropertiesAsyncData}
      onClose={onClose}
      redesignVersion={0}
      listing={listing}
      showEmailLeadForScheduleTour={showEmailLeadForScheduleTour}
      initialCustomMessage={initialCustomMessage}
    />
  ) : (
    <ThankYou
      heading={heading}
      listing={listing}
      onClose={onClose}
      isMobile={isMobile}
      isPdp={isPdp}
      similarProperties={similarPropertiesAsyncData}
    />
  )
}
