import { useAtomValue, useSetAtom } from 'jotai'
import { Prompt } from './prompt'
import {
  enhancedLeadsAtom,
  setEnhancedLeadValueAtom,
} from './enhanced-leads.store'
import styles from './enhanced-lead-prompts.module.css'
import { Fragment } from 'react'

interface EnhancedLeadPromptsProps {
  promptIds: string[]
}

export function EnhancedLeadPrompts({ promptIds }: EnhancedLeadPromptsProps) {
  const enhancedLeadValues = useAtomValue(enhancedLeadsAtom)
  const updateEnhancedLeads = useSetAtom(setEnhancedLeadValueAtom)

  return (
    <div data-tid="enhanced-lead-prompts">
      {promptIds.map((id, index) => (
        <Fragment key={id}>
          <Prompt
            promptId={id}
            selected={enhancedLeadValues[id]}
            onChange={(values: string | string[]) =>
              updateEnhancedLeads({ id, values })
            }
          />
          {index < promptIds.length - 1 && <hr className={styles.separator} />}
        </Fragment>
      ))}
    </div>
  )
}
