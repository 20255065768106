import { brandTrackingName } from '@brand/config/brand-config'

export function urlForWebsiteLink(website: string) {
  try {
    const url = new URL(website)
    if (url && !url.searchParams.has('utm_source')) {
      url.searchParams.append('utm_source', brandTrackingName)
    }
    return url.toString()
  } catch (e) {
    return null
  }
}
