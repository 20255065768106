/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type AmenitiesFieldsFragment = { category: string, name: string, highlight: boolean };

export type Amenities_ListingFragment = { amenitiesWithGroups: Array<{ category: string, name: string, highlight: boolean }> };

export const AmenitiesFieldsFragmentDoc = `
    fragment AmenitiesFieldsFragment on AmenityWithGroup {
  category
  name
  highlight
}
    `;
export const Amenities_ListingFragmentDoc = `
    fragment Amenities_ListingFragment on Listing {
  amenitiesWithGroups {
    ...AmenitiesFieldsFragment
  }
}
    `;



