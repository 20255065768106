import { useAtom, useSetAtom } from 'jotai'
import { useState } from 'react'
import { useFeatureVariable } from '@rentpath/ab-testing-react'

import { ReactComponent as CloseIcon } from '@brand/icons/close.svg'
import { ReactComponent as EmailIcon } from '@brand/icons/email.svg'

import { ThankYouRedesignStackedCards } from './thank-you-redesign-stacked-cards'
import { getIsScheduleTourEnabled } from '../../../tour-wizard/get-is-schedule-tour-enabled'

import type { OneClickCookieData } from '../../../one-click-lead/one-click-lead-cookie.store'
import { oneClickCookieAtom } from '../../../one-click-lead/one-click-lead-cookie.store'

import {
  SCHEDULE_TOUR,
  SEND_CONTACT_INFO,
  SEND_TOUR_REQUEST,
} from '../../../cta-buttons/cta-buttons.const'

import { useSubmitOneClickLead } from '../../../one-click-lead/use-submit-one-click-lead'
import { ThankYouRedesignPhone } from '../thank-you-redesign-phone/thank-you-redesign-phone'
import {
  EMAIL,
  REQUEST_A_TOUR,
  SCHEDULE_TOUR as ONE_CLICK_SCHEDULE_TOUR,
} from '../../../one-click-lead/one-click-lead.const'
import { ThankYouRedesignFinalScreen } from '../final-screen/thank-you-redesign-final-screen'
import { ConfirmationMessage } from '../confirmation-message/thank-you-confirmation-message'

import styles from './thank-you-redesign-stacked-cards-wrapper.module.css'

import type { SimilarOrViewed_ListingFragment } from '../../../listing-card-preview-group/__generated__/similar-properties.gql'

import { ScreenStates, type ScreenStatesType } from '../thank-you-redesign'
import { ThankYouAdStep } from '../thank-you-ad-step/thank-you-ad-step'
import type { ThankYou_ListingFragment } from '../../__generated__/thank-you.gql'
import {
  closeDialogModalById,
  showDialogModalById,
} from '../../../dialog-modal/dialog-modal-interaction-by-id'
import { tourWizardModalStateAtom } from '../../../cta-modals/cta-modals.atom'
import { LEAD_FORM_MODAL_ID } from '../../../lead-form/lead-form-modal'
import { REQUEST_TOUR_MODAL_ID } from '../../../request-a-tour/request-a-tour-wizard-modal'
import { TOUR_WIZARD_MODAL_ID } from '../../../tour-wizard/tour-wizard-modal'

export interface ThankYouRedesignCardsWrapperProps {
  initiatedLeadListing: ThankYou_ListingFragment
  initialCustomMessage?: JSX.Element
  listings: SimilarOrViewed_ListingFragment[]
  dataTagSection?: string
  showEmailLeadForScheduleTour?: boolean
  setActiveScreen: (screen: ScreenStatesType) => void
  activeScreen: ScreenStatesType
  onClose?: () => void
}

const dataTagBtn = {
  [EMAIL]: 'email_one_click',
  [ONE_CLICK_SCHEDULE_TOUR]: 'schedule_tour_one_click',
  [REQUEST_A_TOUR]: 'request_tour_one_click',
}

export type ListingIdsShown = {
  submittedIds: string[]
  notInterestedIds: string[]
}

function getLeadType(
  scheduleTourEnabled: boolean,
  mostRecentLeadTypeSubmitted: string,
  requestTourOptOut: boolean
) {
  if (scheduleTourEnabled) {
    return ONE_CLICK_SCHEDULE_TOUR
  }

  if (mostRecentLeadTypeSubmitted === REQUEST_A_TOUR && !requestTourOptOut) {
    return REQUEST_A_TOUR
  }

  return EMAIL
}

export function ThankYouRedesignCardsWrapper({
  initiatedLeadListing,
  listings,
  dataTagSection,
  activeScreen,
  initialCustomMessage,
  setActiveScreen,
  showEmailLeadForScheduleTour,
  onClose,
}: ThankYouRedesignCardsWrapperProps) {
  const [oneClickData] = useAtom(oneClickCookieAtom)
  const { mostRecentLeadTypeSubmitted } = oneClickData
  const wasTourLead = mostRecentLeadTypeSubmitted === REQUEST_A_TOUR
  const noThankYouListings = !listings?.length
  const setTourWizardModalAtom = useSetAtom(tourWizardModalStateAtom)

  const [listingIdsShown, setListingIdsShown] = useState<ListingIdsShown>({
    submittedIds: [],
    notInterestedIds: [],
  })

  const scheduleTourCtaText = useFeatureVariable<string>(
    ['tour_headline_copy', 'schedule_tour_cta_text'],
    SCHEDULE_TOUR
  )

  const leadTypeMap = {
    [EMAIL]: SEND_CONTACT_INFO,
    [ONE_CLICK_SCHEDULE_TOUR]: scheduleTourCtaText,
    [REQUEST_A_TOUR]: SEND_TOUR_REQUEST,
  }

  const allViewedListingIds = [
    ...listingIdsShown.submittedIds,
    ...listingIdsShown.notInterestedIds,
  ]

  const activeListing = listings[allViewedListingIds.length]

  const { submitOneClickLead } = useSubmitOneClickLead({
    listing: activeListing,
  })

  const scheduleTourEnabled =
    activeListing &&
    getIsScheduleTourEnabled(activeListing.tourProviderDetails) &&
    !showEmailLeadForScheduleTour
  const phoneRequired = activeListing?.leadPhoneRequired

  const leadType = getLeadType(
    scheduleTourEnabled,
    mostRecentLeadTypeSubmitted,
    activeListing?.requestTourOptOut
  )
  const isFirstListing = activeListing?.id === listings[0]?.id
  const isLastListing = listings[listings.length - 1]?.id === activeListing?.id
  const dataTagTextSendLead =
    leadType === EMAIL ? 'send_contact_info' : 'send_tour_request'

  function setFinalScreen() {
    setActiveScreen(ScreenStates.Final)
  }

  function viewNextListing() {
    if (isLastListing) {
      setFinalScreen()
    } else {
      setActiveScreen(ScreenStates.Listing)
      setListingIdsShown((prevListingIds) => ({
        ...prevListingIds,
        notInterestedIds: [
          ...prevListingIds.notInterestedIds,
          activeListing.id,
        ],
      }))
    }
  }

  function submitLead(additionalLeadDetails?: Partial<OneClickCookieData>) {
    submitOneClickLead(additionalLeadDetails)
      .then(() => {
        setListingIdsShown((prevListingIds) => ({
          ...prevListingIds,
          submittedIds: [...prevListingIds.submittedIds, activeListing.id],
        }))

        if (isLastListing) {
          setFinalScreen()
        }
      })
      .catch(() => {
        // should never happen--we have error handling in useSubmitOneClickLead,
        // but just in case
        alert('issue submitting lead, try again')
      })
  }

  function onInterestedClick(event: React.MouseEvent) {
    event.stopPropagation()
    window.eventTracker?.track('click', {
      item: `${dataTagTextSendLead}_${allViewedListingIds.length + 1}`,
      section: dataTagSection,
    })
    if (activeScreen === 'listing' && phoneRequired && !oneClickData.phone) {
      setActiveScreen(ScreenStates.Phone)
    } else {
      if (leadType === ONE_CLICK_SCHEDULE_TOUR) {
        closeDialogModalById(LEAD_FORM_MODAL_ID)
        closeDialogModalById(REQUEST_TOUR_MODAL_ID)

        showDialogModalById(TOUR_WIZARD_MODAL_ID)
        setTourWizardModalAtom({
          listing: activeListing,
        })
      } else {
        submitLead()
      }
    }
  }

  function onNonInterestedClick(event: React.MouseEvent) {
    event.stopPropagation()
    window.eventTracker?.track('click', {
      item: `not_interested_${allViewedListingIds.length + 1}`,
      section: dataTagSection,
    })
    viewNextListing()
  }

  switch (activeScreen) {
    case 'ad':
      return (
        <ThankYouAdStep
          initiatedLeadListing={initiatedLeadListing}
          noThankYouListings={noThankYouListings}
          onSuccessfulAdImpression={() => {
            setActiveScreen(ScreenStates.Listing)
          }}
          onClose={onClose}
        />
      )
    case 'phone':
      return (
        <ThankYouRedesignPhone
          onSkipClick={viewNextListing}
          isLastListing={isLastListing}
          submitLead={submitLead}
        />
      )
    case 'listing':
      return (
        <>
          <ConfirmationMessage
            allViewedListingIds={allViewedListingIds}
            listingIdsShown={listingIdsShown}
            listings={listings}
            customMessage={isFirstListing ? initialCustomMessage : undefined}
            wasTourLead={wasTourLead}
          />
          <ThankYouRedesignStackedCards
            listings={listings}
            viewedListingIds={allViewedListingIds}
            leadSubmittedListingIds={listingIdsShown.submittedIds}
            notInterestedListingIds={listingIdsShown.notInterestedIds}
            allViewedListingIds={allViewedListingIds}
          />
          <div className={styles.buttonDetailsWrapper}>
            <div className={styles.ctaButtons}>
              <button
                data-tid="cta-not-interested"
                className={styles.notInterested}
                onClick={onNonInterestedClick}
              >
                <div className={styles.iconWrapper}>
                  <CloseIcon className={styles.notInterestedIcon} />
                </div>
                <span
                  data-tid="cta-text-not-interested"
                  className={styles.ctaLabel}
                >
                  Not Interested
                </span>
              </button>
              <button
                data-tid="cta-interested"
                className={styles.interested}
                onClick={onInterestedClick}
                data-tag_item={dataTagBtn[leadType]}
                data-tag_listing_id={activeListing.id}
              >
                <div className={styles.iconWrapper}>
                  <EmailIcon className={styles.interestedIcon} />
                </div>
                <span
                  data-tid="cta-text-interested"
                  className={styles.ctaLabel}
                >
                  {leadTypeMap[leadType]}
                </span>
              </button>
            </div>
          </div>
        </>
      )
    // we could specify a case for 'final' here, but it's also the default case
    default:
      return (
        <ThankYouRedesignFinalScreen
          initiatedLeadListing={initiatedLeadListing}
          onClose={onClose}
        >
          <ConfirmationMessage
            allViewedListingIds={allViewedListingIds}
            listingIdsShown={listingIdsShown}
            listings={listings}
            wasTourLead={wasTourLead}
            customMessage={isFirstListing ? initialCustomMessage : undefined}
            isFinalScreen
          />
        </ThankYouRedesignFinalScreen>
      )
  }
}
