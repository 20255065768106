import clsx from 'clsx'
import React from 'react'
import type { AvailableDatesFields } from '../tour-wizard.types'

import { useTourWizardConfig } from '../wizard/hooks/use-tour-wizard-config'
import { getNavDay } from './utils/get-nav-day'

import type { FC } from 'react'
import { ReactComponent as ChevronLeftIcon } from '@brand/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from '@brand/icons/chevron-right.svg'

export type TourTimesNavProps = {
  availableDates: (AvailableDatesFields | null)[] | undefined | null
  onBack: () => void
  onNext: () => void
  showBack: boolean
  showNext: boolean
}

export const TourTimesNav: FC<TourTimesNavProps> = ({
  availableDates = [],
  onBack,
  onNext,
  showBack,
  showNext,
}) => {
  const { theme } = useTourWizardConfig()

  return (
    <nav aria-label="pagination" className={theme?.tourTimesStep?.nav}>
      {showBack && (
        <button
          type="button"
          className={theme?.tourTimesStep?.navBackButton}
          data-tid="tour-time-picker-prev-button"
          onClick={onBack}
        >
          <ChevronLeftIcon />
          <span className="sr-only">Previous Date Range</span>
        </button>
      )}

      {showNext && (
        <button
          type="button"
          className={theme?.tourTimesStep?.navNextButton}
          onClick={onNext}
          data-tid="tour-time-picker-next-button"
        >
          <ChevronRightIcon />
          <span className="sr-only">Next Date Range</span>
        </button>
      )}
      <ul
        className={clsx(
          theme?.tourTimesStep?.dateGrid,
          availableDates?.length === 1
            ? theme?.tourTimesStep?.dateGridWithOne
            : theme?.tourTimesStep?.dateGridWithMany
        )}
        data-tid="tour-times-nav-dates"
      >
        {(availableDates || []).map((item, i) => {
          if (!item) {
            return null
          }
          const availableDate = item.date

          if (!availableDate) {
            return null
          }

          const { day, name } = getNavDay(availableDate)

          return (
            <li
              key={i}
              data-tid="tour-time-picker-date"
              className={theme?.tourTimesStep?.day}
            >
              <span
                className={theme?.tourTimesStep?.dayName}
                data-tid="day-name"
              >
                {name}
              </span>{' '}
              <span
                className={theme?.tourTimesStep?.dayNumber}
                data-tid="day-number"
              >
                {day}
              </span>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
