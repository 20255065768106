/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type UserAddEmailedListingMutationVariables = Types.Exact<{
  listingId: Types.Scalars['ID'];
  zutronId: Types.Scalars['ID'];
}>;


export type UserAddEmailedListingMutation = { userAddEmailedListing: { message?: string | null, meta?: Record<string, unknown> | null, success: boolean } };


export const UserAddEmailedListingDocument = `
    mutation UserAddEmailedListing($listingId: ID!, $zutronId: ID!) {
  userAddEmailedListing(listingId: $listingId, zutronId: $zutronId) {
    message
    meta
    success
  }
}
    `;

    export function createUserAddEmailedListingFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchUserAddEmailedListing(variables: UserAddEmailedListingMutationVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<UserAddEmailedListingMutation, UserAddEmailedListingMutationVariables>(UserAddEmailedListingDocument, variables, headers);
      }
    }

