import { PropertyType } from '../../../__generated__/api-types'

export type PropertyTypeArg = PropertyType | string | undefined | null

export const getPropertyTypeMap = (
  propertyType: PropertyTypeArg
): { lower: string; title: string } => {
  const uppercasePropertyType = (propertyType || '').toUpperCase()

  switch (uppercasePropertyType) {
    case PropertyType.Apartments:
    case 'APARTMENT':
      return {
        lower: 'apartments',
        title: 'Apartments',
      }
    case PropertyType.Townhome:
      return {
        lower: 'townhomes',
        title: 'Townhomes',
      }
    case PropertyType.Condo:
      return {
        lower: 'condos',
        title: 'Condos',
      }
    case PropertyType.House:
    case PropertyType.Duplex:
    case PropertyType.Triplex:
      return {
        lower: 'houses',
        title: 'Houses',
      }
    default:
      return {
        lower: 'apartments',
        title: 'Apartments',
      }
  }
}
