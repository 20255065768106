import { forwardRef } from 'react'
import type { InputProps } from '../input/input'
import { Input } from '../input/input'

export const MessageInput = forwardRef<
  HTMLInputElement,
  Omit<InputProps, 'label'> & { label?: InputProps['label'] }
>((props, ref) => <Input as="textarea" label="Message" {...props} ref={ref} />)

MessageInput.displayName = 'MessageInput'
