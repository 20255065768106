/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type ScheduleTourMutationVariables = Types.Exact<{
  input: Types.ScheduleTourInput;
}>;


export type ScheduleTourMutation = { tourSchedule?: { success: boolean, message?: string | null, meta?: Record<string, unknown> | null } | null };


export const ScheduleTourDocument = `
    mutation ScheduleTour($input: ScheduleTourInput!) {
  tourSchedule(input: $input) {
    success
    message
    meta
  }
}
    `;

    export function createScheduleTourFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchScheduleTour(variables: ScheduleTourMutationVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<ScheduleTourMutation, ScheduleTourMutationVariables>(ScheduleTourDocument, variables, headers);
      }
    }

