import { useAtom } from 'jotai'
import { useCallback, useEffect } from 'react'
import { fetchTourTimesAtom } from '../../tour-wizard.store'

import { useTourWizardConfig } from '../../wizard/hooks/use-tour-wizard-config'
import { useAsyncTourTimesVariables } from './use-async-tour-times-variables'

export function useAsyncTourTimes() {
  const tourWizardConfig = useTourWizardConfig()
  const variables = useAsyncTourTimesVariables(tourWizardConfig)
  const [data, fetchTourTimes] = useAtom(fetchTourTimesAtom)

  const fetchTimes = useCallback(() => {
    if (variables) {
      fetchTourTimes({ variables, zid: tourWizardConfig.userId })
    }
  }, [variables, fetchTourTimes, tourWizardConfig.userId])

  useEffect(() => {
    fetchTimes()
  }, [fetchTimes])

  return data
}
