import { LeadDevice } from '../../../__generated__/api-types'

/** Min width for desktop. */
const DESKTOP_WIDTH = 1024

function isDesktop(width: number) {
  return width >= DESKTOP_WIDTH
}

/**
 * Based on the current window size, generate the device type used for the
 * leads service.
 */
export function getLeadDeviceType() {
  const width = window.innerWidth

  return isDesktop(width) ? LeadDevice.Desktop : LeadDevice.Mobile
}
