import { formatMilitaryTime, parseUnixTimeStamp } from '@rentpath/web-utils'
import clsx from 'clsx'
import type { ChangeEvent, FC } from 'react'
import React from 'react'
import type { AvailableDatesFields } from '../tour-wizard.types'
import { useTourWizardConfig } from '../wizard/hooks/use-tour-wizard-config'
import { TourTimesInput } from './tour-times-input'
import { getTourTimesInputLabel } from './utils/get-tour-times-input-label'

export type TourTimesListProps = {
  availableDates: (AvailableDatesFields | null)[] | undefined | null
  timeSlotsTemplate: string[]
  onTimeSelect: (timeSelection: string) => void
  timeSelection: string
}

export const TourTimesList: FC<TourTimesListProps> = ({
  availableDates = [],
  timeSlotsTemplate,
  onTimeSelect,
  timeSelection,
}) => {
  const { theme } = useTourWizardConfig()
  const showSingleColumn = availableDates?.length === 1

  function handleTimeSelection(e: ChangeEvent<HTMLInputElement>) {
    onTimeSelect(e.target.value)
  }

  return (
    <div
      className={clsx(
        theme?.tourTimesStep?.dateGrid,
        showSingleColumn
          ? theme?.tourTimesStep?.dateGridWithOne
          : theme?.tourTimesStep?.dateGridWithMany
      )}
      data-tid="tour-times-list"
    >
      {availableDates?.map((availableDate, i) =>
        availableDate ? (
          <ul
            key={i}
            className={clsx(
              theme?.tourTimesStep?.timeList,
              showSingleColumn && theme?.tourTimesStep?.timeListSingleTime
            )}
          >
            {timeSlotsTemplate.map((timeSlot, j) => {
              const availableTimeSlot =
                (availableDate.times || []).find(
                  (time) =>
                    formatMilitaryTime(parseUnixTimeStamp(time || '')) ===
                    timeSlot
                ) || undefined

              const id = `time-selection-${i}-${j}`

              const disabled = typeof availableTimeSlot === 'undefined'

              return (
                <li key={id} className={theme?.tourTimesStep?.timeListItem}>
                  <TourTimesInput
                    checked={timeSelection === availableTimeSlot}
                    disabled={disabled}
                    id={id}
                    label={timeSlot}
                    aria-hidden={disabled}
                    aria-label={getTourTimesInputLabel({
                      timeSlot,
                      date: availableDate.date,
                    })}
                    onChange={handleTimeSelection}
                    required
                    value={availableTimeSlot}
                  />
                </li>
              )
            })}
          </ul>
        ) : null
      )}
    </div>
  )
}
