/* eslint-disable sort-keys */
export const defaultTheme = {
  container: 'flex flex-col relative h-full overflow-hidden text-gray-500',
  steps: 'flex h-full w-full flex-col overflow-hidden',
  stepHeading: 'text-headline-color text-2xl font-semibold',
  stepSubHeading: 'mt-12',
  header: 'mb-13 z-10 flex w-full bg-white md:mb-0',
  headerShowBack: 'justify-between',
  headerHideBack: 'justify-end',
  headerShowClose: 'h-60 py-20 px-24',
  headerHideClose: '',
  tourScheduledTime: 'font-bold',
  headerButton: 'font-semibold text-link hover-light hover:no-underline',
  headerSelectTimeIcon: 'relative -top-2 mr-5 text-xs',
  stepContainer: 'relative flex h-full flex-col',
  backButton: 'secondary-cta w-full before:icon-arrow-left before:pr-5',
  nextButton:
    'primary-cta mx-auto w-full disabled:bg-gray-300 after:icon-arrow-right after:pl-5',
  scheduleTourButton: 'primary-cta mx-auto w-full disabled:bg-gray-300',
  footerContainer: 'z-10 flex-grow-0 shadow-100-up',
  footerContainerInner: 'py-16 px-24 sm:mx-auto sm:w-4/5 sm:p-20',
  contactForm: {
    base: 'sm:flex sm:flex-wrap bg-white mb-20',
    nameGroup: 'flex w-full bg-white',
    firstName: 'bg-inherit w-1/2 sm:flex sm:flex-col pr-10',
    lastName: 'bg-inherit w-1/2 sm:flex sm:flex-col pl-10',
    moveInDate: 'bg-inherit w-full sm:w-1/2 sm:flex sm:flex-col sm:pr-10',
    email: 'bg-inherit w-full sm:w-1/2 sm:flex sm:flex-col sm:pl-10',
    phone: 'bg-inherit w-full sm:w-1/2 sm:flex sm:flex-col sm:pr-10',
  },
  addToCalendarButton: 'secondary-cta py-10',
  failureImage: 'max-w-200 mb-25',
  thankYouHeading:
    'text-header300 leading-header300 font-bold font-title text-brand-dark mb-25',
  thankYouImage: 'block mb-25 max-w-200',
  tourTimesStep: {
    container: 'px-20 sm:mx-auto sm:w-4/5 sm:px-0',
    navContainer: 'flex flex-col flex-1 overflow-y-hidden relative',
    scrollContainer: 'flex-1 overflow-y-auto',
    nav: 'relative flex-grow-0 pt-24 pb-10',
    navBackButton:
      'absolute top-0 left-0 px-15 py-24 text-xl text-brand-primary before:icon-chevron-left mt-8',
    navNextButton:
      'absolute top-0 right-0 px-15 py-24 text-xl text-brand-primary before:icon-chevron-right mt-8',
    dateGrid:
      'flex flex-1 items-center justify-evenly overflow-hidden px-20 sm:mx-auto sm:w-4/5 sm:px-0',
    dateGridWithOne: 'sm:justify-center',
    dateGridWithMany: 'sm:justify-between',
    day: 'min-w-90 min-w-[90px] text-center',
    dayName: 'uppercase text-body block text-gray-400',
    dayNumber: 'font-bold text-header-100 text-header100',
    timeList: 'space-y-10',
    timeListItem: 'relative',
    timeListSingleTime: 'w-full',
    selection: {
      base: 'block text-center border rounded py-10 min-w-90 min-w-[90px] w-full',
      checked: 'bg-brand-primary text-white border-brand-primary',
      disabled: 'text-gray-300 cursor-not-allowed',
      unchecked: 'text-brand-primary border-gray-300 cursor-pointer',
      input: 'hidden',
    },
  },
  tourTypeHeading:
    'md:mt-25 mb-30 text-header100 text-lg font-bold font-title text-brand-dark',
  tourTypeInput: 'bg-white text-body text-black',
  tourTypeInputChecked: 'bg-brand-primary text-white',
  tourTypeInputDisabled: 'cursor-not-allowed filter grayscale text-gray-300',
  tourTypeInputHeading: 'text-cta-regular font-bold mb-3',
  tourTypeInputHeadingChecked: 'text-white',
  tourTypeInputHeadingUnchecked: 'text-brand-primary',
  tryAgainButton:
    'primary-cta inline-block mt-12 px-20 disabled:bg-gray-300 w-[150px]',
  tourTypesList: {
    wrapper: 'mt-24',
    list: 'p-2 bg-gray-100 flex items-center rounded',
    listItem: 'flex-1 relative',
    listItemSelected:
      'after:block after:absolute after:right-0 after:bottom-8 after:w-1 after:h-24 after:bg-gray-300',
    input: 'sr-only',
    label:
      'inline-flex w-full cursor-pointer p-10 min-h-90 relative min-h-[90px] rounded-button',
    heading: 'text-center w-full',
    headingDisabled: 'text-gray-300',
  },
  thankYouStep: {
    wrapper: 'items-center overflow-auto px-20 pt-75 pb-20 text-center',
    spacing: 'mx-30 mb-25',
    confirmationText: 'font-bold text-black',
    calendarButtons: 'px:20 w-full sm:mx-auto sm:w-300',
    calendarButtonsText: 'min-w-175',
    calendarButtonsGoogle: 'mb-10 md:mb-15',
    calendarButtonsApple: 'w-full',
    calendarIcon: 'mr-10 -mt-2',
  },
  contactStep: {
    wrapper: 'overflow-auto',
    base: 'p-24 sm:mx-auto sm:w-4/5 sm:px-0',
    subHeading: 'mt-24 text-headline-color text-lg font-semibold',
  },
  empty: {
    wrapper: 'flex h-full flex-col items-center justify-center text-center',
    button: 'primary-cta mt-40 inline-block',
  },
  overlay:
    'absolute top-0 left-0 z-20 flex h-full w-full flex-col items-center justify-center bg-white text-center',
  loadingText: 'mt-5',
}

export type TourWizardTheme = Partial<typeof defaultTheme>
