import { STANDARD_SIZE_ORDER } from './get-cloudinary-url'

import type { PhotoSize } from './get-cloudinary-url'

/**
 * Given an image size (like 'lg'), this function returns the next larger size
 * in the list (like 'xl').
 * - Used to determine which image size to use for high pixel density (HiDPI) /
 *   Retina displays.
 *
 * @param size
 * - One of the standard size values
 * @param maxSize
 * - Used to restrict the largest size used, for example if you never want to go
 *   over xl size on mobile, you can specify 'xl'. Then this function will
 *   return undefined if your image is aleady xl.
 * @returns Undefined if no next size should be used.
 *
 * @example
 * const size = 'xl'
 *
 * // For desktop get the next size to use for retina screens
 * const dpi2Desktop = getNextSize(size) // 'xxl'
 *
 * // For mobile, we might want to never exceed 'xl' size
 * const dpi2Mobile = getNextSize(size, 'xl') // undefined
 * const dpi2Mobile = getNextSize('md', 'xl') // 'lg'
 */
export function getNextSize(
  size: PhotoSize,
  maxSize?: PhotoSize
): PhotoSize | undefined {
  // Find the index of the start size
  const i = STANDARD_SIZE_ORDER.indexOf(size)

  // Should not happen, but if the size is not in the array return undefined
  if (i === -1) {
    return undefined
  }

  // If the next size would exceed the max, then return undefined
  if (maxSize) {
    const iMax = STANDARD_SIZE_ORDER.indexOf(maxSize)

    // Should not happen, but check if maxSize is not in the array
    if (iMax !== -1) {
      // Do not return next size if it would be greater than max
      if (i >= iMax) {
        return undefined
      }
    }
  }

  // Return the next larger size (or undefined)
  return STANDARD_SIZE_ORDER[i + 1] || undefined
}
