import type { LongDayName, MonthName } from '@rentpath/web-utils'
import { dayNames, monthNames } from '@rentpath/web-utils'
import type { dayTimesOptionsType } from './day-time-picker'
import type { LeadForm_ListingFragment } from '../../lead-form/__generated__/lead-form.gql'

export interface TourTime {
  tomorrow: boolean
  month: MonthName
  day: LongDayName
  shortDay: string
  dateOfMonth: number
  date: Date
  fullDateText: string
  shortDateText: string
  timeOfDay?: dayTimesOptionsType | string
  enabled: boolean
}

const DAY_OF_WEEK_MAP: Record<string, number> = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
}

const doesCommentAllowAppointments = (comment?: string | null) => {
  if (!comment) return false
  const normalizedComment = comment.toLowerCase()
  return (
    normalizedComment.includes('appointment') ||
    normalizedComment.includes('appt') ||
    normalizedComment.includes('open')
  )
}

function isOfficeHoursDayOpen(
  times: LeadForm_ListingFragment['officeHours'][0]['times']
) {
  return (
    times.length > 0 &&
    times.some(
      (time) =>
        (time.open && time.close) || doesCommentAllowAppointments(time.comment)
    )
  )
}

function getOfficeHoursMap(
  officeHours: LeadForm_ListingFragment['officeHours']
) {
  if (officeHours.every((curr) => !isOfficeHoursDayOpen(curr.times))) {
    return [false, true, true, true, true, true, true]
  }
  return officeHours.reduce((acc, curr) => {
    acc[DAY_OF_WEEK_MAP[curr.day]] = isOfficeHoursDayOpen(curr.times)
    return acc
  }, Array(7).fill(false))
}

export function getTourTimeDates(
  today: Date,
  daysToAdd: number,
  officeHours: LeadForm_ListingFragment['officeHours']
): TourTime[] {
  const dates: TourTime[] = []

  const isTimeBeforeNoon = today.getHours() < 12
  const amountOfDaysAheadToStart = isTimeBeforeNoon ? 1 : 2
  const officeHoursMap = getOfficeHoursMap(officeHours)

  for (let i = 0; i < daysToAdd; i++) {
    const tomorrowText = i === 0 && isTimeBeforeNoon ? 'Tomorrow, ' : ''
    const currentDate = new Date(today.valueOf())
    currentDate.setDate(currentDate.getDate() + amountOfDaysAheadToStart + i)

    const day = dayNames[currentDate.getDay()]

    const shortDay = day.slice(0, 3)

    dates.push({
      tomorrow: i === 0 && isTimeBeforeNoon,
      month: monthNames[currentDate.getMonth()],
      day,
      shortDay,
      enabled: officeHoursMap[currentDate.getDay()],
      dateOfMonth: currentDate.getDate(),
      date: currentDate,
      fullDateText: `${tomorrowText}${day}, ${
        monthNames[currentDate.getMonth()]
      } ${currentDate.getDate()}`,
      shortDateText: `${shortDay.toUpperCase()} ${
        currentDate.getMonth() + 1
      }/${currentDate.getDate()}`,
    })
  }

  return dates
}
