import { useRef, useState, useCallback } from 'react'
import type { ComponentProps } from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import type { Modal } from '../modals/modal'
import type { TaggingProps } from '../../types'
import { LeadForm } from './lead-form'
import { LeadFormSteps } from '../enhanced-lead-form/const'
import { LeadFormHeader } from './lead-form-header'
import { ThankYouContainer } from '../thank-you/thank-you-container'
import { getEventCategoryPageSignifier } from '../tagging/get-event-category-page-signifier'
import type { getLocationRelativeToSearchedCity } from '../search/utils/get-location-relative-to-searched-city'
import { EnhancedLeadFormHeader } from '../enhanced-lead-form/enhanced-lead-form-header'
import { DialogModal } from '../dialog-modal/dialog-modal'
import { DialogModalCloseButton } from '../dialog-modal/dialog-modal-close-button'

import styles from './lead-form-modal.module.css'
import thankYouStyles from '../thank-you/thank-you-modal.module.css'
import { CALENDAR_PORTAL_ID } from '../../components/calendar/calendar.const'
import { yieldOrContinue } from 'main-thread-scheduling'
import { useAtom } from 'jotai'
import { leadFormModalStateAtom } from '../cta-modals/cta-modals.atom'

export type LeadFormListing = ComponentProps<typeof LeadForm>['listing']

type LeadFormModalBodyProps = {
  listing: LeadFormListing
  currentRefinementSlugs?: string[]
  currentStep?: LeadFormSteps
  setCurrentStep?: (formStep: LeadFormSteps) => void
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
} & Pick<ComponentProps<typeof Modal>, 'onClose'> &
  TaggingProps

type LeadFormModalProps = {
  id?: string
}

export const LEAD_FORM_MODAL_ID = 'lead-form-modal'

/**
 * Forces a reflow for Android Chrome due to a bug where the user
 * won't be able to scroll after closing the modal
 */
function refreshScrollPosition() {
  const userAgent = navigator.userAgent.toLowerCase()
  if (!/android.*chrome|chrome.*android/.test(userAgent)) {
    return
  }

  // Store current scroll position
  const scrollX = window.scrollX
  const scrollY = window.scrollY

  // Force a reflow
  document.body.style.display = 'inline-block'
  void document.body.offsetHeight
  document.body.style.display = ''

  // Restore scroll position
  window.scrollTo(scrollX, scrollY)
}

function LeadFormModalBody(props: LeadFormModalBodyProps) {
  if (props.currentStep === LeadFormSteps.thankYou) {
    return (
      <ThankYouContainer
        listing={props.listing}
        onClose={props.onClose}
        heading="Your message has been sent."
      />
    )
  }

  return (
    <>
      {props.currentStep === LeadFormSteps.formFields ? (
        <DialogModalCloseButton
          onClick={props.onClose}
          data-tag_section="lead_form_modal"
        />
      ) : null}
      <div
        data-tag_section="lead_submission_form"
        data-tid="lead-submission-form"
        className={clsx({
          [styles.leadFormModalBody]:
            props.currentStep === LeadFormSteps.elfPrompts,
        })}
      >
        {props.currentStep === LeadFormSteps.formFields ? (
          <LeadFormHeader {...props} listing={props.listing} isModal />
        ) : (
          <EnhancedLeadFormHeader
            onClose={props.onClose}
            onStepChange={props.setCurrentStep}
            dataTagSection="lead_submission_form_e"
          />
        )}
        <>
          <LeadForm
            showInCalendarInPortal
            onValidSubmit={() => {
              props.setCurrentStep?.(LeadFormSteps.thankYou)
            }}
            listing={props.listing}
            onStepChange={props.setCurrentStep}
            currentStep={props.currentStep}
            currentRefinementSlugs={props.currentRefinementSlugs}
            locationRelativeToSearchedCity={
              props.locationRelativeToSearchedCity
            }
          />

          <div id={CALENDAR_PORTAL_ID}></div>
        </>
      </div>
    </>
  )
}

export function LeadFormModal(props: LeadFormModalProps) {
  const leadFormDialogRef = useRef<HTMLDialogElement>(null)
  const [currentStep, setCurrentStep] = useState<LeadFormSteps>(
    LeadFormSteps.formFields
  )

  const [{ listing, currentRefinementSlugs }, setLeadFormModalState] = useAtom(
    leadFormModalStateAtom
  )

  const { pathname } = useRouter()

  const handleClose = useCallback(async function handleClose() {
    await yieldOrContinue('smooth')
    leadFormDialogRef.current?.close()
    await yieldOrContinue('interactive')
  }, [])

  const onAfterModalClose = useCallback(
    async function onAfterModalClose() {
      await yieldOrContinue('smooth')
      refreshScrollPosition()
      await yieldOrContinue('interactive')
      setCurrentStep(LeadFormSteps.formFields)
      setLeadFormModalState({ listing: null })
    },
    [setCurrentStep, setLeadFormModalState]
  )

  const similarPropsDataTagSection = `similar_properties${getEventCategoryPageSignifier(
    pathname
  )}lead`

  const modalClass = clsx(styles.leadFormModal, {
    [styles.leadFormModalPanel]: currentStep === LeadFormSteps.formFields,
    [styles.enhancedLeadFormModalPanel]:
      currentStep === LeadFormSteps.elfPrompts,
    [styles.leadFormModalBody]: currentStep === LeadFormSteps.elfPrompts,
    [thankYouStyles.thankYouModal]: currentStep === LeadFormSteps.thankYou,
  })

  const dataTid = clsx({
    ['lead-form-modal']: currentStep === LeadFormSteps.formFields,
    ['enhanced-questions-modal']: currentStep === LeadFormSteps.elfPrompts,
    ['thank-you-modal']: currentStep === LeadFormSteps.thankYou,
  })

  const dataTagSection = clsx({
    ['lead_form_modal']: currentStep === LeadFormSteps.formFields,
    [similarPropsDataTagSection]: currentStep === LeadFormSteps.thankYou,
  })

  return (
    <DialogModal
      className={modalClass}
      id={LEAD_FORM_MODAL_ID}
      data-tid={dataTid}
      data-tag_section={dataTagSection}
      ref={leadFormDialogRef}
      onAfterClose={onAfterModalClose}
      data-dialog-modal
      entranceAnimation="grow"
    >
      {listing !== null && (
        <LeadFormModalBody
          {...props}
          listing={listing}
          currentRefinementSlugs={currentRefinementSlugs}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          onClose={handleClose}
        />
      )}
    </DialogModal>
  )
}
