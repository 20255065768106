import { getMonthName } from '@rentpath/web-utils'
import { getNavDay } from './get-nav-day'

type GetTourTimesInputType = {
  timeSlot: string
  date?: string | null
}

export const getTourTimesInputLabel = ({
  timeSlot,
  date,
}: GetTourTimesInputType) => {
  if (!date) return timeSlot

  const [_year, month, day] = date.split('-') || []

  if (!month || !day) return timeSlot

  const dayOfWeek = getNavDay(date, false).name
  const monthName = getMonthName(parseInt(month) - 1)

  return `${timeSlot} ${
    dayOfWeek !== 'Today' ? 'on ' : ''
  }${dayOfWeek}, ${monthName} ${parseInt(day)}`
}
