/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type PetPolicyFieldsFragment = { additionalRent?: number | null, comment?: string | null, deposit?: number | null, id?: Types.PetPolicyIds | null, initialFee?: string | null, label?: string | null, maximumPets?: number | null, weightRestriction?: number | null };

export type PetPolicies_ListingFragment = { petPolicies: Array<{ additionalRent?: number | null, comment?: string | null, deposit?: number | null, id?: Types.PetPolicyIds | null, initialFee?: string | null, label?: string | null, maximumPets?: number | null, weightRestriction?: number | null }> };

export const PetPolicyFieldsFragmentDoc = `
    fragment PetPolicyFields on PetPolicy {
  additionalRent
  comment
  deposit
  id
  initialFee
  label
  maximumPets
  weightRestriction
}
    `;
export const PetPolicies_ListingFragmentDoc = `
    fragment PetPolicies_ListingFragment on Listing {
  petPolicies {
    ...PetPolicyFields
  }
}
    `;



