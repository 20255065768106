/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { TelephonyFields_ListingFragmentDoc, CtaButtons_ListingFragmentDoc, CtaButtons_TourProviderDetailsFragmentDoc, CtaButtons_LocationFragmentDoc } from '../../cta-buttons/__generated__/cta-buttons.gql';
export type ListingPhone_ListingFragment = { id: string, isActive: boolean, isBasic: boolean, phoneMobileSem?: string | null, phoneMobile?: string | null, phoneDesktop?: string | null, phoneDesktopText?: string | null, phoneDesktopSem?: string | null, phoneDesktopSemText?: string | null, phoneMobileText?: string | null, phoneMobileSemText?: string | null, revenue?: number | null, zScore?: number | null, NV_RPL?: number | null, mitsPhone: { raw?: string | null, formatted?: string | null }, telephony: { isSwapPhoneEligible: boolean, webNumber: { raw?: string | null, formatted?: string | null }, semNumber: { raw?: string | null, formatted?: string | null } } };

export const ListingPhone_ListingFragmentDoc = `
    fragment ListingPhone_ListingFragment on Listing {
  id
  isActive
  isBasic
  phoneMobileSem
  phoneMobile
  phoneDesktop
  phoneDesktopText
  phoneDesktopSem
  phoneDesktopSemText
  phoneMobileText
  phoneMobileSemText
  revenue
  zScore
  NV_RPL
  mitsPhone {
    raw
    formatted
  }
  ...TelephonyFields_ListingFragment
}
    `;



