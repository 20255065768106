import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { isAvailable } from '../utils/is-available'
import type { ListingAvailabilityStatus } from '../../../__generated__/api-types'

type BasicAvailableListing = {
  isBasic?: boolean
  availabilityStatus?: ListingAvailabilityStatus
}

export function useIsEmailCtaBasicAvailableListing(
  listing?: BasicAvailableListing | null
) {
  const emailCtaOnNonPayingProps =
    useFeatureVariable<number>(
      ['email_cta_on_nonpaying_props', 'version'],
      0
    ) === 1

  return Boolean(
    listing &&
      emailCtaOnNonPayingProps &&
      listing.isBasic &&
      listing.availabilityStatus &&
      isAvailable(listing.availabilityStatus)
  )
}
