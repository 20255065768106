/**
 * Determine if running on an iPad device.
 * - Note on the server this will always return false.
 * - Must run on the client to determine if iPad.
 *
 * TODO:
 * Negotiate with product and design to figure out a
 * solution that doesnt involve this sort of hack.
 */
function getIsIpad() {
  if (typeof window !== 'undefined') {
    const { navigator } = window
    return (
      typeof navigator !== 'undefined' &&
      /Mac/.exec(navigator.userAgent) !== null &&
      navigator?.maxTouchPoints > 2
    )
  }

  return false
}

export function getShouldShowNativeDatePicker(isMobile: boolean) {
  return isMobile || getIsIpad()
}
