import { getLeadMessagePlaceholder } from './get-lead-message-placeholder'
import type { LeadData } from '../lead-form'
import { buildLeadQuestionsMessage } from '../../enhanced-lead-form/build-lead-questions-message'
import type { LeadForm_ListingFragment } from '../__generated__/lead-form.gql'

export function getMessageWithIntent(
  leadForm: LeadData,
  listing: {
    name?: string | null
    isBasic: boolean
    leadQuestions?: LeadForm_ListingFragment['leadQuestions']
  },
  questions?: Record<string, string[]>,
  showPaidLeadMessage?: boolean
) {
  const { message, requestATour, submittingApplication } = leadForm
  const defaultMessage = getLeadMessagePlaceholder(listing, showPaidLeadMessage)

  const intentMessages: string[] = []
  if (submittingApplication) {
    intentMessages.push('- Online Application\n')
  }
  if (requestATour) {
    intentMessages.push('- Taking a Tour\n')
  }

  const leadQuestions = buildLeadQuestionsMessage({
    questions,
    leadQuestionIds: listing.leadQuestions?.ids,
  })
  // If no intent options were selected, just use the message field
  let resultMessage: string

  if (!intentMessages.length) {
    resultMessage = `${leadQuestions}${message}`
  } else {
    // Avoid sending the placeholder text if the user didn't type a custom message
    const userMessage = message === defaultMessage ? '' : message

    // Create customized message, then append the user's message.
    resultMessage = [
      `Hello, can you tell me how I can do the following at ${listing.name}?\n`,
      ...intentMessages,
      leadQuestions,
      userMessage,
    ]
      .filter(Boolean)
      .join('')
  }

  return resultMessage
}
