import { getDateDelta, utcDateToDateString } from '@rentpath/web-utils'
import { initialMaxDays } from '../hooks/use-responsive-days-to-show'

/**
 * getDateRange()
 *
 * @example
 * getDateRange('Tue May 11 2021', 4)
 * // returns ['2021-05-11', '2021-05-12', '2021-05-13', '2021-05-14']
 *
 * @param startDate
 * @param numberOfDays
 *
 * @returns `string[]`  array of date strings using format `YYYY-MM-DD`
 */
export function getDateRange(
  startDate: string,
  numberOfDays = initialMaxDays
): string[] {
  const tourStartDate = utcDateToDateString(new Date(startDate))

  const tourDateRange = [tourStartDate]

  for (let i = 0; i < numberOfDays - 1; i += 1) {
    tourDateRange.push(getDateDelta(tourStartDate, i + 1))
  }

  return tourDateRange
}
