/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type AdTarget_ListingFragment = { adTargetCode?: string | null, propertyType?: Types.PropertyType | null, adPartners: Array<{ name: string }> };

export const AdTarget_ListingFragmentDoc = `
    fragment AdTarget_ListingFragment on Listing {
  adTargetCode
  adPartners {
    name
  }
  propertyType
}
    `;



