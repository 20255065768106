import { atomWithStorage } from 'jotai/utils'
import Cookies from 'js-cookie'
import type { CookieAttributes } from 'js-cookie'

export interface OneClickCookieData {
  email: string
  name: string
  phone: string
  moveDate: string
  applicationOptIn: boolean
  tourRequest: boolean
  mostRecentLeadTypeSubmitted: string
  message?: string
  dateTime?: string
  requestedTourDate?: string
}

export const defaultOneClickLeadData = {
  email: '',
  name: '',
  phone: '',
  moveDate: '',
  applicationOptIn: false,
  tourRequest: false,
  mostRecentLeadTypeSubmitted: '',
  requestedTourDate: '',
  message: '',
}

const oneClickCookieOptions: CookieAttributes = { path: '/', expires: 1 }

export const oneClickCookieAtom = atomWithStorage<OneClickCookieData>(
  'oneClick',
  defaultOneClickLeadData,
  {
    getItem: (key: string) => {
      const val = Cookies.get(key)

      return val ? JSON.parse(val) : defaultOneClickLeadData
    },
    setItem: (key: string, leadCookieData: OneClickCookieData) => {
      // store in an odd format to conform to older apps while we transition
      const val = JSON.stringify(leadCookieData)
      Cookies.set(key, val, oneClickCookieOptions)

      return val
    },
    removeItem: (key: string) => {
      Cookies.remove(key, oneClickCookieOptions)
    },
  }
)
