import { useAtomValue } from 'jotai'
import React from 'react'

import { CONTACT_STEP, TOUR_TIMES_STEP } from '../tour-wizard.config'
import { ContactFormStep } from '../contact-form/contact-form-step'
import { TourTimesStep } from '../tour-times/tour-times-step'
import { currentStepAtom } from '../tour-wizard.store'

import type { FC } from 'react'

export const TourWizardSteps: FC = () => {
  const currentStep = useAtomValue(currentStepAtom)

  if (currentStep === TOUR_TIMES_STEP) {
    return <TourTimesStep />
  }

  if (currentStep === CONTACT_STEP) {
    return <ContactFormStep />
  }

  return null
}
