import type { AdTargetData } from './get-ads-targets'
import { getAdsTargets } from './get-ads-targets'
import type { AdTarget_ListingFragment } from './__generated__/ad-targets.gql'

export function getListingAdTargets(listing?: AdTarget_ListingFragment | null) {
  if (!listing) {
    return []
  }

  const adTargetsData: AdTargetData = {
    targetCode: listing.adTargetCode,
    advertiser: listing.adPartners[0]?.name,
    propertyType: listing.propertyType,
  }

  return getAdsTargets(adTargetsData)
}
