/* eslint-disable react/jsx-no-target-blank */

import { Anchor } from '../../components/anchor/anchor'
import { pmcIdList } from '@brand/config/pmc-id-list'
import { ReactComponent as NewTabIcon } from '@brand/icons/new-tab.svg'
import styles from './property-website-link-footer.module.css'
import type { LeadForm_ListingFragment } from './__generated__/lead-form.gql'
import { urlForWebsiteLink } from '../detail/about-property/utils/website-utils'

interface PropertyWebsiteLinkFooterProps {
  listing: LeadForm_ListingFragment
}
/**
 * Displays WebsiteURLFooter (External Website Link) for
 * property management companies with company Ids listed in pmcIdList.
 */
export function getShouldShowWebsiteLinkFooter(mgmtId: string) {
  return pmcIdList[mgmtId] || false
}

export function PropertyWebsiteLinkFooter({
  listing,
}: PropertyWebsiteLinkFooterProps) {
  const isPermittedPMC =
    typeof listing.propertyManagementCompany?.id === 'string' &&
    getShouldShowWebsiteLinkFooter(listing.propertyManagementCompany?.id)

  const isDiamondMaxOrDiamondTier =
    Boolean(listing.listingTier?.match('Diamond')) || listing.isDiamondMax

  const showPropertyWebsiteLink = isPermittedPMC || isDiamondMaxOrDiamondTier

  if (!listing?.website || !showPropertyWebsiteLink) {
    return null
  }

  // we're appending a `utm_source` search param here so
  // we need to properly build a new url to avoid building a bad url
  // like "https://example.com?color=red?utm_source=brand.com"
  const url = urlForWebsiteLink(listing.website)

  if (!url) {
    return null
  }

  return (
    <div className={styles.linkFooterPropertyWebsite}>
      <Anchor
        href={url.toString()}
        data-tag_item="property_website"
        target="_blank"
        // We are removing the noreferrer and ignoring the norefferrer errors (top of the file), because we need to pass the referrer to the property websites
        // Major clients measure traffic to their website using the referrer. UDR is one such example.
        rel="nofollow noopener"
      >
        Visit Property Website
        <NewTabIcon className={styles.linkFooterCompanyLinkIcon} />
      </Anchor>
    </div>
  )
}
