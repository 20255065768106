import { useMemo, useState } from 'react'
import clsx from 'clsx'
import { LoadingProperties } from '../loading-properties/loading-properties'
import formStyles from '../thank-you-redesign-listing-cards/thank-you-redesign-stacked-cards-wrapper.module.css'
import styles from './thank-you-ad-step.module.css'
import { FinalStepButton } from '../final-step-button/final-step-button'
import type { ThankYou_ListingFragment } from '../../__generated__/thank-you.gql'
import { LazyAdSlot } from '../../../ads/lazy-ad-slot'
import { getListingAdTargets } from '../../../ads/get-listing-ad-targets'
import { AdSlot as AditudeAdSlot } from '../../../ads/aditude/ad-slot'
import { useAditudeEnabled } from '../../../ads/aditude/use-aditude-enabled'
import { THANK_YOU_AD_SLOTS } from '@brand/features/ads/aditude-ad-slots.const'
import { includeUnpaidPdpAds } from '../../../ads/include-unpaid-pdp-ads'
import { getAdvertiserTargets } from '../../../ads/get-advertiser-targets'

const PROGRESS_DURATION_V2 = 2.5

type ThankYouAdStepProps = {
  onSuccessfulAdImpression?: () => void
  noThankYouListings: boolean
  onClose?: () => void
  initiatedLeadListing: ThankYou_ListingFragment
}

export function ThankYouAdStep({
  initiatedLeadListing,
  noThankYouListings,
  onClose,
  onSuccessfulAdImpression,
}: ThankYouAdStepProps) {
  const [isAdLoaded, setAdLoaded] = useState<boolean>(false)
  const aditudeEnabled = useAditudeEnabled()

  const adLoadingHandler = (event: googletag.events.SlotOnloadEvent) => {
    const slotPath = event.slot.getTargeting('custom_path')

    if (slotPath?.length && slotPath[0] === 'thankyoucard') {
      setAdLoaded(true)
    }
  }

  const adComponent = useMemo(
    () =>
      aditudeEnabled ? (
        <AditudeAdSlot
          config={
            includeUnpaidPdpAds(
              initiatedLeadListing.tplsource,
              initiatedLeadListing.offMarket
            )
              ? THANK_YOU_AD_SLOTS.UNPAID_AD
              : THANK_YOU_AD_SLOTS.PAID_AD
          }
          targeting={getAdvertiserTargets(initiatedLeadListing)}
        />
      ) : (
        <LazyAdSlot
          divId="thank-you"
          type="med_rect_atf"
          adTargets={getListingAdTargets(initiatedLeadListing)}
          adsPath="thankyoucard"
          clientTag="thank_you_v2"
          tagNumber={5}
          onAdLoad={adLoadingHandler}
        />
      ),
    [aditudeEnabled, initiatedLeadListing]
  )

  return (
    <div
      className={clsx(
        formStyles.modalWrapper,
        styles.adLoadingWrapper,
        noThankYouListings ? styles.loadingV3 : styles.loadingV2
      )}
    >
      <div
        className={clsx(
          styles.ad,
          noThankYouListings ? styles.adV3 : styles.adV2
        )}
      >
        {adComponent}
      </div>
      {noThankYouListings ? (
        <FinalStepButton
          initiatedLeadListing={initiatedLeadListing}
          onClose={onClose}
          withIcon
        />
      ) : (
        isAdLoaded && (
          <LoadingProperties
            title="Gathering Similar Properties"
            progressLoadingDuration={PROGRESS_DURATION_V2}
            onClickViewProperties={onSuccessfulAdImpression}
            onLoadingEnded={onSuccessfulAdImpression}
          />
        )
      )}
    </div>
  )
}
