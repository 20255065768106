import React, { createContext, useMemo } from 'react'

import { DEFAULT_TOUR_TYPE_DETAILS, TYPES_ORDER } from '../tour-wizard.config'
import { defaultTheme } from '../tour-wizard.default-theme'

import type { FC } from 'react'

import type { TourWizardConfig } from '../tour-wizard.types'
import type { TourType } from '__generated__/api-types'

export const TourWizardConfigContext = createContext<TourWizardConfig>(
  {} as TourWizardConfig
)

export const TourWizardConfigProvider: FC<{
  tourWizardConfig: TourWizardConfig
  children: React.ReactNode
}> = ({ children, tourWizardConfig }) => {
  const tourTypes = tourWizardConfig.tourTypes

  const orderedTourTypes = useMemo(() => {
    const enabled: TourType[] = []
    const disabled: TourType[] = []
    TYPES_ORDER.forEach((type) => {
      if (tourTypes[type]) {
        enabled.push(type)
      } else if (!tourTypes[type]) {
        disabled.push(type)
      }
    })
    return [enabled, disabled]
  }, [tourTypes])

  const tourProvider = tourWizardConfig.tourProvider.toLowerCase()

  const isUDR = tourProvider === 'udr'
  const smsOptIn = !!tourWizardConfig?.tourProviderDetails?.smsOptIn
  const preferredMethod = isUDR ? 'sms' : 'email'

  return (
    <TourWizardConfigContext.Provider
      value={{
        tourTypeDetails: DEFAULT_TOUR_TYPE_DETAILS,
        ...tourWizardConfig,
        orderedTourTypes,
        preferredMethod,
        smsOptIn,
        // TODO: consider deep merge over replacement
        theme: tourWizardConfig.theme ? tourWizardConfig.theme : defaultTheme,
        tourTypes,
      }}
    >
      {children}
    </TourWizardConfigContext.Provider>
  )
}
