import React from 'react'

import { ReactComponent as EmailIcon } from '@brand/icons/email.svg'
import { useTourWizardConfig } from './hooks/use-tour-wizard-config'
import { TourWizardOverlay } from './tour-wizard-overlay'

import type { FC } from 'react'

import styles from './tour-wizard-error.module.css'
import { CTAButtons } from '../../../features/cta-buttons/cta-buttons'
import { ListingAvailabilityStatus } from '../../../__generated__/api-types'
import { useFeatureVariable } from '@rentpath/ab-testing-react'

export const TourWizardError: FC<{
  message: string
  header?: string
}> = (props) => {
  const { failureImage, theme, listing } = useTourWizardConfig()
  const dynamicTourRequestEnabled =
    useFeatureVariable<number>(['dynamic_tour_requests', 'version'], 0) > 0

  // in the event that a user sees this error _and_ the listing in question has limited availability, show "send an email" instead
  const primaryCta =
    dynamicTourRequestEnabled &&
    listing.availabilityStatus === ListingAvailabilityStatus.Limited ? (
      <CTAButtons.EmailCTA
        data-tid="cta-email-property-lead"
        fluid
        variant="outlined"
      />
    ) : (
      <CTAButtons.RequestTourCTA
        data-tid="cta-schedule-a-tour"
        fluid
        variant="primary"
        label="Send a Tour Request"
        icon={<EmailIcon aria-hidden />}
      />
    )

  return (
    <TourWizardOverlay data-tid="tour-error">
      <h1 className={styles.errorPageHeading}>{props.header || 'Oops!'}</h1>
      {failureImage && (
        <img
          src={failureImage}
          className={theme?.failureImage}
          alt=""
          role="presentation"
        />
      )}
      <div id="tour-error-message" className={styles.errorMessage}>
        <p> We were unable to book your tour.</p>
        <p>Please contact the property a different way to confirm your tour.</p>
      </div>
      <CTAButtons listing={listing} className={styles.ctaButtons}>
        {primaryCta}
        <CTAButtons.PhoneCTA
          {...{
            variant: 'primary',
            fluid: true,
          }}
        />
      </CTAButtons>
    </TourWizardOverlay>
  )
}
