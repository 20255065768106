import { useFeatureVariable } from '@rentpath/ab-testing-react'

import { useRequestData } from '../../features/request-data/pages-router/use-request-data'

export function usePhotoOrderExperiment() {
  const { optimizedPhotosSessionId } = useRequestData()

  const withOptimizedPhotos =
    useFeatureVariable<number>(['photo_order_test', 'version'], 0) === 1 &&
    optimizedPhotosSessionId

  return {
    withOptimizedPhotos: Boolean(withOptimizedPhotos),
    optimizedPhotosSessionId,
  }
}
