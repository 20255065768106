import React from 'react'
import type { ReactNode } from 'react'
import { useTourWizardConfig } from './hooks/use-tour-wizard-config'

interface StepHeadingProps {
  title: string
  subtitle?: string | ReactNode
}

export function TourWizardStepHeading({ title, subtitle }: StepHeadingProps) {
  const { theme } = useTourWizardConfig()

  return (
    <div>
      <h1 className={theme?.stepHeading}>{title}</h1>
      {subtitle && <p className={theme?.stepSubHeading}>{subtitle}</p>}
    </div>
  )
}
