import clsx from 'clsx'
import type { ElementType, ForwardedRef, ReactElement } from 'react'
import {
  Children,
  useRef,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
} from '@rentpath/core-components'
import { useRequestData } from '../../features/request-data/pages-router/use-request-data'
import { HorizontalScrollArrowButton } from './horizontal-scroll-arrow-button'
import { HorizonalScrollGradient } from './horizontal-scroll-gradient'
import type { HorizonalScrollGradientRef } from './horizontal-scroll-gradient'
import type { HorizonalScrollButtonRef } from './horizontal-scroll-arrow-button'
import styles from './horizontal-scroll.module.css'

const DefaultScrollElement = 'ul'

export interface HorizontalScrollProps {
  /**
   * due to the observer, we cannot alllow text
   * as a child element
   */
  children: (ReactElement | false | null | undefined)[] | ReactElement
  className?: string
  /**
   * @default: true
   */
  noScrollbar?: boolean
  scrollAmount?: number
  observerOptions?: IntersectionObserverInit
}

export const HorizontalScroll: PolymorphicForwardRefExoticComponent<
  HorizontalScrollProps,
  typeof DefaultScrollElement
> = forwardRef(function HorizontalScroll<
  T extends ElementType = typeof DefaultScrollElement
>(
  props: PolymorphicPropsWithoutRef<HorizontalScrollProps, T>,
  forwardedRef: ForwardedRef<Element>
) {
  const {
    as,
    children,
    className,
    noScrollbar = true,
    scrollAmount,
    observerOptions,
    ...rest
  } = props
  const { isMobile } = useRequestData()
  const listRef = useRef<HTMLUListElement | HTMLDivElement>(null)
  const leftGradientRef = useRef<HorizonalScrollGradientRef>(null)
  const rightGradientRef = useRef<HorizonalScrollGradientRef>(null)
  const leftArrowRef = useRef<HorizonalScrollButtonRef>(null)
  const rightArrowRef = useRef<HorizonalScrollButtonRef>(null)
  const ScrollerComponent: ElementType = as || DefaultScrollElement

  useImperativeHandle(forwardedRef, () => listRef.current!, [])

  useEffect(() => {
    if (
      !listRef.current ||
      Children.toArray(children).length === 0 ||
      !listRef.current?.firstChild
    ) {
      return
    }

    const firstItem = listRef.current?.firstChild as HTMLElement
    const lastItem = listRef.current?.lastChild as HTMLElement

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === firstItem) {
            if (entry.isIntersecting) {
              leftArrowRef.current?.disable()
              leftGradientRef.current?.hide()
            } else {
              leftArrowRef.current?.enable()
              leftGradientRef.current?.show()
            }
          } else {
            if (entry.isIntersecting) {
              rightArrowRef.current?.disable()
              rightGradientRef.current?.hide()
            } else {
              rightArrowRef.current?.enable()
              rightGradientRef.current?.show()
            }
          }
        })
      },
      {
        threshold: observerOptions?.threshold || 1,
        rootMargin: observerOptions?.rootMargin || '0% 2% 0% 2%',
        root: listRef.current,
      }
    )

    observer.observe(firstItem)
    if (lastItem) observer.observe(lastItem)

    return () => {
      observer.unobserve(firstItem)
      if (lastItem) observer.unobserve(lastItem)
    }
  }, [listRef, observerOptions, children])

  const handleNavigation = useCallback(
    function handleNavigation(direction: 'left' | 'right') {
      const elem = listRef.current

      if (!elem) return

      const distance = scrollAmount || elem.clientWidth / 2
      const left = direction === 'left' ? -distance : distance

      elem.scrollBy({
        behavior: 'smooth',
        left,
      })
    },
    [listRef, scrollAmount]
  )

  return (
    <div className={clsx(styles.container, className)}>
      <div
        className={clsx(styles.arrows, {
          // stupid fix for Samsung devices that
          // have bugs with media queries
          // forces the arrows to be hidden on mobile
          [styles.notMobile]: !isMobile,
        })}
      >
        <HorizontalScrollArrowButton
          ref={leftArrowRef}
          key="scroll_left"
          aria-label="View Previous Items"
          onClick={handleNavigation}
          direction="left"
        />
        <HorizontalScrollArrowButton
          ref={rightArrowRef}
          key="scroll_right"
          aria-label="View More Items"
          onClick={handleNavigation}
          direction="right"
        />
      </div>
      <div className={styles.gradients}>
        <HorizonalScrollGradient
          ref={leftGradientRef}
          direction="left"
          data-tid="left-gradient"
        />
        <HorizonalScrollGradient
          ref={rightGradientRef}
          direction="right"
          data-tid="right-gradient"
        />
      </div>
      <ScrollerComponent
        className={clsx(styles.list, {
          [styles.noScrollbar]: noScrollbar,
        })}
        {...rest}
        ref={listRef}
      >
        {children}
      </ScrollerComponent>
    </div>
  )
})
