import clsx from 'clsx'
import { ReactComponent as CheckIcon } from '@brand/icons/check.svg'
import styles from './thank-you-confirmation-message.module.css'
import type { ListingIdsShown } from '../thank-you-redesign-listing-cards/thank-you-redesign-stacked-cards-wrapper'
import type { SimilarOrViewed_ListingFragment } from '../../../listing-card-preview-group/__generated__/similar-properties.gql'

const ConfirmationWithIcon = ({
  className,
  customMessage,
  wasTourLead,
}: {
  className: string
  customMessage?: JSX.Element
  wasTourLead: boolean
}) => (
  <div className={clsx(className, styles.confirmationMessage)}>
    <span className={styles.checkIconWrapper}>
      <CheckIcon />
    </span>
    <span>
      {customMessage || (wasTourLead ? 'Request Sent!' : 'Message Sent!')}
    </span>
  </div>
)

interface ConfirmationMessageProps {
  allViewedListingIds: string[]
  listingIdsShown: ListingIdsShown
  listings: SimilarOrViewed_ListingFragment[]
  wasTourLead: boolean
  customMessage?: JSX.Element
  isFinalScreen?: boolean
}

export function ConfirmationMessage({
  allViewedListingIds,
  listingIdsShown,
  listings,
  wasTourLead,
  customMessage,
  isFinalScreen,
}: ConfirmationMessageProps) {
  if (listingIdsShown?.notInterestedIds.length) {
    const mostRecentNotInterestedId =
      listingIdsShown?.notInterestedIds[
        listingIdsShown.notInterestedIds.length - 1
      ]

    const lastListingId = listings[allViewedListingIds.length - 1].id

    if (mostRecentNotInterestedId === lastListingId) {
      // don't show the "here's another" message on final screen. There is not another.
      if (isFinalScreen) return null

      return (
        <p
          className={clsx(styles.showSlideIn, styles.confirmationMessage)}
          key={allViewedListingIds.length}
        >
          {customMessage || "OK! Here's another..."}
        </p>
      )
    }
  }

  return (
    <div className={styles.message}>
      <ConfirmationWithIcon
        className={styles.showSlideIn}
        wasTourLead={wasTourLead}
        customMessage={customMessage}
        key={allViewedListingIds.length}
      />
    </div>
  )
}
