/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type HotDeals_ListingFragment = { deals: Array<{ description: string, couponCategory: string }> };

export const HotDeals_ListingFragmentDoc = `
    fragment HotDeals_ListingFragment on Listing {
  deals {
    description
    couponCategory
  }
}
    `;



