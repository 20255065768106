import { EnhancedLeadPrompts } from '../../features/enhanced-lead-form/enhanced-lead-prompts'
import styles from './enhanced-lead-questions.module.css'
import { Heading } from '../../components/heading/heading'
import type { LeadForm_ListingFragment } from '../lead-form/__generated__/lead-form.gql'

interface EnhancedLeadQuestionFormProps {
  listing: LeadForm_ListingFragment
  onFormSubmit: () => void
  sendButton: JSX.Element
}

export function EnhancedLeadQuestionForm({
  listing,
  onFormSubmit,
  sendButton,
}: EnhancedLeadQuestionFormProps) {
  return (
    <form
      className={styles.form}
      onSubmit={(e) => {
        e.preventDefault()
        onFormSubmit()
      }}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.heading}>
          <Heading withBodyFont level="2x">
            Almost Done...
          </Heading>
        </div>
        <EnhancedLeadPrompts
          promptIds={listing.leadQuestions?.ids as string[]}
        />
      </div>
      <footer className={styles.footer}>{sendButton}</footer>
    </form>
  )
}
