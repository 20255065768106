import { getDateDelta } from '@rentpath/web-utils'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'

import { tourDatesAtom, tourTypeAtom } from '../../tour-wizard.store'

import type { TourWizardConfig } from '../../tour-wizard.types'
import type { TourTimesQueryVariables } from '../graphql/__generated__/tour-times.gql'
import { getAsyncTourTimesVariables } from '../graphql/get-async-tour-times-variables'
import { getDateRange } from '../utils/get-date-range'
import { useResponsiveDaysToShow } from './use-responsive-days-to-show'

function useSelectedTourType(tourWizardConfig: TourWizardConfig) {
  const { orderedTourTypes } = tourWizardConfig
  const [enabledTypes] = orderedTourTypes || []
  const selectedTypeName = useAtomValue(tourTypeAtom)

  if (!enabledTypes.includes(selectedTypeName)) {
    return enabledTypes[0]
  }

  return selectedTypeName
}

export function useAsyncTourTimesVariables(
  tourWizardConfig: TourWizardConfig
): TourTimesQueryVariables | null {
  const daysToShow = useResponsiveDaysToShow()
  const tourDates = useAtomValue(tourDatesAtom)

  const selectedTypeName = useSelectedTourType(tourWizardConfig)

  const variables = useMemo(() => {
    if (daysToShow > 0) {
      const dates = getDateRange(getDateDelta(tourDates[0], 0), daysToShow)
      return getAsyncTourTimesVariables(
        tourWizardConfig,
        selectedTypeName,
        dates
      )
    }

    return null
  }, [tourWizardConfig, tourDates, daysToShow, selectedTypeName])

  return variables
}
