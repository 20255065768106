import clsx from 'clsx'
import type { ForwardedRef } from 'react'
import { memo, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import { useRequestData } from '../../request-data/pages-router/use-request-data'
import type { TestIdProp } from '../../../types'
import styles from './ad-slot.module.css'
import { useAditudeEnabled } from './use-aditude-enabled'

export type AdSlotConfig = {
  baseDivId: string
}

export type AdSlotProps = {
  className?: string
  config?: AdSlotConfig
  targeting?: Record<string, string | string[]> | null
} & TestIdProp

export const AdSlot = memo(
  forwardRef(function AdSlot(
    { className, config, targeting, ...restProps }: AdSlotProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) {
    const { off } = useRequestData()
    const ref = useRef<HTMLDivElement>(null)
    const baseDivId = config?.baseDivId
    const id = `aditude-ad-slot-${baseDivId}`
    const aditudeEnabled = useAditudeEnabled()

    useImperativeHandle(forwardedRef, () => ref.current!)

    useEffect(() => {
      if (ref.current && baseDivId) {
        window.tude = window.tude || {}
        window.tude?.cmd?.push(function renderAditudeSlot() {
          window.tude?.refreshAdsViaDivMappings?.([
            {
              divId: id,
              baseDivId,
              targeting,
            },
          ])
        })
      }
    }, [baseDivId, id, targeting])

    if (off.gpt || !config?.baseDivId || !aditudeEnabled) {
      return null
    }

    return (
      <div
        className={clsx(styles.slot, className, 'ad-slot')}
        {...restProps}
        id={id}
        ref={ref}
      />
    )
  })
)
