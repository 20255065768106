import { LeadQuestion } from './const'

const formatMap = {
  [LeadQuestion.LEASE_TERM]: (value: string[]) => `${value.join(',')} mo lease`,
  [LeadQuestion.BEDS]: (value: string[]) => `${value.join(', ')} Bedroom units`,
  [LeadQuestion.OCCUPANTS]: (value: string[]) =>
    `Occupancy ${value.join(', ')}`,
  [LeadQuestion.PETS]: (value: string[]) => `Pets ${value.join(', ')}`,
} as const

export function buildLeadQuestionsMessage({
  questions,
  leadQuestionIds,
}: {
  questions?: Record<string, string[]>
  leadQuestionIds?: string[] | null
} = {}) {
  if (!questions) {
    return ''
  }

  const formattedQuestions = Object.entries(questions).reduce<string[]>(
    (acc, [key, value]) => {
      const formatter = formatMap[key as keyof typeof formatMap]

      if (formatter && value.length && leadQuestionIds?.includes(key)) {
        acc.push(formatter(value))
      }

      return acc
    },
    []
  )

  if (formattedQuestions.length === 0) {
    return ''
  }

  return `Looking for ${formattedQuestions.join(' - ')}. `
}
