import {
  amenityRefinementMap,
  amenitySlugMap,
  amenitySlugs,
  petRefinementMap,
  petSlugs,
} from '@brand/search/refinements'
import { getAmenityAlias } from '@brand/detail/amenities'
import { Amenity } from '../../__generated__/api-types'

export function getAltText(
  caption?: string | null,
  name?: string | null,
  index?: number | null,
  cityState?: string | null
) {
  return !caption || /\.(jpg|jpeg|png)$/i.test(caption)
    ? `${name ? `${name} - ` : ''}${cityState ? `${cityState} - ` : ''}Photo${
        typeof index === 'number' ? ` ${index + 1}` : ''
      }`
    : caption
}

export function getAmenityText({
  refinementSlugs,
  amenitiesHighlighted,
}: {
  refinementSlugs: string[]
  amenitiesHighlighted: string[]
}) {
  const excludedAmenitySlugs = [
    amenitySlugMap[Amenity.AvailableNow],
    amenitySlugMap[Amenity.IncomeRestricted],
  ]

  const selectedPetPoliciesAndAmenities = (refinementSlugs ?? []).reduce<
    string[]
  >((acc, slug) => {
    // Pet policy gets listed first
    if (petSlugs.includes(slug)) {
      acc.unshift(petRefinementMap[slug].label)
    }

    if (!excludedAmenitySlugs.includes(slug) && amenitySlugs.includes(slug)) {
      acc.push(amenityRefinementMap[slug].label)
    }

    return acc
  }, [])

  const isPetPolicySelected = refinementSlugs.some((slug) =>
    petSlugs.includes(slug)
  )

  const highlightedAmenities = (amenitiesHighlighted ?? [])
    .filter((amenity) => {
      const isAmenitySelected =
        selectedPetPoliciesAndAmenities.includes(amenity)

      return isPetPolicySelected
        ? // If user selected a pet policy, don't also show the "Pet Friendly" top amenity
          amenity !== 'Pet Friendly' && !isAmenitySelected
        : // Deduplicate with selected amenities before mapping to the amenity alias.
          // Some amenity aliases are used only on PDP
          !isAmenitySelected
    })
    .map(getAmenityAlias)

  const allAmenities = [
    // Deduplicate
    ...new Set(selectedPetPoliciesAndAmenities.concat(highlightedAmenities)),
  ]

  return allAmenities.slice(0, 3).join(' • ')
}
