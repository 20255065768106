import { useState } from 'react'

import { CloudImage } from '../../../../../components/cloud-image/cloud-image'
import { Heading } from '../../../../../components/heading/heading'

import clsx from 'clsx'

import { buildPropertyImageDesc } from '../../../../detail/gallery/gallery-carousel.utils'

import type { ThankYou_ListingFragment } from '../../../../thank-you/__generated__/thank-you.gql'

import styles from './thank-you-ad-step-header.module.css'

export interface AdStepHeaderProps {
  initiatedLeadListing: ThankYou_ListingFragment
}

export function AdStepHeader({ initiatedLeadListing }: AdStepHeaderProps) {
  const { name, photos, optimizedPhotos } = initiatedLeadListing
  const [photoHasError, setPhotoHasError] = useState(false)

  const optimizedOrRegularPhotos = optimizedPhotos || photos

  const firstPhoto = optimizedOrRegularPhotos?.[0]

  return (
    <div className={styles.adStepHeaderWrapper}>
      {firstPhoto?.id && !photoHasError && (
        <CloudImage
          className={styles.image}
          alt={buildPropertyImageDesc(initiatedLeadListing, firstPhoto)}
          width={140}
          height={140}
          publicId={firstPhoto.id}
          size="lg"
          sizeDesktop="xl"
          onError={() => setPhotoHasError(true)}
        />
      )}
      <Heading
        withBodyFont
        className={clsx(
          styles.heading,
          photoHasError && styles.headingWithPhotoError
        )}
      >
        Request Sent!
      </Heading>
      {name && <p className={styles.propertyName}>{name}</p>}
    </div>
  )
}
