import type { ReactNode } from 'react'
import { getCloudinaryUrl } from '../../components/cloud-image/get-cloudinary-url'
import { ImageWithFallback } from '../../components/image-with-fallback/image-with-fallback'
import styles from './listing-card-carousel.module.css'
import type { getLocationRelativeToSearchedCity } from '../search/utils/get-location-relative-to-searched-city'

type DetailPageLinkProps = {
  isMobile?: boolean
  urlPathname: string
  children: ReactNode
  onLinkClick?: () => void
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
  dataTagItem?: string
}

type SlideItemProps = {
  id?: string
  alt: string
  isMobile?: boolean
  urlPathname: string
  className?: string
  onLinkClick?: () => void
  isLCP?: boolean
  name: string
  index?: number
  carouselClassName?: string
  isUnpaid: boolean
  isPhantomImage?: boolean
  photo: {
    id: string
    caption?: string | null | undefined
  }
  width?: number
  height?: number
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
  dataTagItem?: string
}
function DetailPageLink(props: DetailPageLinkProps) {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={props.urlPathname}
      className={styles.image}
      target={props.isMobile ? undefined : '_blank'}
      onClick={props.onLinkClick}
      data-tag_geo_sort_property_match_type={
        props.locationRelativeToSearchedCity
      }
      data-tag_item={props.dataTagItem}
    >
      {props.children}
    </a>
  )
}

export function SlideItem(props: SlideItemProps) {
  return (
    <div className={props.className}>
      {!props.isPhantomImage && (
        <DetailPageLink
          isMobile={props.isMobile}
          urlPathname={props.urlPathname}
          onLinkClick={props.onLinkClick}
          locationRelativeToSearchedCity={props.locationRelativeToSearchedCity}
          dataTagItem={props.dataTagItem}
        >
          <ImageWithFallback
            id={props.id}
            width={props.width || 350}
            height={props.height || 240}
            priority={props.isLCP}
            unoptimized
            src={getCloudinaryUrl(props.photo.id, 'lg', {
              isUnpaid: props.isUnpaid,
            })}
            alt={props.alt}
            className={styles.image}
            fallbackImageSize="sm"
          />
        </DetailPageLink>
      )}
    </div>
  )
}
