export const AVAILABILITY_UNKNOWN = 'Availability Unknown'
export const PRICE_UNAVAILABLE = 'Price Unavailable'
export const CONTACT_FOR_PRICE = 'Contact for Price'
export const MIN_VALID_PRICE_THRESHOLD = 10
export const MAGICAL_HIGH = 99999
export const PDP_FLOOR_PLAN_SECTION_ID = 'pdp-floorPlan-section'
export const floorPlanSectionTitles = [
  'Loft',
  'Studio',
  '1 Bedroom',
  '2 Bedrooms',
  '3 Bedrooms',
  '4+ Bedrooms',
] as const

export const floorPlansShort: readonly string[] = [
  'Loft',
  'Studio',
  '1 Bed',
  '2 Beds',
  '3 Beds',
  '4 Beds+',
]

export const floorPlansAbbrev: readonly string[] = [
  'Loft',
  'Studio',
  '1 Bd',
  '2 Bd',
  '3 Bd',
  '4 Bd+',
]

export const LISTHUB_AVAILABLE = 'Active'
export const LISTHUB_UNAVAILABLE = 'Inactive'
export const TODAY = 'Today'
export const AVAILABLE = 'Available'
export const AVAILABLE_NOW = 'Available Now'
export const NOT_AVAILABLE = 'Not Available'
export const CONTACT_FOR_AVAILABILITY = 'Contact for Availability'
export const UNKNOWN = 'Contact'

export const FP_LEGAL_DISCLAIMER_START_FRAGMENT =
  'Floor plans are artist’s rendering. All dimensions are approximate. Actual product and specifications may vary in dimension or detail. Not all features are available in every apartment. Price shown is base rent, does not include add-on fees and utilities. Base rent and availability are subject to change. '
export const FP_LEGAL_DISCLAIMER_MIDDLE_FRAGMENT = 'See Total Cost & Fees'
export const FP_LEGAL_DISCLAIMER_END_FRAGMENT =
  ' or contact a representative for details.'
const FP_LEGAL_DISCLAIMER_END_FRAGMENT_BASIC_LISTING =
  'Contact a representative for details.'

export const FP_LEGAL_DISCLAIMER = `${FP_LEGAL_DISCLAIMER_START_FRAGMENT}${FP_LEGAL_DISCLAIMER_MIDDLE_FRAGMENT}${FP_LEGAL_DISCLAIMER_END_FRAGMENT}`
export const FP_LEGAL_DISCLAIMER_BASIC_LISTING = `${FP_LEGAL_DISCLAIMER_START_FRAGMENT}${FP_LEGAL_DISCLAIMER_END_FRAGMENT_BASIC_LISTING}`
