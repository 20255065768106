import { Button } from '../../components/button/button'
import { Spinner } from '../../components/spinner/spinner'
import styles from './submit-lead-cta-button.module.css'

interface SubmitLeadCTAButtonProps {
  isValid?: boolean
  isSending: boolean
  title: string
  dataTag: string
}

export function SubmitLeadCTAButton({
  isValid = true,
  isSending,
  title,
  dataTag,
}: SubmitLeadCTAButtonProps) {
  return (
    <Button
      type="submit"
      fluid
      rounded
      variant="primary"
      data-tid="lead-submit"
      data-tag_item={dataTag}
      disabled={!isValid || isSending}
      className={isSending ? styles.loading : ''}
    >
      {isSending ? <Spinner variant="inverted" /> : title}
    </Button>
  )
}
