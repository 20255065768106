import React from 'react'
import { useTourWizardConfig } from './hooks/use-tour-wizard-config'
import { TourWizardNav } from './tour-wizard-nav'

import type { FC } from 'react'

export const TourWizardFooter: FC = () => {
  const { theme } = useTourWizardConfig()

  return (
    <footer className={theme?.footerContainer}>
      <div className={theme?.footerContainerInner}>
        <TourWizardNav />
      </div>
    </footer>
  )
}
