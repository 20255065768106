/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type TourFromHome_PhotoFragment = { id: string };

export type TourFromHome_HdTourFragment = { name?: string | null, url?: string | null, imageToken?: string | null, vendorThumbnailUrl?: string | null };

export type TourFromHome_VideoFragment = { title?: string | null, videoUrl?: string | null, imageToken?: string | null };

export type TourFromHome_ListingFragment = { id: string, name?: string | null, isUnpaid: boolean, hasVideosOrTours: boolean, photos?: Array<{ id: string } | null> | null, hdTours: Array<{ name?: string | null, url?: string | null, imageToken?: string | null, vendorThumbnailUrl?: string | null }>, videos: Array<{ title?: string | null, videoUrl?: string | null, imageToken?: string | null }> };

export const TourFromHome_PhotoFragmentDoc = `
    fragment TourFromHome_PhotoFragment on Photo {
  id
}
    `;
export const TourFromHome_HdTourFragmentDoc = `
    fragment TourFromHome_HdTourFragment on HdTour {
  name
  url
  imageToken
  vendorThumbnailUrl
}
    `;
export const TourFromHome_VideoFragmentDoc = `
    fragment TourFromHome_VideoFragment on Video {
  title
  videoUrl
  imageToken
}
    `;
export const TourFromHome_ListingFragmentDoc = `
    fragment TourFromHome_ListingFragment on Listing {
  id
  name
  isUnpaid
  hasVideosOrTours
  photos {
    ...TourFromHome_PhotoFragment
  }
  hdTours {
    ...TourFromHome_HdTourFragment
  }
  videos {
    ...TourFromHome_VideoFragment
  }
}
    `;



