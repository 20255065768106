/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type TourTimesQueryVariables = Types.Exact<{
  listingId: Types.Scalars['ID'];
  dates: Array<Types.Scalars['DateTime']> | Types.Scalars['DateTime'];
  type: Types.TourType;
}>;


export type TourTimesQuery = { listing?: { tourTimes: Array<{ date?: string | null, times?: Array<string> | null }> } | null };


export const TourTimesDocument = `
    query TourTimes($listingId: ID!, $dates: [DateTime!]!, $type: TourType!) {
  listing(id: $listingId) {
    tourTimes(dates: $dates, type: $type) {
      date
      times
    }
  }
}
    `;

    export function createTourTimesFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchTourTimes(variables: TourTimesQueryVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<TourTimesQuery, TourTimesQueryVariables>(TourTimesDocument, variables, headers);
      }
    }

