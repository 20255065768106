import { useMediaQuery } from '@rentpath/react-hooks'
import { useMemo } from 'react'

import { useTourWizardConfig } from '../../wizard/hooks/use-tour-wizard-config'
import { getIsMaxLeaseTourProvider } from '../../wizard/utils/get-is-max-lease-tour-provider'
import { getIsUDRTourProvider } from '../../wizard/utils/get-is-udr-tour-provider'

enum Breakpoints {
  MobileSm = '(max-width: 375px)',
  Mobile = '(min-width: 376px) and (max-width: 600px)',
  Tablet = '(min-width: 601px) and (max-width: 768px)',
  Desktop = '(min-width: 769px)',
}

const breakpointConfig: Record<string, number> = {
  [Breakpoints.MobileSm]: 2,
  [Breakpoints.Mobile]: 3,
  [Breakpoints.Tablet]: 4,
  [Breakpoints.Desktop]: 5,
}

export const initialMaxDays = 0

export function useResponsiveDaysToShow(): number {
  const tourWizardConfig = useTourWizardConfig()

  const isMobileSm = useMediaQuery(Breakpoints.MobileSm)
  const isMobile = useMediaQuery(Breakpoints.Mobile)
  const isTablet = useMediaQuery(Breakpoints.Tablet)
  const isDesktop = useMediaQuery(Breakpoints.Desktop)

  const isUDR = getIsUDRTourProvider(tourWizardConfig.tourProvider)
  const isMaxLease = getIsMaxLeaseTourProvider(tourWizardConfig.tourProvider)

  const daysToShow = useMemo(() => {
    let newDays = 0

    if (isUDR) return 1

    if (isMobileSm) {
      // For all mobile screens max lease requires 3 columns
      newDays = isMaxLease
        ? breakpointConfig[Breakpoints.Mobile]
        : breakpointConfig[Breakpoints.MobileSm]
    } else if (isMobile) {
      newDays = breakpointConfig[Breakpoints.Mobile]
    } else if (isTablet) {
      newDays = breakpointConfig[Breakpoints.Tablet]
    } else if (isDesktop) {
      newDays = breakpointConfig[Breakpoints.Desktop]
    }

    return newDays
  }, [isMobileSm, isMobile, isTablet, isDesktop, isUDR, isMaxLease])

  return daysToShow
}
