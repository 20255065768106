/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type UseShareProperty_ListingFragment = { addressFull?: string | null, name?: string | null, urlPathname?: string | null, photos?: Array<{ id: string, caption?: string | null, category?: string | null } | null> | null };

export const UseShareProperty_ListingFragmentDoc = `
    fragment UseShareProperty_ListingFragment on Listing {
  addressFull
  name
  photos {
    id
    caption
    category
  }
  urlPathname
}
    `;



