export const DETAIL_PAGE_AD_SLOTS = {
  PAID_AD1: { baseDivId: 'pb-slot-pdp-paid-ad-1' },
  PAID_AMENITIES: {
    baseDivId: 'pb-slot-pdp-paid-nativeamenities',
  },

  UNPAID_AD1: { baseDivId: 'pb-slot-pdp-unpaid-ad-1' },
  UNPAID_AD2: { baseDivId: 'pb-slot-pdp-unpaid-ad-2' },
  UNPAID_AMENITIES: { baseDivId: 'pb-slot-pdp-unpaid-nativeamenities' },
  // makes TS happy due to it existing in the other config
  UNPAID_GALLERY: undefined,
} as const

export const SEARCH_PAGE_AD_SLOTS = {
  IN_SEARCH_RESULTS_AD1: {
    baseDivId: 'pb-slot-search-inresults-1',
  },
  IN_SEARCH_RESULTS_AD2: {
    baseDivId: 'pb-slot-search-inresults-2',
  },
  IN_SEARCH_RESULTS_AD3: {
    baseDivId: 'pb-slot-search-inresults-3',
  },
  PAGINATION_AD: { baseDivId: 'pb-slot-search-pagination' },
} as const

export const THANK_YOU_AD_SLOTS = {
  PAID_AD: { baseDivId: 'pb-slot-thankyou-paid-native' },
  UNPAID_AD: { baseDivId: 'pb-slot-thankyou-unpaid-native' },
} as const
