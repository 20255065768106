import { getBrandWebsite } from '@brand/config/brand-config'
import { errorMessages } from '@rentpath/form-validation'
import { useAtomValue, useSetAtom } from 'jotai'
import { useState } from 'react'
import { useRequestData } from '../request-data/pages-router/use-request-data'
import { useZutronId } from '../user/user.store'
import { dayTimeAtom } from './day-time-picker/day-time-atom'
import { formatDayPickerTimeToISO } from './day-time-picker/day-time-formatters'
import { submitLeadWithErrorHandling } from '../lead-form/submit-lead'
import { createFacebookDataForLead } from '../lead-form/utils/create-facebook-data-for-lead'
import {
  trackSuccess,
  LeadCategoryRate,
} from '../lead-form/utils/event-tracking'
import { getLeadDeviceType } from '../lead-form/utils/get-lead-device-type'
import { getLeadRefinements } from '../lead-form/utils/get-lead-refinements'
import { userAddEmailedEvent } from '../user-event-tracking/user-add-emailed-event'
import {
  DAY_TIME_PICKER_DAYS_TO_ADD,
  DAY_TIME_PICKER_TIMES,
} from './day-time-picker/day-time-picker'
import { REQUEST_A_TOUR } from '../one-click-lead/one-click-lead.const'
import { leadCookieAtom } from '../lead-form/lead-cookie.store'
import { oneClickCookieAtom } from '../one-click-lead/one-click-lead-cookie.store'
import { buildRequestTourMessage } from './contact-day-picker-form/build-request-tour-message'
import {
  currentStepAtom,
  RequestTourSteps,
} from './request-a-tour-wizard.store'
import {
  enhancedLeadsAtom,
  enhancedLeadCookieAtom,
} from '../enhanced-lead-form/enhanced-leads.store'
import { buildEnhancedLeadSubmissionFields } from '../enhanced-lead-form/utils'
import {
  LeadChannel,
  LeadSmsConsentEntity,
  LeadTour,
} from '../../__generated__/api-types'
import type { LeadSubmissionInput } from '../../__generated__/api-types'
import type { SubmitHandler, UseFormSetError } from 'react-hook-form'
import type { LeadForm_ListingFragment } from '../lead-form/__generated__/lead-form.gql'
import type { getLocationRelativeToSearchedCity } from '../search/utils/get-location-relative-to-searched-city'
import { getTourTimeDates } from './day-time-picker/get-tour-time-dates'

export type RequestTourFormData = {
  email: string
  message: string
  moveDate: string
  name: string
  phone: string
}

interface CustomErrors {
  email?: string
}

interface CustomGraphQLErrorExtensions {
  errors?: CustomErrors
}

type UseLeadSubmissionProps = {
  currentRefinementSlugs?: string[]
  listing: LeadForm_ListingFragment
  setError?: UseFormSetError<RequestTourFormData>
  shouldShowSmsConsent?: boolean
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
}

export function useLeadSubmission({
  listing,
  setError,
  shouldShowSmsConsent,
  currentRefinementSlugs,
  locationRelativeToSearchedCity,
}: UseLeadSubmissionProps) {
  const [isSending, setIsSending] = useState(false)
  const { ip, useGDPR, visitId, sessionId, isMobile, sem, ipLocation } =
    useRequestData()
  const tourDayAndTime = useAtomValue(dayTimeAtom)
  const zutronId = useZutronId()
  const setLeadCookieData = useSetAtom(leadCookieAtom)
  const setOneClickCookieData = useSetAtom(oneClickCookieAtom)
  const setCurrentStep = useSetAtom(currentStepAtom)
  const enhancedLeadsFormValues = useAtomValue(enhancedLeadsAtom)
  const setEnhancedLeadsCookie = useSetAtom(enhancedLeadCookieAtom)
  const isEnhanced = Boolean(listing.leadQuestions?.ids?.length)

  const handleValidSubmit: SubmitHandler<RequestTourFormData> = (leadData) => {
    const fbData = createFacebookDataForLead({
      email: leadData.email,
      listingId: listing.id,
      ip,
      revenue: listing.revenue,
      useGDPR,
      sessionId,
      visitId,
    })

    const dayTimePickerDates = getTourTimeDates(
      new Date(),
      DAY_TIME_PICKER_DAYS_TO_ADD,
      listing.officeHours
    )

    const formattedRequestTourTime = DAY_TIME_PICKER_TIMES[tourDayAndTime.time]

    const dateTime = `${formattedRequestTourTime} ${
      dayTimePickerDates[tourDayAndTime.day].shortDateText
    }`

    const leadMessage = buildRequestTourMessage({
      name: leadData.name,
      propertyName: listing?.name || '',
      dateTime,
      customerMessage: leadData.message,
      questions: enhancedLeadsFormValues,
      leadQuestionIds: listing.leadQuestions?.ids || [],
    })

    const enhancedLeadFormatted = isEnhanced
      ? buildEnhancedLeadSubmissionFields(
          listing.leadQuestions?.ids || [],
          enhancedLeadsFormValues
        )
      : {}

    const lead: LeadSubmissionInput = {
      applicationOptIn: false,
      campaignId: sem.marketingCampaignId,
      device: getLeadDeviceType(),
      context: 'modal',
      email: leadData.email,
      facebookData: fbData,
      evTransId: `${visitId}.${sessionId}_${listing.id}`,
      leadTypeId: LeadChannel.Active,
      listingId: listing.id,
      message: leadMessage,
      moveDate: leadData.moveDate,
      fullName: leadData.name,
      optInNewsletter: Boolean(ipLocation?.isUSA),
      phone: leadData.phone,
      refinements: getLeadRefinements(currentRefinementSlugs),
      tourType: LeadTour.Unspecified,
      smsConsentEntities: shouldShowSmsConsent
        ? [LeadSmsConsentEntity.Advertiser]
        : null,
      smsOptIn: shouldShowSmsConsent,
      taggingHitId: window.hit_id,
      tourDateTime: formatDayPickerTimeToISO(
        tourDayAndTime,
        listing.timeZoneId || '',
        listing.officeHours
      ),
      sessionId: `${visitId}.${sessionId}`,
      tvisit: `${visitId}.${sessionId}`,
      website: getBrandWebsite(isMobile),
      ...enhancedLeadFormatted,
    }

    setIsSending(true)

    const headers = zutronId ? { zid: zutronId } : undefined

    submitLeadWithErrorHandling({ lead }, headers)
      .tapOk(() => {
        const cookieData = {
          email: leadData.email,
          moveDate: leadData.moveDate,
          name: leadData.name,
          phone: leadData.phone,
        }

        if (isEnhanced) setEnhancedLeadsCookie(enhancedLeadsFormValues)

        trackSuccess(
          listing,
          LeadCategoryRate.REQUEST_TOUR,
          isEnhanced ? 'request_tour_e' : 'request_tour',
          'request_tour_form',
          locationRelativeToSearchedCity
        )
        setLeadCookieData(cookieData)

        const requestedTourDate = formatDayPickerTimeToISO(
          tourDayAndTime,
          listing.timeZoneId || '',
          listing.officeHours
        )

        setOneClickCookieData({
          ...cookieData,
          applicationOptIn: false,
          tourRequest: true,
          dateTime,
          requestedTourDate,
          message: leadMessage,
          mostRecentLeadTypeSubmitted: REQUEST_A_TOUR,
        })

        userAddEmailedEvent({ listingId: listing.id, zutronId: zutronId || '' })
        setCurrentStep(RequestTourSteps.similarProperties)
      })
      .tapError((err) => {
        const customExtensions = err?.extensions as CustomGraphQLErrorExtensions
        const customErrors = customExtensions?.errors

        if (customErrors && customErrors.email) {
          setError?.('email', {
            type: 'custom',
            message: errorMessages.EMAIL_INVALID,
          })
        } else {
          alert('There was a problem submitting your info.')
        }
      })
      .tap(() => {
        setIsSending(false)
      })
  }

  return {
    handleValidSubmit,
    isSending,
  }
}
