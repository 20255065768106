import { useEffect, useRef } from 'react'
import type { ReactElement } from 'react'
import clsx from 'clsx'
import { format } from 'date-fns'
import { useAtom } from 'jotai'

import { getTourTimeDates } from './get-tour-time-dates'
import { HorizontalScroll } from '../../../components/horizontal-scroll/horizontal-scroll'
import styles from './day-time-picker.module.css'
import type { LeadForm_ListingFragment } from '../../lead-form/__generated__/lead-form.gql'
import { dayTimeAtom } from './day-time-atom'

export interface DayTimePickerProps {
  showArrows?: boolean
  modalForm?: boolean
  officeHours: LeadForm_ListingFragment['officeHours']
}

interface DaySlotTheme {
  slotStyle?: string
  activeSlotStyle?: string
  idleSlotStyle?: string
  disabledSlotStyle?: string
}

interface RequestSlotProps {
  theme: DaySlotTheme
  isActive: boolean
  index: number
  handler: (index: number) => void
  title: JSX.Element | string
  disabled: boolean
}

export const DAY_TIME_PICKER_DAYS_TO_ADD = 14

export const DAY_TIME_PICKER_HOURS = {
  'anytime on': 0,
  'the morning of': 9,
  'the afternoon of': 14,
} as const

export const dayTimesOptions = ['Anytime', 'Morning', 'Afternoon'] as const

export type dayTimesOptionsType = typeof dayTimesOptions[number]

export type TimeDescriptions = keyof typeof DAY_TIME_PICKER_HOURS

export const DAY_TIME_PICKER_TIMES = Object.keys(
  DAY_TIME_PICKER_HOURS
) as TimeDescriptions[]

const dayCardTheme: DaySlotTheme = {
  slotStyle: styles.dayCardSlotStyle,
  activeSlotStyle: styles.dayCardSlotStyleActive,
  idleSlotStyle: styles.dayCardSlotStyleIdle,
  disabledSlotStyle: styles.dayCardSlotStyleDisabled,
}

const timeSlotTheme: DaySlotTheme = {
  ...dayCardTheme,
  slotStyle: styles.timeSlotStyle,
}

function RequestSlot(props: RequestSlotProps): JSX.Element {
  const idleStyle = props.disabled
    ? props.theme.disabledSlotStyle
    : props.theme.idleSlotStyle
  return (
    <button
      className={clsx(
        props.theme.slotStyle,
        props.isActive ? props.theme.activeSlotStyle : idleStyle
      )}
      onClick={() => props.handler(props.index)}
      type="button"
      disabled={props.disabled}
    >
      <span className={styles.nonSelectableText}>{props.title}</span>
    </button>
  )
}

function getFirstEnabledDay(days: ReturnType<typeof getTourTimeDates>): number {
  const firstEnabledDay = days.findIndex((day) => day.enabled)
  return firstEnabledDay > -1 ? firstEnabledDay : 0
}

export function DayTimePicker(props: DayTimePickerProps) {
  const horizontalScrollHandle = useRef<HTMLUListElement>(null)

  const dayTimePickerDates = getTourTimeDates(
    new Date(),
    DAY_TIME_PICKER_DAYS_TO_ADD,
    props.officeHours
  )

  const [tourDayAndTime, updateTourDayAndTime] = useAtom(dayTimeAtom)

  useEffect(() => {
    if (horizontalScrollHandle?.current && tourDayAndTime?.day > 2) {
      // If user selects a date to the right of the visible container width
      // (i.e. they scrolled to a date on the right) then we need to initialize
      // our scroll position so that the date is visible.
      // These numbers are based on the width of the date <li> element + padding
      horizontalScrollHandle.current.scrollTo({
        left: 87 * (tourDayAndTime.day - 1) + 36,
        behavior: 'instant' as ScrollBehavior,
      })
    }

    if (!dayTimePickerDates[tourDayAndTime.day]?.enabled) {
      updateTourDayAndTime({
        day: getFirstEnabledDay(dayTimePickerDates),
        time: 0,
      })
    }
  }, [tourDayAndTime.day, dayTimePickerDates, updateTourDayAndTime])

  function handleDaySelect(day: number) {
    updateTourDayAndTime({
      day,
      time: 0,
    })
  }

  function handleTimeSelect(time: number) {
    updateTourDayAndTime((prev) => ({
      ...prev,
      time,
    }))
  }

  const selectedDate = dayTimePickerDates[tourDayAndTime.day]?.date
    ? format(dayTimePickerDates[tourDayAndTime.day].date, 'M/d')
    : ''
  const requestedDays = `${
    DAY_TIME_PICKER_TIMES[tourDayAndTime.time] || ''
  } ${selectedDate}`

  return (
    <div className={clsx(props.modalForm ? '' : styles.wrapper)}>
      <div className={styles.dayPicker}>
        <HorizontalScroll
          ref={horizontalScrollHandle}
          data-tid={'requested-date-time-picker-slots'}
        >
          {dayTimePickerDates.reduce((acc: ReactElement[], day, index) => {
            const weekDay = format(day.date, 'eee')

            acc.push(
              <li
                key={`dateslot_${index}_${weekDay}`}
                className={clsx(
                  styles.dayPickerDateSlotItem,
                  !props?.showArrows &&
                    styles.dayPickerDateSlotItemWithoutArrows,
                  props?.showArrows && styles.dayPickerDateSlotItemWithArrows
                )}
              >
                <RequestSlot
                  theme={dayCardTheme}
                  index={index}
                  handler={handleDaySelect}
                  isActive={index === tourDayAndTime.day}
                  disabled={!day.enabled}
                  title={
                    <div>
                      <p className={styles.dateSlotTextDay}>{weekDay}</p>
                      <p className={styles.dateSlotTextDate}>
                        {format(day.date, 'M/d')}
                      </p>
                    </div>
                  }
                />
              </li>
            )
            return acc
          }, [])}
        </HorizontalScroll>
        <ul className={styles.timeSlotList} data-tid="requested-time-slot">
          {dayTimesOptions.map((dayTime, index) => (
            <li key={dayTime} className={styles.timeSlotItem}>
              <RequestSlot
                theme={timeSlotTheme}
                index={index}
                handler={handleTimeSelect}
                isActive={tourDayAndTime.time === index}
                title={dayTime}
                disabled={false}
              />
            </li>
          ))}
        </ul>
        {props?.modalForm ? (
          <p
            data-tid="requested-text"
            className={styles.youreRequestingTextModal}
          >
            You&apos;re requesting <span>{requestedDays}.</span>
          </p>
        ) : (
          <p data-tid="requested-text" className={styles.youreRequestingText}>
            {`You're requesting ${requestedDays}.`}
          </p>
        )}
      </div>
    </div>
  )
}
