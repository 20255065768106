import { useEffect, useMemo } from 'react'

export function useUpdateEventTrackerForLeads(
  listingId: string,
  tplSource?: string | null
) {
  const trackingData = useMemo(
    () => ({
      listing_id: listingId, // cd9, cd30
      tpl_source: tplSource, // cd28
    }),
    [listingId, tplSource]
  )

  useEffect(() => {
    // Set tracking parameters that the eventTracker needs as long as there's a
    // lead form on the page. They are absolutely necessary. We also don't want to
    // use setData action since it doesn't merge with previous data.
    // - Note: listhub_id was previously included in rent-js but is no longer used
    window.eventTracker?.include(trackingData)
  }, [trackingData])
}
