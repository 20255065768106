/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type ParkingFieldsFragment = { assigned?: boolean | null, assignedFee?: string | null, comments?: string | null, id?: string | null, perSpaceFee?: string | null, totalSpaces?: number | null, type?: string | null };

export type Parking_ListingFragment = { parking: Array<{ assigned?: boolean | null, assignedFee?: string | null, comments?: string | null, id?: string | null, perSpaceFee?: string | null, totalSpaces?: number | null, type?: string | null }> };

export const ParkingFieldsFragmentDoc = `
    fragment ParkingFields on Parking {
  assigned
  assignedFee
  comments
  id
  perSpaceFee
  totalSpaces
  type
}
    `;
export const Parking_ListingFragmentDoc = `
    fragment Parking_ListingFragment on Listing {
  parking {
    ...ParkingFields
  }
}
    `;



