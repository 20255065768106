import { useAtomValue } from 'jotai'
import { useState } from 'react'
import { ThankYouContainer } from '../thank-you/thank-you-container'
import { currentStepAtom } from './request-a-tour-wizard.store'
import {
  ContactDayPickerForm,
  emptyRequestTourData,
} from './contact-day-picker-form/contact-day-picker-form'
import { EnhancedLeadStep } from './enhanced-lead-step'
import type { LeadForm_ListingFragment } from '../lead-form/__generated__/lead-form.gql'
import type { RequestTourFormData } from './use-lead-submission'
import type { getLocationRelativeToSearchedCity } from '../search/utils/get-location-relative-to-searched-city'

interface RequestTourWizardProps {
  listing: LeadForm_ListingFragment
  currentRefinementSlugs?: string[]
  onClose?: () => void
  locationRelativeToSearchedCity?: ReturnType<
    typeof getLocationRelativeToSearchedCity
  >
}

export function RequestTourWizard(props: RequestTourWizardProps) {
  // storing contact form state between steps for Next/Back functionality
  // (lead cookie data + message -- message includes tour request info)
  const [contactFormData, setContactFormData] =
    useState<RequestTourFormData>(emptyRequestTourData)

  const stepArray = [
    <ContactDayPickerForm
      key="contact-day-picker-form"
      listing={props.listing}
      currentRefinementSlugs={props.currentRefinementSlugs}
      onClose={props.onClose}
      message={contactFormData.message}
      setContactFormData={setContactFormData}
      locationRelativeToSearchedCity={props.locationRelativeToSearchedCity}
    />,
    <EnhancedLeadStep
      key="enhanced-lead-questions"
      listing={props.listing}
      currentRefinementSlugs={props.currentRefinementSlugs}
      onClose={props.onClose}
      contactFormData={contactFormData}
      locationRelativeToSearchedCity={props.locationRelativeToSearchedCity}
    />,
    <ThankYouContainer
      key="thank-you-screen"
      listing={props.listing}
      onClose={props.onClose}
      heading="This property will contact you to arrange a tour."
    />,
  ]

  const currentStep = useAtomValue(currentStepAtom)
  return stepArray[currentStep]
}
