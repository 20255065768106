/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { Aerial_ListingFragmentDoc } from '../../aerial-view/__generated__/aerial-view.gql';
export type Address_LocationFragment = { city?: string | null, lat?: number | null, lng?: number | null, neighborhoods?: Array<string | null> | null, stateAbbr?: string | null, zip?: string | null };

export type Address_ListingFragment = { address?: string | null, addressFull?: string | null, aerialId?: string | null, id: string, location?: { city?: string | null, lat?: number | null, lng?: number | null, neighborhoods?: Array<string | null> | null, stateAbbr?: string | null, zip?: string | null } | null };

export const Address_LocationFragmentDoc = `
    fragment Address_LocationFragment on Location {
  city
  lat
  lng
  neighborhoods
  stateAbbr
  zip
}
    `;
export const Address_ListingFragmentDoc = `
    fragment Address_ListingFragment on Listing {
  address
  ...Aerial_ListingFragment
  location {
    ...Address_LocationFragment
  }
}
    `;



