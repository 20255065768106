import { TOTAL_STEPS } from '../../tour-wizard.store'

export function getNextDisabled(
  currentStep: number,
  emptyFieldCount: number,
  timeSelection: string
): boolean {
  if (currentStep === 0) {
    return !timeSelection
  }
  if (currentStep === 1) {
    return emptyFieldCount > 0
  }

  return currentStep > TOTAL_STEPS - 1
}
