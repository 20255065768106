import React, { useMemo } from 'react'
import clsx from 'clsx'
import { useAtomValue } from 'jotai'
import { useFeatureVariable } from '@rentpath/ab-testing-react'

import { getEmptyFieldCount } from '../contact-form/contact-form.utils'
import {
  contactFormAtom,
  currentStepAtom,
  timeSelectionAtom,
  TOTAL_STEPS,
} from '../tour-wizard.store'
import { useNextHandler } from './hooks/use-next-handler'
import { useTourWizardConfig } from './hooks/use-tour-wizard-config'
import { TourWizardError } from './tour-wizard-error'
import { TourWizardLoading } from './tour-wizard-loading'
import { getNextDisabled } from './utils/get-next-disabled'

import type { FC } from 'react'
import { SCHEDULE_TOUR } from '../../../features/cta-buttons/cta-buttons.const'
import { Button } from '../../../components/button/button'

export const TourWizardNav: FC = () => {
  const { theme, tourProvider } = useTourWizardConfig()
  const currentStep = useAtomValue(currentStepAtom)
  const contactForm = useAtomValue(contactFormAtom)

  const timeSelection = useAtomValue(timeSelectionAtom)
  const nextDisabled = useMemo(
    () =>
      getNextDisabled(
        currentStep,
        getEmptyFieldCount(contactForm),
        timeSelection
      ),
    [contactForm, currentStep, timeSelection]
  )
  const isLastStep = currentStep === TOTAL_STEPS - 1
  const isTestMode = tourProvider === 'test'

  const scheduleTourCtaText = useFeatureVariable<string>(
    ['tour_headline_copy', 'schedule_tour_cta_text'],
    SCHEDULE_TOUR
  )

  const [handleNext, loadingTourRequest, tourRequestError] = useNextHandler()
  return (
    <>
      {loadingTourRequest && (
        <TourWizardLoading message="Contacting property" />
      )}

      {tourRequestError && !loadingTourRequest && (
        <TourWizardError message="A problem occurred while contacting the property." />
      )}
      <div
        className={clsx(
          'flex w-full items-center space-x-10 md:mx-auto md:px-0'
        )}
      >
        <Button
          type="button"
          data-tid="tour-wizard-next-button"
          data-tag_item={
            isLastStep ? 'schedule_tour_submit_button' : 'next_button'
          }
          onClick={handleNext}
          disabled={nextDisabled}
          className={clsx(
            nextDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
            isLastStep ? theme?.scheduleTourButton : theme?.nextButton
          )}
        >
          {isLastStep ? scheduleTourCtaText : 'Next'}
          {isTestMode && ' (TEST)'}
        </Button>
      </div>
    </>
  )
}
