import { useAtom } from 'jotai'
import React from 'react'

import { useTourWizardConfig } from '../wizard/hooks/use-tour-wizard-config'
import { setTourTypeAtom } from '../tour-wizard.store'
import { TourTypesInput } from './tour-types-input'

import type { ChangeEvent, FC } from 'react'

import clsx from 'clsx'
import type { TourType } from '../../../__generated__/api-types'

export interface TourTypeListItemsProps {
  types: TourType[]
}

const TourTypesListItems: FC<TourTypeListItemsProps> = ({ types = [] }) => {
  const [tourTypeSelection, setTourType] = useAtom(setTourTypeAtom)
  const { tourTypes, tourTypeDetails, theme } = useTourWizardConfig()

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setTourType(e.target.value as TourType)
  }

  return (
    <>
      {types.map((tourType, i) => {
        const detail = tourTypeDetails?.[tourType]
        const isLast = i === types.length - 1
        const isSelected = tourType === tourTypeSelection
        const isNextSelected = types[i + 1] === tourTypeSelection
        return detail ? (
          <li
            key={i}
            className={clsx(
              theme?.tourTypesList?.listItem,
              !isSelected &&
                !isLast &&
                !isNextSelected &&
                theme?.tourTypesList?.listItemSelected
            )}
          >
            <TourTypesInput
              checked={tourType === tourTypeSelection}
              dataTagItem={detail.dataTagItem}
              description={detail.description}
              disabled={tourType ? !tourTypes[tourType] : false}
              id={tourType}
              data-tid={tourType}
              image={detail.image}
              onChange={handleChange}
              title={detail.title}
              value={tourType}
            />
          </li>
        ) : null
      })}
    </>
  )
}

export const TourTypesList: FC = () => {
  const { orderedTourTypes, theme } = useTourWizardConfig()
  const [enabledTypes] = orderedTourTypes || []

  if (enabledTypes.length <= 1) {
    return null
  }

  return (
    <div className={theme?.tourTypesList?.wrapper}>
      <ul
        className={theme?.tourTypesList?.list}
        data-tid="tour-types-list"
        role="tablist"
      >
        <TourTypesListItems types={enabledTypes} />
      </ul>
    </div>
  )
}
