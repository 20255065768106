import { atom } from 'jotai'

export interface DayTime {
  day: number
  time: number
}

export const dayTimeAtom = atom<DayTime>({
  day: 0,
  time: 0,
})
