import type { AdUnit } from './ads.types'
import { useLazyAd } from './use-lazy-ad'

export type LazyAdSlotProps = {
  divId: string
  adTargets: string[]
  adsPath?: string
  type: AdUnit['type']
  clientTag?: string
  tagNumber?: number
  onAdLoad?: (event: googletag.events.SlotOnloadEvent) => void
}

export function LazyAdSlot({
  divId,
  adTargets,
  adsPath,
  type,
  clientTag,
  tagNumber,
  onAdLoad,
}: LazyAdSlotProps) {
  useLazyAd({
    adUnits: [{ selectorId: divId, type }],
    adTargets,
    adsPath,
    clientTag,
    tagNumber,
    onAdLoad,
  })

  return <div id={divId} />
}
