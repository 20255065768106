import React from 'react'
import clsx from 'clsx'

import { useTourWizardConfig } from '../wizard/hooks/use-tour-wizard-config'

import type { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react'

import type { TourTypeDetail } from '../tour-wizard.types'

export type TourTypesInputProps = {
  id: string
  checked: boolean
  disabled: boolean
} & TourTypeDetail &
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const TourTypesInput: FC<TourTypesInputProps> = ({
  title,
  dataTagItem,
  ...inputProps
}) => {
  const { theme } = useTourWizardConfig()

  let classNameLabel = theme?.tourTypeInput

  if (inputProps.disabled) {
    classNameLabel = theme?.tourTypeInputDisabled
  } else if (inputProps.checked) {
    classNameLabel = theme?.tourTypeInputChecked
  }

  return (
    <>
      <input
        className={theme?.tourTypesList?.input}
        type="radio"
        name="tour-type"
        role="tab"
        aria-selected={inputProps.checked}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
        data-tag_item={dataTagItem}
      />
      <label
        htmlFor={inputProps.id}
        data-tid="type-picker-button"
        className={clsx(theme?.tourTypesList?.label, classNameLabel)}
      >
        <h4
          className={clsx(
            theme?.tourTypesList?.heading,
            {
              [theme?.tourTypesList?.headingDisabled || '']:
                inputProps.disabled,
              [theme?.tourTypeInputHeadingChecked ?? '']: inputProps.checked,
            },

            // eslint-disable-next-line no-nested-ternary
            inputProps.disabled
              ? theme?.tourTypesList?.headingDisabled
              : inputProps.checked
              ? theme?.tourTypeInputHeadingChecked
              : theme?.tourTypeInputHeadingUnchecked
          )}
          data-tag_item="tour_type_label"
        >
          {title}
        </h4>
      </label>
    </>
  )
}
