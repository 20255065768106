/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type Aerial_ListingFragment = { addressFull?: string | null, aerialId?: string | null, id: string };

export type AerialViewData_ListingFragment = { success: boolean, message?: string | null, url?: string | null };

export type AerialViewDataQueryVariables = Types.Exact<{
  videoId: Types.Scalars['String'];
  address?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AerialViewDataQuery = { aerialViewData: { success: boolean, message?: string | null, url?: string | null } };

export const Aerial_ListingFragmentDoc = `
    fragment Aerial_ListingFragment on Listing {
  addressFull
  aerialId
  id
}
    `;
export const AerialViewData_ListingFragmentDoc = `
    fragment AerialViewData_ListingFragment on AerialViewVideoData {
  success
  message
  url
}
    `;
export const AerialViewDataQueryDocument = `
    query AerialViewDataQuery($videoId: String!, $address: String) {
  aerialViewData(videoId: $videoId, address: $address) {
    ...AerialViewData_ListingFragment
  }
}
    ${AerialViewData_ListingFragmentDoc}`;

    export function createAerialViewDataQueryFetcher(options: GraphqlRequesterOptions) {
            const graphqlRequester = new GraphqlRequester(options)
    
            return function fetchAerialViewDataQuery(variables: AerialViewDataQueryVariables, headers?: RequestInit['headers']) {
      return graphqlRequester.request<AerialViewDataQuery, AerialViewDataQueryVariables>(AerialViewDataQueryDocument, variables, headers);
      }
    }

