import { propertyTypeSlugs } from '@brand/search/refinements'
import { sortRefinementsSlugs } from '../../search/utils/sort-refinement-slugs'

/**
 * Takes a list of refinement slugs and returns the refinements array that we
 * send with a lead submission.
 *
 * @param currentRefinementSlugs
 */
export function getLeadRefinements(
  currentRefinementSlugs?: string[]
): string[] {
  const sortedSlugs = sortRefinementsSlugs(currentRefinementSlugs || []) ?? []
  const propertyTypes: string[] = []
  const refinements: string[] = []

  // ag.js puts the property type at the start of the refinements. I'm not sure
  // this matters, but let's just keep it consistent.
  sortedSlugs.forEach((slug) => {
    if (propertyTypeSlugs.includes(slug)) {
      propertyTypes.push(slug)
    } else {
      refinements.push(slug)
    }
  })

  return propertyTypes.concat(refinements)
}
