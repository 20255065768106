import clsx from 'clsx'
import { useState } from 'react'
import { Heading } from '../../../components/heading/heading'
import { Anchor } from '../../../components/anchor/anchor'
import { Modal } from '../../modals/modal'
import { ThankYouRedesignCardsWrapper } from './thank-you-redesign-listing-cards/thank-you-redesign-stacked-cards-wrapper'
import { ThankYouRedesignStackedSkeletons } from './thank-you-redesign-listing-cards/thank-you-redesign-stacked-cards'
import styles from './thank-you-redesign.module.css'

import type { AsyncData } from '@swan-io/boxed'
import type { SimilarOrViewed_ListingFragment } from '../../listing-card-preview-group/__generated__/similar-properties.gql'
import { useRequestData } from '../../request-data/pages-router/use-request-data'
import { usePageType } from '../../analytics/utils/use-page-type'
import type { ThankYou_ListingFragment } from '../__generated__/thank-you.gql'
import { AdStepHeader } from './thank-you-ad-step/thank-you-ad-step-header/thank-you-ad-step-header'

export interface ThankYouRedesignProps {
  listing: ThankYou_ListingFragment
  onClose?: () => void
  redesignVersion: number
  initialCustomMessage?: JSX.Element
  similarProperties: AsyncData<SimilarOrViewed_ListingFragment[]>
  showEmailLeadForScheduleTour?: boolean
}

export const enum ScreenStates {
  Ad = 'ad',
  Listing = 'listing',
  Phone = 'phone',
  Final = 'final',
}

export type ScreenStatesType =
  | ScreenStates.Ad
  | ScreenStates.Listing
  | ScreenStates.Phone
  | ScreenStates.Final

export function ThankYouRedesign({
  listing,
  onClose,
  redesignVersion,
  similarProperties,
  initialCustomMessage,
  showEmailLeadForScheduleTour,
}: ThankYouRedesignProps) {
  const { isMobile } = useRequestData()
  const pageName = usePageType(isMobile)
  const dataTagSection = `confirmation_modal_${pageName}`

  const primaryLeadListingHasAttFiber = listing.adPartners.some(
    (adPartner) => adPartner?.name === 'ATT'
  )

  const isV2orV3 = redesignVersion === 2 || redesignVersion === 3

  const showAdAsFirstStep = isV2orV3 && primaryLeadListingHasAttFiber

  const [activeScreen, setActiveScreen] = useState<ScreenStatesType>(
    showAdAsFirstStep ? ScreenStates.Ad : ScreenStates.Listing
  )
  const isAdStep = activeScreen === ScreenStates.Ad
  const isFinalScreen = activeScreen === ScreenStates.Final
  const isCloseHidden = isAdStep && redesignVersion === 2
  const showListingLoaderSkeletons = !showAdAsFirstStep && !isFinalScreen

  const showContactHeading = !isFinalScreen && !isAdStep

  return (
    <div
      className={styles.modalWrapper}
      data-tag_section={dataTagSection}
      data-tid="thank_you_modal"
    >
      <header
        className={clsx(styles.header, {
          [styles.hideHeading]: isFinalScreen,
          [styles.adStepHeader]: isAdStep,
        })}
      >
        {showContactHeading && (
          <Heading as="h3" withBodyFont>
            Contact Similar Properties
          </Heading>
        )}
        {isAdStep && <AdStepHeader initiatedLeadListing={listing} />}
        {!isCloseHidden && (
          <Modal.CloseButton onClose={onClose}>
            <Anchor as="button" variant="light" data-tag_item="close">
              Close
            </Anchor>
          </Modal.CloseButton>
        )}
      </header>
      {similarProperties.match({
        NotAsked: () =>
          showListingLoaderSkeletons ? (
            <ThankYouRedesignStackedSkeletons />
          ) : null,
        Loading: () =>
          showListingLoaderSkeletons ? (
            <ThankYouRedesignStackedSkeletons />
          ) : null,
        Done: (listings) => {
          const noBasicListings = listings.filter(
            (listingResults) => !listingResults.isBasic
          )

          if (!isFinalScreen && noBasicListings.length === 0 && !isAdStep) {
            setActiveScreen(ScreenStates.Final)
          }

          return (
            <ThankYouRedesignCardsWrapper
              initiatedLeadListing={listing}
              initialCustomMessage={initialCustomMessage}
              listings={noBasicListings}
              setActiveScreen={setActiveScreen}
              dataTagSection={dataTagSection}
              activeScreen={activeScreen}
              showEmailLeadForScheduleTour={showEmailLeadForScheduleTour}
              onClose={onClose}
            />
          )
        },
      })}
    </div>
  )
}
