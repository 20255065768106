import { useEffect, useMemo } from 'react'
import type { AdUnit } from './ads.types'

export type UseLazyAdArgs = {
  adTargets?: string[]
  adUnits: AdUnit[]
  adsPath?: string
  clientTag?: string
  tagNumber?: number
  onAdLoad?: (event: googletag.events.SlotOnloadEvent) => void
}

/**
 * WARNING: This effect will fire twice in strict mode.
 */
export function useLazyAd(args: UseLazyAdArgs) {
  const abortController = useMemo(() => new AbortController(), [])
  const { onAdLoad } = args

  useEffect(() => {
    if (typeof onAdLoad !== 'function') return

    const handler = (event: googletag.events.SlotOnloadEvent) =>
      onAdLoad?.(event)

    window?.googletag?.pubads().addEventListener('slotOnload', handler)
    return () => {
      window?.googletag?.pubads().removeEventListener('slotOnload', handler)
    }
  }, [onAdLoad])

  useEffect(() => {
    if (typeof window.ramp?.getUnits !== 'function') {
      return
    }
    const unitsBeforeInsertion = window.ramp.getUnits()

    async function run({ signal }: { signal: AbortController['signal'] }) {
      if (signal?.aborted) {
        return
      }

      if (!window.ramp?.addUnits) {
        return
      }

      const ramp = window.ramp
      const hasAdTargets = args.adTargets && args.adTargets.length > 0

      if (hasAdTargets) {
        ramp.custom_tags = ramp.custom_tags || args.adTargets
      }

      if (args.clientTag) {
        if (!window.PageOS) {
          return
        }

        const PageOS = window.PageOS

        PageOS.setClientTag?.(args.tagNumber || 2, args.clientTag)
      }

      if (args.adsPath) {
        await ramp.setPath(args.adsPath)
      }

      return ramp
        .addUnits(args.adUnits) // isn't this fun to read :face_melt:
        .then(() => ramp.displayUnits())
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error)
        })
    }

    void run({ signal: abortController.signal })

    return () => {
      abortController.abort()

      if (typeof window?.ramp?.getUnits !== 'function') {
        return
      }

      const ramp = window.ramp
      const unitsBeforeUnmount = ramp.getUnits()
      const lastInserted =
        unitsBeforeUnmount && unitsBeforeUnmount.at && unitsBeforeUnmount.at(-1)

      if (
        unitsBeforeInsertion.length !== unitsBeforeUnmount.length &&
        lastInserted
      ) {
        void ramp.setPath(ramp.forcePath || 'ROS')
        ramp.destroyUnits(lastInserted).catch((error: Error) => {
          // eslint-disable-next-line no-console
          console.error(error)
        })
      }
    }
  }, [
    abortController,
    args.adTargets,
    args.adUnits,
    args.adsPath,
    args.clientTag,
    args.tagNumber,
  ])
}
