import type { AdTarget_ListingFragment } from './__generated__/ad-targets.gql'
/**
 * Given a listing, returns the advertising partner target to use for ads.
 * - Converts all advertisers to lower case
 * @returns
 * A target such as
 * - { advertiser: 'att'}
 * - { advertiser: ['att','other']}
 */
export function getAdvertiserTargets(
  listing?: AdTarget_ListingFragment | null
) {
  if (!listing) {
    return null
  }

  const adPartners = 'adPartners' in listing ? listing.adPartners : null

  if (!adPartners || adPartners.length === 0) {
    return null
  }

  const adPartnersLowerCase = adPartners.map((advertiser) =>
    (advertiser.name || '').toLowerCase()
  )

  // Return a single string if there is only one ad partner, otherwise return
  // the entire array
  const value =
    adPartners.length === 1 ? adPartnersLowerCase[0] : adPartnersLowerCase

  return { advertiser: value }
}
