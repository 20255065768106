/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
export type LogoFieldsFragment = { caption?: string | null, id: string };

export type PropertyManagementCompanyFragment = { id?: string | null, description?: string | null, name?: string | null, nameSanitized?: string | null, logo?: { caption?: string | null, id: string } | null };

export type AboutProperty_FloorPlanFragment = { units: Array<{ unitId?: string | null }>, imageFloorPlan?: { id: string } | null, image3dFurnished?: { id: string } | null, image3dUnfurnished?: { id: string } | null };

export type AboutProperty_ListingFragment = { description?: string | null, propertyType?: Types.PropertyType | null, totalUnits: number, isBasic: boolean, website?: string | null, tplsource?: string | null, offMarket: boolean, updatedAt?: string | null, priceText?: string | null, price?: number | null, propertyManagementCompany?: { id?: string | null, description?: string | null, name?: string | null, nameSanitized?: string | null, logo?: { caption?: string | null, id: string } | null } | null, floorPlans?: Array<{ units: Array<{ unitId?: string | null }>, imageFloorPlan?: { id: string } | null, image3dFurnished?: { id: string } | null, image3dUnfurnished?: { id: string } | null }> | null };

export type MlsInfo_ListingFragment = { mlsInfo?: { brokerName?: string | null, agentFirstName?: string | null, agentLastName?: string | null, feed?: string | null, id?: string | null, isMls?: boolean | null } | null };

export const LogoFieldsFragmentDoc = `
    fragment LogoFields on Photo {
  caption
  id
}
    `;
export const PropertyManagementCompanyFragmentDoc = `
    fragment PropertyManagementCompany on PropertyManagementCompany {
  id
  description
  logo {
    ...LogoFields
  }
  name
  nameSanitized
}
    `;
export const AboutProperty_FloorPlanFragmentDoc = `
    fragment AboutProperty_FloorPlanFragment on FloorPlan {
  units {
    unitId
  }
  imageFloorPlan {
    id
  }
  image3dFurnished {
    id
  }
  image3dUnfurnished {
    id
  }
}
    `;
export const AboutProperty_ListingFragmentDoc = `
    fragment AboutProperty_ListingFragment on Listing {
  description
  propertyManagementCompany {
    ...PropertyManagementCompany
  }
  propertyType
  totalUnits
  isBasic
  website
  tplsource
  offMarket
  updatedAt
  priceText
  price
  floorPlans {
    ...AboutProperty_FloorPlanFragment
  }
}
    `;
export const MlsInfo_ListingFragmentDoc = `
    fragment MlsInfo_ListingFragment on Listing {
  mlsInfo {
    brokerName
    agentFirstName
    agentLastName
    feed
    id
    isMls
  }
}
    `;



