import clsx from 'clsx'
import baseStyles from './scroll-snap-carousel.module.css'
import searchResultsStyles from '../../features/search/search-results/search-results.module.css'
import type { ForwardedRef, HTMLAttributes } from 'react'
import { useState, useImperativeHandle, useRef, forwardRef, memo } from 'react'
import { yieldOrContinue } from 'main-thread-scheduling'

export interface CarouselButtonRef extends HTMLButtonElement {
  hide: () => void
  show: () => void
  disable: (disable: boolean) => void
}

export const CarouselButton = memo(
  forwardRef<HTMLButtonElement, HTMLAttributes<HTMLButtonElement>>(
    function CarouselButton(
      props: HTMLAttributes<HTMLButtonElement>,
      forwardedRef: ForwardedRef<HTMLButtonElement>
    ) {
      const ref = useRef<HTMLButtonElement>(null)
      const [hide, setHide] = useState(true)
      const { children, className, ...rest } = props

      useImperativeHandle(
        forwardedRef,
        () => ({
          ...ref.current!,
          hide: async function hideCarouselButton() {
            await yieldOrContinue('smooth')
            setHide(true)
          },
          show: async function showCarouselButton() {
            await yieldOrContinue('smooth')
            setHide(false)
          },
        }),
        []
      )

      return (
        <button
          ref={ref}
          className={clsx(
            baseStyles.control,
            searchResultsStyles.carouselControl,
            className,
            hide && baseStyles.disabled
          )}
          {...rest}
          type="button"
          data-tag_action="ignore"
        >
          {children}
        </button>
      )
    }
  )
)
