/**
 * Builds alt text for property images.
 *
 * e.g., "{Category} - {Prop Name OR address} - {City}, {State Abbrev}"
 *
 * @param listing
 * @param photo
 */
import type { Maybe } from '__generated__/api-types'

import type { PhotoFragment } from './__generated__/gallery-carousel.gql'

export function buildPropertyImageDesc(
  listing: {
    stateAbbr?: string | null
    city?: string | null
    address?: string | null
    name?: string | null
  },
  photo: Maybe<PhotoFragment>
) {
  if (!photo) {
    return ''
  }
  const cityState = [listing.city, listing.stateAbbr].filter(Boolean)

  return [
    photo.category,
    listing.name || listing.address,
    // make sure both city and state are definend
    cityState.length === 2 && cityState.join(', '),
  ]
    .filter(Boolean)
    .join(' - ')
}
