/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { PhotoFragmentDoc } from '../../gallery/__generated__/gallery-carousel.gql';
import { DetailReviews_ListingFragmentDoc, ReviewsFieldsFragmentDoc } from '../../__generated__/detail-page.gql';
import { ListingPhone_ListingFragmentDoc } from '../../../listing-phone/__generated__/listing-phone.gql';
import { TelephonyFields_ListingFragmentDoc, CtaButtons_ListingFragmentDoc, CtaButtons_TourProviderDetailsFragmentDoc, CtaButtons_LocationFragmentDoc } from '../../../cta-buttons/__generated__/cta-buttons.gql';
export type JsonLd_ListingFragment = { address?: string | null, amenitiesHighlighted: Array<string>, description?: string | null, isUnpaid: boolean, isActive: boolean, isBasic: boolean, name?: string | null, propertyType?: Types.PropertyType | null, phoneMobileText?: string | null, phoneDesktopText?: string | null, ratingCount?: number | null, ratingPercent?: number | null, urlPathname?: string | null, id: string, phoneMobileSem?: string | null, phoneMobile?: string | null, phoneDesktop?: string | null, phoneDesktopSem?: string | null, phoneDesktopSemText?: string | null, phoneMobileSemText?: string | null, revenue?: number | null, zScore?: number | null, NV_RPL?: number | null, floorPlans?: Array<{ name?: string | null, bedCount?: number | null, bathCount?: number | null, sqFtRange?: { min?: number | null, max?: number | null } | null }> | null, hdTours: Array<{ imageToken?: string | null }>, location?: { city?: string | null, state?: string | null, zip?: string | null, lat?: number | null, lng?: number | null } | null, officeHours: Array<{ day: string, times: Array<{ open?: string | null, close?: string | null }> }>, priceRange?: { max?: number | null, min?: number | null } | null, photos?: Array<{ id: string, caption?: string | null, category?: string | null } | null> | null, ratings?: Array<{ count: number, percent?: number | null, rating: number }> | null, videos: Array<{ imageToken?: string | null }>, reviews?: Array<{ author?: string | null, date: string, helpfulCount: number, id: string, listingId: string, rating: number, review: string, isCurrentManagement: boolean, comments: Array<{ author: string, comment: string, date: string }> }> | null, mitsPhone: { raw?: string | null, formatted?: string | null }, telephony: { isSwapPhoneEligible: boolean, webNumber: { raw?: string | null, formatted?: string | null }, semNumber: { raw?: string | null, formatted?: string | null } } };

export const JsonLd_ListingFragmentDoc = `
    fragment JsonLd_ListingFragment on Listing {
  address
  amenitiesHighlighted
  description
  isUnpaid
  isActive
  isBasic
  floorPlans {
    name
    bedCount
    bathCount
    sqFtRange {
      min
      max
    }
  }
  hdTours {
    imageToken
  }
  location {
    city
    state
    zip
    lat
    lng
  }
  name
  officeHours {
    day
    times {
      open
      close
    }
  }
  priceRange {
    max
    min
  }
  propertyType
  phoneMobileText
  phoneDesktopText
  photos {
    ...PhotoFragment
  }
  ratingCount
  ratingPercent
  ratings {
    count
    percent
    rating
  }
  videos {
    imageToken
  }
  ...DetailReviews_ListingFragment
  ...ListingPhone_ListingFragment
  urlPathname
}
    `;



