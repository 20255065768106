/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { Schools_SchoolFragmentDoc } from '../../../schools/__generated__/schools.gql';
export type Schools_ListingFragment = { schools?: Array<{ districtName?: string | null, name?: string | null, type?: string | null, grade?: string | null, overallRating?: string | null, milesToGeoCode?: string | null, locationSlug?: string | null }> | null };

export const Schools_ListingFragmentDoc = `
    fragment Schools_ListingFragment on Listing {
  schools {
    ...Schools_SchoolFragment
  }
}
    `;



