import { atom } from 'jotai'
import { atomWithDefault, atomWithStorage } from 'jotai/utils'
import Cookies from 'js-cookie'
import type { CookieAttributes } from 'js-cookie'
import type { EnhancedLeadResponses } from './const'

type EnhancedLeadUpdate = {
  id: string
  values: string | string[]
}

const leadCookieOptions: CookieAttributes = { path: '/' }

const defaultData = {} as EnhancedLeadResponses

/* cookie data */

const enhancedLeadStorage = {
  getItem: (key: string) => {
    let maybeVal = Cookies.get(key)

    if (maybeVal) {
      try {
        maybeVal = JSON.parse(maybeVal)
      } catch {
        // no op
      }
    }

    return maybeVal || {}
  },
  setItem: (key: string, enhancedLeadData: EnhancedLeadResponses) => {
    Cookies.set(key, JSON.stringify(enhancedLeadData), leadCookieOptions)
  },
  removeItem: (key: string) => {
    Cookies.remove(key, leadCookieOptions)
  },
}

export const enhancedLeadCookieAtom = atomWithStorage<EnhancedLeadResponses>(
  'enhancedLead',
  defaultData,
  enhancedLeadStorage
)

/* form state */

export const enhancedLeadsAtom = atomWithDefault((get) =>
  get(enhancedLeadCookieAtom)
)

export const setEnhancedLeadValueAtom = atom(
  null,
  (get, set, update: EnhancedLeadUpdate) => {
    set(enhancedLeadsAtom, {
      ...get(enhancedLeadsAtom),
      ...{
        [update.id]:
          typeof update.values === 'string' ? [update.values] : update.values,
      },
    })
  }
)
