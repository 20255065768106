import React, { useRef, useEffect } from 'react'
import { useTourWizardConfig } from '../wizard/hooks/use-tour-wizard-config'
import styles from './contact-form-terms.module.css'

export const ContactFormTerms = () => {
  const { tourProviderDetails } = useTourWizardConfig()
  const ref = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    if (!ref.current) return

    ref.current.querySelectorAll('a').forEach((anchor: HTMLAnchorElement) => {
      anchor.target = '_blank'
    })
  }, [])

  return (
    <div className="text-legal100 pb-40 sm:pr-20">
      <p
        ref={ref}
        data-tid="tour_disclaimer"
        className={styles.contactFormTerms}
        dangerouslySetInnerHTML={{
          __html: `${tourProviderDetails?.disclaimer}`,
        }}
      />
    </div>
  )
}
