import { ToggleButton } from '../../components/toggle-button/toggle-button'
import { useState } from 'react'
import styles from './prompt.module.css'
import { PromptMapping } from './const'

export interface PromptProps {
  promptId: string
  onChange?: (value: string | string[]) => void
  selected?: string[]
}

export function Prompt({ onChange, promptId, selected }: PromptProps) {
  const [, setSelectedOptions] = useState<string[]>([])

  if (!PromptMapping[promptId]) return null

  const { singleSelection, text, values, leadField } = PromptMapping[promptId]

  function handleToggle(value: string | string[]) {
    singleSelection
      ? setSelectedOptions([value[value.length - 1] as string])
      : setSelectedOptions(value as string[])
    onChange?.(value)
  }

  const options = values.map((value) => ({
    value,
    label: value,
    name: leadField,
    'data-tag_item': `${value}-${leadField}`,
  }))

  return (
    <fieldset className={styles.container} name={leadField}>
      <legend className={styles.promptText}>
        {text} <span className={styles.optionalText}>(optional)</span>
      </legend>
      {!singleSelection && (
        <p className={styles.subText}>Try multiple selections</p>
      )}
      <div className={styles.buttonsContainer}>
        <ToggleButton.Group
          onChange={handleToggle}
          options={options}
          value={selected}
          selectionPattern={singleSelection ? 'SINGLE_SELECTION' : null}
          type="checkbox"
        />
      </div>
    </fieldset>
  )
}
