import { graphqlRequesterOptions } from '../../config/graphql-requester-options'
import { createSubmitLeadFetcher } from './__generated__/lead-form.gql'

import type * as Types from '../../__generated__/api-types'

type SubmitLeadMutationVariables = Types.Exact<{
  lead: Types.LeadSubmissionInput
}>

export const submitLead = createSubmitLeadFetcher(graphqlRequesterOptions)

/**
 * Lead submit function with some basic error handling builtin
 */
export function submitLeadWithErrorHandling(
  variables: SubmitLeadMutationVariables,
  headers?: RequestInit['headers']
) {
  return submitLead(variables, headers).tapOk((data) => {
    // Empty lead -> error happened -> throw error so tapError() works down the chain
    if (
      data &&
      (data.leadSubmission === null || data.leadSubmission?.success === false)
    ) {
      throw Error('There was a problem submitting your info.')
    }

    return data
  })
}
