import {
  EMAIL_REGEX,
  errorMessages,
  validateDate,
  validatePhone,
} from '@rentpath/form-validation'
import { useSetAtom } from 'jotai'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { DateInput } from '../../../components/date-input/date-input'
import { EmailInput } from '../../../components/forms/email-input'
import { PhoneInput } from '../../../components/forms/phone-input'
import { Input } from '../../../components/input/input'
import { useDefaultMoveDate } from '../../lead-form/hooks/use-default-move-date'
import { getShouldShowNativeDatePicker } from '../../lead-form/utils/get-should-show-native-date-picker'
import {
  removeErrorAtom,
  setContactInfoValuesAtom,
  setErrorAtom,
} from '../tour-wizard.store'
import { useTourWizardConfig } from '../wizard/hooks/use-tour-wizard-config'
import { TourWizardStepContainer } from '../wizard/tour-wizard-step-container'
import { TourWizardStepHeading } from '../wizard/tour-wizard-step-heading'
import { ContactFormTerms } from './contact-form-terms'

export const ContactFormStep: FC = () => {
  const { theme, isMobile } = useTourWizardConfig()
  const setError = useSetAtom(setErrorAtom)
  const removeError = useSetAtom(removeErrorAtom)
  const setContactInfoValues = useSetAtom(setContactInfoValuesAtom)
  const moveDate = useDefaultMoveDate()
  const showNativeDatePicker = getShouldShowNativeDatePicker(Boolean(isMobile))

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    moveDate: moveDate,
    phone: '',
  }
  const { formState, getValues, register } = useForm({
    defaultValues,
    mode: 'onTouched',
  })
  const { errors } = formState

  /**
   * Because we're using an external library for validation,
   * and tour wizard maintains its own state for managing errors,
   * we need to bind the two things together.
   */
  useEffect(() => {
    setContactInfoValues(getValues())

    if (!formState.isValid) {
      for (const [name] of Object.entries(formState.errors)) {
        setError(name)
      }
    } else {
      for (const [name] of Object.entries(formState.touchedFields)) {
        removeError(name)
      }
    }
  }, [formState, getValues, removeError, setContactInfoValues, setError])

  return (
    <TourWizardStepContainer
      className={theme?.contactStep?.wrapper}
      data-tid="contact-form-step"
    >
      <div className={theme?.contactStep?.base}>
        <TourWizardStepHeading title="Almost done..." />
        <h2 className={theme?.contactStep?.subHeading}>
          Fill out your contact info
        </h2>
        <form noValidate className={theme?.contactForm?.base}>
          <div className={theme?.contactForm?.nameGroup}>
            <Input
              autoComplete="given-name"
              label="First Name"
              required
              type="text"
              className={theme?.contactForm?.firstName}
              data-tid="contact-first-name"
              error={errors.firstName?.message}
              {...register('firstName', {
                required: errorMessages.FIRST_NAME_EMPTY,
                minLength: {
                  value: 2,
                  message: errorMessages.NAME_MIN_LENGTH,
                },
              })}
            />

            <Input
              autoComplete="family-name"
              label="Last Name"
              required
              type="text"
              className={theme?.contactForm?.lastName}
              data-tid="contact-last-name"
              error={errors.lastName?.message}
              {...register('lastName', {
                required: errorMessages.LAST_NAME_EMPTY,
                minLength: {
                  value: 2,
                  message: errorMessages.NAME_MIN_LENGTH,
                },
              })}
            />
          </div>

          <div className={theme?.contactForm?.moveInDate}>
            <DateInput
              showNativeDatePicker={showNativeDatePicker}
              autoComplete="off"
              error={errors.moveDate?.message}
              required
              data-tag_item="move_in_date"
              data-tid="lead-form-date"
              {...register('moveDate', {
                validate: (v) => validateDate(v, Boolean(isMobile)),
              })}
            />
          </div>

          <EmailInput
            data-tid="contact-email"
            className={theme?.contactForm?.email}
            required
            error={errors.email?.message}
            {...register('email', {
              required: errorMessages.EMAIL_EMPTY,
              pattern: {
                value: EMAIL_REGEX,
                message: errorMessages.EMAIL_INVALID_LONG,
              },
            })}
          />

          <PhoneInput
            className={theme?.contactForm?.phone}
            data-tid="contact-phone"
            required
            error={errors.phone?.message}
            {...register('phone', {
              validate: (v) => validatePhone(v, true),
            })}
          />
        </form>

        <ContactFormTerms />
      </div>
    </TourWizardStepContainer>
  )
}
