/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { PublicTransitFieldsFragmentDoc } from '../public-transit/__generated__/public-transit.gql';
import { PublicTransitStop_PublicTransitStopFragmentDoc } from '../public-transit/__generated__/public-transit-card.gql';
export type CategoryFieldsFragment = { parks?: { score?: number | null } | null, schools?: { score?: number | null } | null, grocery?: { score?: number | null } | null, errands?: { score?: number | null } | null, dineDrink?: { score?: number | null } | null, culture?: { score?: number | null } | null, shopping?: { score?: number | null } | null };

export type WalkScoreFieldsFragment = { walk?: { description?: string | null, score?: number | null } | null, bike?: { description?: string | null, score?: number | null } | null, transit?: { description?: string | null, score?: number | null } | null, category?: { parks?: { score?: number | null } | null, schools?: { score?: number | null } | null, grocery?: { score?: number | null } | null, errands?: { score?: number | null } | null, dineDrink?: { score?: number | null } | null, culture?: { score?: number | null } | null, shopping?: { score?: number | null } | null } | null };

export type WalkScore_ListingFragment = { walkScore?: { walk?: { description?: string | null, score?: number | null } | null, bike?: { description?: string | null, score?: number | null } | null, transit?: { description?: string | null, score?: number | null } | null, category?: { parks?: { score?: number | null } | null, schools?: { score?: number | null } | null, grocery?: { score?: number | null } | null, errands?: { score?: number | null } | null, dineDrink?: { score?: number | null } | null, culture?: { score?: number | null } | null, shopping?: { score?: number | null } | null } | null } | null };

export type PublicTransit_ListingFragment = { publicTransit?: { stopsCountByType: Array<{ type: Types.PublicTransitStopType, count: number }>, stops: Array<{ routes: Array<string>, type?: Types.PublicTransitStopType | null, name?: string | null, distanceInMiles?: number | null }>, agencies: Array<{ url?: string | null, name?: string | null }> } | null };

export const CategoryFieldsFragmentDoc = `
    fragment CategoryFields on CategoryScoresType {
  parks {
    score
  }
  schools {
    score
  }
  grocery {
    score
  }
  errands {
    score
  }
  dineDrink {
    score
  }
  culture {
    score
  }
  shopping {
    score
  }
}
    `;
export const WalkScoreFieldsFragmentDoc = `
    fragment WalkScoreFields on WalkScore {
  walk {
    description
    score
  }
  bike {
    description
    score
  }
  transit {
    description
    score
  }
  category {
    ...CategoryFields
  }
}
    `;
export const WalkScore_ListingFragmentDoc = `
    fragment WalkScore_ListingFragment on Listing {
  walkScore {
    ...WalkScoreFields
  }
}
    `;
export const PublicTransit_ListingFragmentDoc = `
    fragment PublicTransit_ListingFragment on Listing {
  publicTransit {
    ...PublicTransitFields
  }
}
    `;



