/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */

import type * as Types from '../../../__generated__/api-types.js';

import { GraphqlRequester, GraphqlRequesterOptions } from "@rentpath/graphql-requester";
import { LeadTracking_ListingFragmentDoc } from '../../lead-form/__generated__/lead-form.gql';
import { ListingPhone_ListingFragmentDoc } from '../../listing-phone/__generated__/listing-phone.gql';
import { TelephonyFields_ListingFragmentDoc, CtaButtons_ListingFragmentDoc, CtaButtons_TourProviderDetailsFragmentDoc, CtaButtons_LocationFragmentDoc } from '../../cta-buttons/__generated__/cta-buttons.gql';
import { OfficeHours_ListingQueryFragmentDoc, OfficeHoursFieldsFragmentDoc, OfficeHourTimeFragmentDoc } from '../../detail/office-hours/__generated__/office-hours.gql';
export type TourWizard_ListingFragment = { availabilityStatus: Types.ListingAvailabilityStatus, id: string, addressFull?: string | null, name?: string | null, phoneDesktop?: string | null, requestTourOptOut: boolean, offMarket: boolean, NV_RPL?: number | null, revenue?: number | null, propertyType?: Types.PropertyType | null, tplsource?: string | null, zScore?: number | null, isActive: boolean, isBasic: boolean, phoneMobileSem?: string | null, phoneMobile?: string | null, phoneDesktopText?: string | null, phoneDesktopSem?: string | null, phoneDesktopSemText?: string | null, phoneMobileText?: string | null, phoneMobileSemText?: string | null, tourProviderDetails?: { providerKey?: string | null, propertyId?: string | null, disclaimer?: string | null, enabled?: boolean | null, name?: string | null, smsContentEntities?: string | null, smsOptIn?: boolean | null, updatedAt?: string | null, types?: { inPerson?: boolean | null, selfGuided?: boolean | null, videoCall?: boolean | null } | null } | null, mitsPhone: { raw?: string | null, formatted?: string | null }, officeHours: Array<{ day: string, times: Array<{ open?: string | null, close?: string | null, comment?: string | null }> }>, telephony: { isSwapPhoneEligible: boolean, webNumber: { raw?: string | null, formatted?: string | null }, semNumber: { raw?: string | null, formatted?: string | null } } };

export const TourWizard_ListingFragmentDoc = `
    fragment TourWizard_ListingFragment on Listing {
  availabilityStatus
  id
  addressFull
  name
  phoneDesktop
  requestTourOptOut
  tourProviderDetails {
    types {
      inPerson
      selfGuided
      videoCall
    }
    providerKey
    propertyId
    disclaimer
    enabled
    name
    smsContentEntities
    smsOptIn
    updatedAt
  }
  offMarket
  ...LeadTracking_ListingFragment
  ...ListingPhone_ListingFragment
  ...OfficeHours_ListingQueryFragment
}
    `;



