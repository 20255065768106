import { forwardRef } from 'react'
import type { InputProps } from '../input/input'
import { Input } from '../input/input'

export const PhoneInput = forwardRef<
  HTMLInputElement,
  Omit<InputProps, 'label'> & { label?: InputProps['label'] }
>((props, ref) => (
  <Input
    autoComplete="tel-national"
    label="Phone number"
    type="tel"
    {...props}
    ref={ref}
  />
))

PhoneInput.displayName = 'PhoneInput'
