import { PromptMapping } from './const'
import type {
  EnhancedLeadResponses,
  EnhancedLeadSubmissionMapped,
} from './const'

export function buildEnhancedLeadSubmissionFields(
  listingQuestions: string[],
  reponses: EnhancedLeadResponses
) {
  return Object.entries(reponses).reduce<EnhancedLeadSubmissionMapped>(
    (acc, [key, values]) => {
      if (
        values.length &&
        PromptMapping[key] &&
        listingQuestions.includes(key)
      ) {
        const prompt = PromptMapping[key]
        const responseIndices = values.map((inputValue) =>
          prompt.values.indexOf(inputValue)
        )
        // @ts-expect-error | TS is not seeing specific `leadField` value
        acc[prompt.leadField] = prompt.singleSelection
          ? prompt.leadValues[responseIndices[0]]
          : responseIndices.map((index) => prompt.leadValues[index])
      }
      return acc
    },
    {
      questionIds: listingQuestions,
    }
  )
}
