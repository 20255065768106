import type { ForwardedRef } from 'react'
import {
  useId,
  useRef,
  useImperativeHandle,
  useEffect,
  forwardRef,
} from 'react'
import styles from './dialog-modal.module.css'
import clsx from 'clsx'
import { yieldOrContinue } from 'main-thread-scheduling'

interface DialogModalProps {
  children: React.ReactNode
  className?: string
  /**
   * Click outside of dialog modal closes it with this
   * set to true
   * @default true
   */
  closeOnOutsideClick?: boolean
  /**
   * Animation to use when opening the dialog modal
   * The inverse of this animation will be used when closing
   */
  entranceAnimation?: 'grow' | 'slide-from-bottom'
  /**
   * Function to be called after the dialog closes
   * when the dialog fires the `close` event. This should
   * *not* be used to close the dialog modal and rather
   * if some sort of state needs to be updated.
   */
  onAfterClose?: () => void
  /**
   * Opens the dialog modal using the `showModal` method
   * if the dialog modal has already been open and this is
   * set to false, then the dialog modal will close
   *
   * @warning
   * This is not really intended for usage with using a
   * close button. If you do you use a close button,
   * you should be using the native `close` method
   * and call `onAfterClose` to change any state.
   * Below is an example of how to use this with
   * `useState`:
   *
   * @example
   *   import { DialogModal } from './DialogModal'
   *   import { useState } from 'react'
   *
   *   const [open, setOpen] = useState(false)
   *
   *   <DialogModal open={open}>
   *     <button
   *       onClick={() => setOpen(false)}
   *     >
   *       Close
   *     </button>
   *     ...
   *   </DialogModal>
   *
   */
  open?: boolean
  'data-tid'?: string
}

/**
 * Native dialog component to be used for modals
 *
 * You need to pass either a "ref" or an "id" to be able
 * to utilize opening / closing the modal. If using an "id"
 * you can use the helpers provided by importing the functions
 * `showDialogModalById` and `closeDialogModalById` to open and close
 * the modal.
 *
 * @usage
 * Ideally you should use a ref for the dialog modal, but in certain
 * cases where the dialog modal cannot be colocated with the
 * interactions, then you can use the id to interact with
 * the dialog modal instead.
 *
 * @example
 *   // with ref
 *   import { DialogModal } from './DialogModal'
 *   import { useRef } from 'react'
 *
 *   const dialogRef = useRef<HTMLDialogElement>(null)
 *
 *   <DialogModal ref={dialogRef}>...</DialogModal>
 *
 *   function closeModal() {
 *     dialogRef.current?.close()
 *   }
 *
 *   function openModal() {
 *    dialogRef.current?.showModal()
 *   }
 *
 * @example
 *   // with query selectors
 *   import { DialogModal } from './DialogModal'
 *
 *   <DialogModal id="modal-id">...</DialogModal>
 *
 *   function closeDialogModal() {
 *    document.getElementById('modal-id')?.close()
 *   }
 *
 *   function openDialogModal() {
 *     document.getElementById('modal-id')?.showModal()
 *   }
 *
 * @example
 *   // with helpers
 *   import { DialogModal } from './DialogModal'
 *   import {
 *     showDialogModalById,
 *     closeDialogModalById
 *   } from './dialog-modal-interaction-by-id'
 *
 *   <DialogModal id="modal-id">...</DialogModal>
 *
 *   showDialogModalById('modal-id')
 *   closeDialogModalById('modal-id')
 *
 * @example
 *   // using the button to close the dialog modal
 *   import { useCallback, useRef } from 'react'
 *   import { DialogModal } from './DialogModal'
 *
 *   const ref = useRef<HTMLDialogElement>(null)
 *
 *   const openDialog = useCallback(function openDialog() {
 *    ref.current?.showModal()
 *   }, [])
 *
 *   <button onClick={openDialog}>Open Dialog</button>
 *   <DialogModal ref={ref}>
 *     <DialogModalCloseButton ref={ref}>
 *       Close
 *     </DialogModalCloseButton>
 *     ...
 *   </DialogModal>
 *
 * @example
 *   // with onClick and state (complex)
 *   import { yieldOrContinue } from 'main-thread-scheduling'
 *   import { useState, useCallback, useRef } from 'react'
 *   import { DialogModal } from './DialogModal'
 *
 *   const ref = useRef<HTMLDialogElement>(null)
 *   const [open, setOpen] = useState(false)
 *
 *   const handleAfterClose = useCallback(
 *     async function handleAfterClose() {
 *      await yieldOrContinue('smooth')
 *      setOpen(false)
 *     }
 *   }, [])
 *
 *   const onClick = useCallback(function onClick() {
 *     ref.current?.close()
 *   }, [])
 *
 *   <DialogModal
 *     ref={ref}
 *     open={open}
 *     onAfterClose={handleAfterClose}
 *   >
 *     <button onClick={onClick}>
 *       Close
 *     </button>
 *
 *     // or just use a ref with the
 *     // intended button to close the dialog
 *     <DialogModalCloseButton ref={ref}>
 *       Close
 *     </DialogModalCloseButton>
 *     ...
 *   </DialogModal>
 */
export const DialogModal = forwardRef(function DialogModal(
  props: DialogModalProps & React.HTMLAttributes<HTMLDialogElement>,
  forwardedRef: ForwardedRef<HTMLDialogElement>
) {
  const {
    children,
    className,
    closeOnOutsideClick = true,
    entranceAnimation,
    id,
    onAfterClose,
    open,
    ...rest
  } = props
  const ref = useRef<HTMLDialogElement>(null)

  // these are added because useRef can be null
  // at the time useEffect is called. this allows for the
  // listeners to work as expected in case of that
  // happening
  const generatedId = useId()
  const dialogId = id || `${generatedId}-dialog-modal`

  useImperativeHandle(forwardedRef, () => ref.current!, [])

  useEffect(() => {
    const dialog =
      ref.current ||
      (document.getElementById(dialogId as string) as HTMLDialogElement)

    if (dialog && closeOnOutsideClick) {
      async function closeOutside(event: MouseEvent) {
        if (event.target === dialog) {
          await yieldOrContinue('idle')
          dialog?.close()
        }
      }

      // call onAfterClose when the dialog closes
      async function handleClose() {
        await yieldOrContinue('smooth')
        onAfterClose?.()
      }

      dialog.addEventListener('click', closeOutside)
      dialog.addEventListener('close', handleClose)

      return () => {
        dialog.removeEventListener('click', closeOutside)
        dialog.removeEventListener('close', handleClose)
      }
    }
  }, [ref, dialogId, closeOnOutsideClick, onAfterClose])

  useEffect(
    function onDialogState() {
      /* override the "open" from dialog to
       * exec showModal() which puts the dialog in
       * the top-layer so it as a ::backdrop
       *
       * if the dialog was previously open, then
       * we can close it. we want to prevent the
       * dialog modal being closed on the first render
       * if `open` is false
       *
       * NOTE: `.open()` is not really intended to be used
       * in a modal scenario
       */
      if (open === true && ref.current?.open === false) {
        ref.current?.showModal()
      } else if (open === false && ref.current?.open === true) {
        ref.current?.close()
      }
    },
    [open]
  )

  return (
    <dialog
      role="dialog"
      ref={ref}
      className={clsx(styles.dialog, className, {
        [styles.bottomSlide]: entranceAnimation === 'slide-from-bottom',
        [styles.grow]: entranceAnimation === 'grow',
      })}
      data-tag_item={closeOnOutsideClick ? 'close_overlay' : 'modal_overlay'}
      data-tid={props['data-tid'] || 'modal'}
      id={dialogId}
      {...rest}
    >
      <div className={styles.panel}>{children}</div>
    </dialog>
  )
})
