import { LeadTour } from '../../../__generated__/api-types'

export function getTourType(tourType: string) {
  switch (tourType) {
    case LeadTour.AgentGuided:
    case LeadTour.VideoCall:
    case LeadTour.SelfGuided:
      return tourType
    default:
      return LeadTour.Unspecified
  }
}
