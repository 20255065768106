import type { Listing } from '../../__generated__/api-types'
import { isAvailable } from '../detail/utils/is-available'

type isBasicAndAvailableListingProps = {
  isBasic?: Listing['isBasic']
  availabilityStatus?: Listing['availabilityStatus']
}

export function isBasicAndAvailableListing({
  isBasic: isListingBasic,
  availabilityStatus,
}: isBasicAndAvailableListingProps) {
  const isListingAvailable =
    availabilityStatus && isAvailable(availabilityStatus)

  if (isListingBasic && isListingAvailable) return true

  return false
}
